import { Box, Typography } from "@mui/material";
import { memo } from "react";

const DetailLabel = ({ children, width = 170, label = "", sx, labelSx }) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} sx={sx}>
      <Typography
        width={width}
        variant="body2"
        fontSize={".9rem"}
        fontWeight={"500"}
        color={"text.secondary"}
        sx={labelSx}
      >
        {label}
      </Typography>
      <Box flex={1}>
        <Typography
          sx={{ cursor: "text" }}
          component={"div"}
          fontWeight={children ? "600" : "400"}
          variant="body2"
          display={"flex"}
          alignItems={"center"}
          color={children ? "text.primary" : "text.secondary"}
          fontSize={".9rem"}
        >
          {children || "--"}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(DetailLabel);
