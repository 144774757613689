import { updateReport } from "../../store/features/reportsSlice";
import { store } from "../../store/store";
import { getLastSegment } from "../../utils/formaters";

export default (reportId, progress, pdfUrl) => {
  const role = getLastSegment(window.location.pathname);
  store.dispatch(
    updateReport({
      role: role || "personal",
      id: reportId,
      changes: {
        status: parseInt(progress || 0) >= 100 ? "completed" : "processing",
        progress: parseInt(progress || 0),
        pdfUrl: pdfUrl || null,
      },
    })
  );
};
