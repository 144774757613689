import { Autocomplete, MenuItem, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { CURRENCY_LIST } from "../../utils/more/currency_country";

function CurrencyInput({
  width = "100%",
  label = "Escolha uma moeda",
  helperText,
  variant,
  sx = {},
  onChange = () => {},
  required,
  size,
  endAdornment,
  hiddenLabel,
  disableClearable,
  value,
  margin = "normal",
  readOnly,
  InputProps,
  fullWidth,
  symbolOnly,
}) {
  const [error, setError] = useState(false);

  const getPropCountry = useCallback(
    (code, prop) => {
      if (!code) return "";
      const selected = CURRENCY_LIST.find((c) => c.code === code);
      if (Boolean(selected)) {
        return selected[prop];
      }
    },
    [CURRENCY_LIST]
  );

  const handleBlur = useCallback(() => {
    if (required && !value) {
      setError(true);
    }
  }, [value]);

  const handleFocus = useCallback(() => {
    setError(false);
  }, []);

  return (
    <Autocomplete
      options={CURRENCY_LIST}
      autoHighlight
      readOnly={readOnly}
      size={size}
      disableClearable={disableClearable}
      onBlur={handleBlur}
      onFocus={handleFocus}
      fullWidth
      sx={{ width: width, ...sx }}
      getOptionLabel={(option) =>
        symbolOnly
          ? option?.code
          : `${option.name} - ${option.code} (${option.symbol})`
      }
      isOptionEqualToValue={(option, value) => option.code === value.code}
      value={
        value
          ? {
              code: value,
              symbol: getPropCountry(value, "symbol"),
              name: getPropCountry(value, "name"),
            }
          : null
      }
      onChange={(e, v) => {
        onChange(v ? v.code : "");
      }}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {symbolOnly
            ? option.code
            : `${option.name} - ${option.code} (${option.symbol})`}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          error={error}
          required={required}
          hiddenLabel={hiddenLabel}
          helperText={error && helperText}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {endAdornment}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              ...InputProps,
            },
          }}
        />
      )}
    />
  );
}

export default CurrencyInput;
