import { Box, Skeleton, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import formatAmount from "../../../../utils/formatAmount";

const RouteAmountResume = ({ distance = 0, rate = 0, loading }) => {
  const currency = useSelector(selectAccountCurrency);

  const totalAmount = useMemo(() => {
    return parseFloat(distance || 0) * parseFloat(rate || 0);
  }, [rate, distance]);

  return (
    <Box
      position={"absolute"}
      zIndex={100}
      bgcolor={"background.default"}
      boxShadow={3}
      bottom={0}
      left={0}
      pt={1}
      p={2}
      pb={0}
      width={"25em"}
    >
      <Typography color="text.secondary" variant="body2" fontWeight={"500"}>
        Total estimado
      </Typography>
      <Box height={40}>
        {loading ? (
          <Skeleton height={40} width={150} />
        ) : (
          <Typography variant="h6" fontWeight={"600"}>
            {currency} {formatAmount(totalAmount)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(RouteAmountResume);
