export const scrollToExpense = (expenseId) => {
  setTimeout(() => {
    const element = document.getElementById(`expense_${expenseId}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, 300);
};
