const cache = new Map();

const DEFAULT_CACHE_TIME = 20 * 1000; //20 segundos

export const generateCacheKey = (url, params) => {
  const paramString = new URLSearchParams(params).toString();
  return `${url}?${paramString}`;
};

export const setCache = (key, value, ttl) => {
  // 20 segundos default
  const cacheTime = ttl || DEFAULT_CACHE_TIME;
  const expireAt = Date.now() + cacheTime;
  cache.set(key, { value, expireAt });

  setTimeout(() => {
    if (cache.get(key)?.expireAt === expireAt) {
      cache.delete(key);
    }
  }, cacheTime);
};

export const getCache = (key) => {
  const cached = cache.get(key);
  if (!cached) return null;

  if (cached.expireAt < Date.now()) {
    cache.delete(key);
    return null;
  }

  return cached.value;
};

export const clearCache = (baseUrl) => {
  // Limpar todo o cache relacionado
  cache.forEach((value, cacheKey) => {
    if (cacheKey.startsWith(baseUrl)) {
      // ajuste conforme sua estrutura de URL
      cache.delete(cacheKey);
    }
  });
};
