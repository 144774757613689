import { CategoryOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarType from "../../../../components/avatar/AvatarType";
import Baseline from "../../../../components/form/Baseline";
import {
  getCategories,
  selectCategoriesByRole,
} from "../../../../store/features/configs/categoriesSlice";
import SelectorBox from "../SelectorBox";

function Category({
  value,
  readOnly,
  onChange = () => {},
  size,
  variant = "filled",
  inputRef,
  role = "personal",
  isEditable = true,
  disabled,
  margin = "dense",
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectCategoriesByRole(state, role));

  const loading = status === "loading";

  const onOpen = () => {
    if (status === "idle") {
      dispatch(getCategories(role));
    }
  };

  if (!isEditable) {
    return (
      <Baseline
        Icon={CategoryOutlined}
        label={"Categoria"}
        value={value?.name}
      />
    );
  } else {
    return (
      <SelectorBox Icon={CategoryOutlined}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          openOnFocus
          readOnly={readOnly}
          options={data || []}
          loading={loading}
          value={value || null}
          onOpen={onOpen}
          onChange={(e, v) => {
            onChange(v);
          }}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          getOptionLabel={(option) => option?.name}
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem {...props} selected={selected}>
                <AvatarType icon={option?.icon} />
                <Typography variant="inherit" ml={1}>
                  {option?.name}
                </Typography>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="type_id"
              margin={margin}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {!disabled && params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              label={"Categoria"}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(Category);
