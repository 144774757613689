import {
  Autocomplete,
  Checkbox,
  Chip,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranches,
  selectBranchesByRole,
} from "../../../store/features/configs/branchesSlice";

function AutocompleteBranches({
  role,
  width,
  margin,
  autoFocus,
  sx,
  size,
  value = [],
  variant,
  placeholder,
  onChange = () => {},
  label = "Filiais",
  hiddenLabel,
  innerRef,
  readOnly,
  required,
}) {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) =>
    selectBranchesByRole(state, role)
  );

  const handleOpen = () => {
    if (status === "idle") {
      dispatch(getBranches(role));
    }
  };

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(e, v) => {
        onChange(v);
      }}
      sx={{ width, ...sx }}
      size={size}
      readOnly={readOnly}
      loading={status === "loading"}
      options={data || []}
      onOpen={handleOpen}
      autoHighlight
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            label={option?.name}
            sx={{ fontWeight: "600" }}
            {...getTagProps({ index })}
          />
        ));
      }}
      getOptionLabel={(option) => option?.name}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={option?._id} sx={{ height: 40 }}>
          <Checkbox
            style={{ marginRight: 8, marginLeft: -10 }}
            size="small"
            checked={selected}
          />
          {option?.name}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin={margin}
          hiddenLabel={hiddenLabel}
          required={required}
          variant={variant}
          autoFocus={autoFocus}
          inputRef={innerRef}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

export default memo(AutocompleteBranches);
