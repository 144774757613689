import { Button } from "@mui/material";
import React, { memo, useState } from "react";
import ApprovalModal from "../../../../modals/approval/ApprovalModal";
import RejectModal from "../../../../modals/approval/RejectModal";

export const ApproveButton = ({ expensesIds = [], onApprove = () => {} }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        sx={{
          height: 33,
          fontSize: ".95rem",
          bgcolor: (t) => `${t.palette.success.main}15`,
          transition: "none",
          borderColor: "transparent",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        color="success"
        variant="outlined"
        disableElevation
      >
        Aprovar {expensesIds.length > 1 ? `(${expensesIds?.length})` : ""}
      </Button>
      <ApprovalModal
        open={open}
        onClose={() => setOpen(false)}
        expensesIds={expensesIds}
        onApprove={onApprove}
      />
    </>
  );
};

export const RejectButton = ({ expensesIds = [], onApprove = () => {} }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        sx={{
          height: 33,
          fontSize: ".95rem",
          bgcolor: (t) => `${t.palette.error.main}15`,
          transition: "none",
          borderColor: "transparent",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        color="error"
        variant="outlined"
        disableElevation
      >
        Rejeitar {expensesIds.length > 1 ? `(${expensesIds?.length})` : ""}
      </Button>
      <RejectModal
        open={open}
        onClose={() => setOpen(false)}
        onApprove={onApprove}
        expensesIds={expensesIds}
      />
    </>
  );
};

const SelectionApprovalButtons = ({ expenses = [] }) => {
  return (
    <>
      <RejectButton expensesIds={expenses} />
      <ApproveButton expensesIds={expenses} />
    </>
  );
};

export default memo(SelectionApprovalButtons);
