import { LinearProgress } from "@mui/material";
import React, { memo } from "react";

const Fallback = ({ bottom, mb = 0, isLoading = true }) => {
  return (
    <LinearProgress
      sx={{
        width: "100%",
        height: isLoading ? 3 : 0,
        position: "absolute",
        top: bottom ? undefined : 0,
        bottom: bottom ? mb : undefined,
        left: 0,
        zIndex: (t) => t.zIndex.appBar + 100,
        transition: ".2s ease",
      }}
    />
  );
};

export default memo(Fallback);
