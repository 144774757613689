import { GroupAddOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  DialogContent,
  Divider,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import validator from "validator";
import { HttpClient } from "../../../api/httpClient";
import PermissionsList from "../../../components/USERS/PermissionsList";
import DateInput from "../../../components/inputs/DateInput";
import { setError } from "../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import DialogFullScreenLayout from "../../DialogFullScreenLayout";
import { initialPermissions, userInitialSettings } from "../ModalRegisterUser";
import SettingsContent from "./components/SettingsContent";

const EmailInput = ({ emails, onChange, readOnly }) => {
  const [inputValue, setInputValue] = useState("");

  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");
    const result = text.match(/[\w.-]+@[a-z]+\.[a-z]+(?:\.[a-z]+)*/g);
    if (!text || !result) return;
    onChange((prev) => [...new Set([...prev, ...result])]);
  };

  const renderPopper = useCallback(
    (props) => {
      if (inputValue && validator.isEmail(inputValue)) {
        return <Popper {...props} sx={{ boxShadow: 3 }} />;
      }
    },
    [inputValue]
  );

  const renderTags = useCallback((value, getTagProps) => {
    return value.map((option, index) => (
      <Chip
        variant="outlined"
        avatar={<Avatar>{option[0]?.toUpperCase()}</Avatar>}
        sx={{ fontWeight: "600", fontSize: ".9rem" }}
        label={option}
        {...getTagProps({ index })}
      />
    ));
  }, []);

  return (
    <Box display={"flex"} alignItems={"center"} gap={2} mt={1}>
      <Autocomplete
        autoHighlight
        multiple
        readOnly={readOnly}
        fullWidth
        noOptionsText={"Email já adicionado"}
        options={[...emails, inputValue]}
        filterOptions={(options) =>
          options.filter((op) => !emails.includes(op))
        }
        getOptionDisabled={(option) => !validator.isEmail(option)}
        onChange={(e, v) => {
          onChange(v);
        }}
        renderTags={renderTags}
        PopperComponent={renderPopper}
        onInputChange={(e, v) => setInputValue(v)}
        value={emails}
        getOptionLabel={(option) => option}
        renderOption={(props, option) =>
          validator.isEmail(option) && (
            <MenuItem {...props} sx={{ fontWeight: "600", fontSize: "1rem" }}>
              <Avatar sx={{ mr: 2, width: 35, height: 35 }}>
                {option[0]?.toUpperCase()}
              </Avatar>{" "}
              {option}
            </MenuItem>
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            onPaste={handlePaste}
            fullWidth
            label="Enviar convites para:"
            variant="filled"
            placeholder="Digite um e-mail ou cole de uma lista..."
          />
        )}
      />
    </Box>
  );
};

const ModalInviteUsers = ({ open = false, onClose = () => {} }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [emails, setEmails] = useState([]);

  const [settings, setSettings] = useState(userInitialSettings);

  const [validTo, setValidTo] = useState(
    new Date().setFullYear(new Date().getFullYear() + 2)
  );

  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    if (!open) {
      setEmails([]);
      setSettings(userInitialSettings);
      setPermissions(initialPermissions);
      setValidTo(new Date().setFullYear(new Date().getFullYear() + 2));
    }
  }, [open]);

  //settings
  const handleChangeSettings = useCallback((prop, v) => {
    setSettings((prev) => ({ ...prev, [prop]: v }));
  }, []);

  //permissions
  const handleChangePermissions = useCallback((path = "", value) => {
    const settings = path.split(".");
    setPermissions((prev) => {
      if (!path.includes(".") && !Boolean(settings.length)) {
        prev[path] = value;
        return { ...prev };
      } else if (settings.length === 1) {
        prev[settings[0]] = value;
        return { ...prev };
      } else if (settings.length === 2) {
        prev[settings[0]][settings[1]] = value;
        return { ...prev };
      } else if (settings.length === 3) {
        prev[settings[0]][settings[1]][settings[2]] = value;
        return { ...prev };
      } else if (settings.length === 4) {
        prev[settings[0]][settings[1]][settings[2]][settings[3]] = value;
        return { ...prev };
      }
    });
  }, []);

  const handleInvite = async () => {
    setLoading(true);
    let errors = [];
    for (let index = 0; index < emails.length; index++) {
      const email = emails[index];
      try {
        const formatedData = {
          email,
          name: "",
          lastname: "",
          ...settings,
          status: "inviting",
          apflowId:
            settings.apflowId === "default" || settings.apflowId === ""
              ? null
              : settings.apflowId,
          org: settings.org || null,
          group_id: settings.group_id || null,
          valid_to: validTo,
          roles: {
            master: permissions.master,
            admin: permissions.admin.active
              ? {
                  ...permissions.admin,
                }
              : false,
            approver: permissions.approver,
          },
        };
        const res = await HttpClient.post(`/users`, formatedData);
        // dispatch(
        //   addUser({
        //     id: res.data.userId,
        //     ...formatedData,
        //   })
        // );
      } catch (error) {
        errors.push(email);
      }
    }
    if (errors.length) {
      dispatch(
        setError({
          title: "Erro ao tentar convidar usuários",
          message: `Pode ser que os e-mails inseridos já estejam cadastrados em outra conta da Notepay: ${errors?.join(
            " ,"
          )}`,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Usuários convidados",
        })
      );
    }
    setLoading(false);
    onClose();
  };

  return (
    <DialogFullScreenLayout
      open={false}
      onClose={onClose}
      title={"Convidar usuários"}
      Icon={GroupAddOutlined}
      disableInternalContainer
      loading={loading}
      headerContent={
        <Button
          disabled={!Boolean(emails.length) || loading}
          onClick={handleInvite}
          size="large"
          disableElevation
          variant="contained"
        >
          {loading ? "Convidando" : "Convidar"}
        </Button>
      }
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          p: 0,
        }}
      >
        <Box
          p={2}
          pb={10}
          flex={1}
          height={{ xs: "auto", md: "100%" }}
          display={"flex"}
          flexDirection={"column"}
          flexBasis={0}
          overflow={{ md: "auto", xs: "hidden" }}
          borderRight={1}
          borderColor={"divider"}
          boxShadow={2}
          minWidth={400}
        >
          <EmailInput emails={emails} onChange={setEmails} readOnly={loading} />
          <Typography
            variant="body2"
            color={"text.secondary"}
            display={"flex"}
            alignItems={"center"}
            gap={1}
            mt={2}
            fontWeight={"500"}
          >
            <InfoOutlined fontSize="small" />
            Os e-mails inseridos não poderão ser editados pelos convidados.
          </Typography>
        </Box>
        <Box
          p={2}
          pb={10}
          flex={1}
          height={{ xs: "auto", md: "100%" }}
          display={"flex"}
          flexDirection={"column"}
          flexBasis={0}
          overflow={{ md: "auto", xs: "hidden" }}
          minWidth={400}
        >
          <Typography
            mt={1}
            variant="h5"
            fontSize={"1.3rem"}
            fontWeight={"500"}
          >
            Permissões de acesso
          </Typography>
          <PermissionsList
            sx={{ ml: -2 }}
            permissions={permissions}
            onChangePermissions={handleChangePermissions}
          />
          <Divider sx={{ my: 2, mt: 0, mx: -2 }} />
          <SettingsContent
            groupId={settings?.group_id}
            onInputChange={handleChangeSettings}
          />
          <Divider sx={{ my: 2, mx: -2 }} />
          <Typography
            gutterBottom
            variant="h5"
            fontSize={"1.3rem"}
            fontWeight={"500"}
          >
            Validade de acesso
          </Typography>
          <Typography mb={2} variant="body2" color={"text.secondary"}>
            O acesso dos usuários será válido até
          </Typography>
          <DateInput
            width={300}
            size={"small"}
            hiddenLabel
            label={""}
            variant={"filled"}
            value={validTo}
            onChange={setValidTo}
          />
        </Box>
      </DialogContent>
    </DialogFullScreenLayout>
  );
};

export default ModalInviteUsers;
