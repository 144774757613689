import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { removeManyExpenses } from "../../store/features/expensesSlice";
import RejectionReasonInput from "./components/RejectionReasonInput";

const RejectModal = ({
  open,
  onClose = () => {},
  expensesIds = [],
  onApprove = () => {},
}) => {
  const dispatch = useDispatch();
  const role = "approver";

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (!open) {
      setComment("");
    }
  }, [open]);

  const handleReject = async () => {
    setLoading(true);
    try {
      await APIActions.expenses.reject({
        expensesIds,
        role,
        comment,
      });
      dispatch(
        removeManyExpenses({
          role,
          data: expensesIds,
        })
      );
      dispatch(
        openSnackbar({
          message:
            expensesIds?.length === 1
              ? "Despesa rejeitada"
              : `${expensesIds?.length} despesas rejeitadas`,
        })
      );
      onClose();
      onApprove();
    } catch (error) {
      dispatch(
        setError({
          title:
            expensesIds?.length === 1
              ? "Erro ao rejeitar despesa"
              : "Erro ao rejeitar despesas",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      open={open}
      onClose={() => !comment?.trim() && onClose()}
      fullWidth
      maxWidth="xs"
    >
      {loading && <LinearProgress color="error" />}
      <DialogTitle fontSize={"1.5rem"} fontWeight={"600"}>
        {expensesIds?.length === 1
          ? `Deseja rejeitar a despesa?`
          : `Deseja rejeitar ${expensesIds?.length} despesas?`}
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RejectionReasonInput value={comment} onChange={setComment} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading}
          variant="contained"
          disableElevation
          color="error"
          onClick={handleReject}
        >
          {loading ? "Rejeitando" : "Rejeitar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(RejectModal);
