import { lazy } from "react";

const Users = lazy(() => import("../pages/admin/users"));
const Branches = lazy(() => import("../pages/admin/branches"));
const Groups = lazy(() => import("../pages/admin/groups"));
const Categories = lazy(() => import("../pages/admin/categories"));
const Rules = lazy(() => import("../pages/admin/rules"));
const Audit = lazy(() => import("../pages/admin/audit"));
const Payments = lazy(() => import("../pages/admin/payments"));
const Vehicles = lazy(() => import("../pages/admin/vehicles"));
const RoutePolicies = lazy(() => import("../pages/admin/route-policies"));
const Projects = lazy(() => import("../pages/admin/projects"));
const APIRestIntegrationPage = lazy(() =>
  import("../pages/admin/api-rest-integration")
);

export const ADMIN_ROUTES = [
  {
    path: "users",
    element: <Users />,
    children: [],
  },
  {
    path: "branches",
    element: <Branches />,
    children: [],
  },
  {
    path: "groups",
    element: <Groups />,
    children: [],
  },
  {
    path: "categories",
    element: <Categories />,
    children: [],
  },
  {
    path: "rules",
    element: <Rules />,
    children: [],
  },
  {
    path: "audit",
    element: <Audit />,
    children: [],
  },
  {
    path: "route-policies",
    element: <RoutePolicies />,
    children: [],
  },
  {
    path: "payment-methods",
    element: <Payments />,
    children: [],
  },
  {
    path: "vehicles",
    element: <Vehicles />,
    children: [],
  },
  {
    path: "projects",
    element: <Projects />,
    children: [],
  },
  {
    path: "sap-integration",
    element: <>sap-integration</>,
    children: [],
  },
  {
    path: "api-rest",
    element: <APIRestIntegrationPage />,
    children: [],
  },
  //   {
  //     path: "billing",
  //     element: (
  //       <CheckConfigAccessWrapper settingKey={"master"} showNoAccessComponent>
  //         <BillingPage />
  //       </CheckConfigAccessWrapper>
  //     ),
  //     children: [
  //       {
  //         path: "invoices",
  //         element: (
  //           <CheckConfigAccessWrapper settingKey={"master"} showNoAccessComponent>
  //             <InvoicesPage />
  //           </CheckConfigAccessWrapper>
  //         ),
  //         children: [
  //           {
  //             path: ":invoiceId",
  //             element: (
  //               <CheckConfigAccessWrapper
  //                 settingKey={"master"}
  //                 showNoAccessComponent
  //               >
  //                 <InvoiceViewPage />
  //               </CheckConfigAccessWrapper>
  //             ),
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     path: "advanced-settings",
  //     element: (
  //       <CheckConfigAccessWrapper settingKey={"master"} showNoAccessComponent>
  //         <AdvancedSettings />
  //       </CheckConfigAccessWrapper>
  //     ),
  //     children: [],
  //   },
];
