import {
  AccountTreeOutlined,
  BusinessCenterOutlined,
  Groups2Outlined,
  NotesOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import SectionLine from "../../../../components/form/SectionLine";
import BranchSelector from "../../../../components/inputs/selectors/BranchSelector";
import GroupSelector from "../../../../components/inputs/selectors/GroupSelector";
import SelectApprovalFlow from "../../../../components/inputs/selectors/SelectApprovalFlow";
import TextInput from "../../../../components/inputs/text-input";

const SettingsContent = ({ onInputChange = () => {}, groupId }) => {
  return (
    <>
      <Typography mb={2} variant="h5" fontSize={"1.3rem"} fontWeight={"500"}>
        Configurações
      </Typography>
      <Box ml={1}>
        <SectionLine Icon={BusinessCenterOutlined}>
          <BranchSelector
            margin={"dense"}
            variant={"filled"}
            onChange={(v) => onInputChange("org", v)}
          />
        </SectionLine>
        <SectionLine Icon={Groups2Outlined}>
          <GroupSelector
            margin={"dense"}
            variant={"filled"}
            onChange={(v) => onInputChange("group_id", v)}
          />
        </SectionLine>
        <SectionLine Icon={AccountTreeOutlined}>
          <SelectApprovalFlow
            selectedGroupId={groupId}
            margin={"dense"}
            variant={"filled"}
            onChange={(v) => onInputChange("apflowId", v)}
          />
        </SectionLine>
        {/* <SectionLine Icon={BadgeOutlined}>
          <OccupationSelector
            margin={"dense"}
            variant={"filled"}
            onChange={(v) => onInputChange("occupation", v)}
          />
        </SectionLine> */}
        <SectionLine Icon={NotesOutlined}>
          <TextInput
            margin={"dense"}
            variant={"filled"}
            label="Notas"
            onChange={(v) => onInputChange("notes", v)}
          />
        </SectionLine>
      </Box>
    </>
  );
};

export default SettingsContent;
