import { Avatar } from "@mui/material";
import React, { memo } from "react";
import getInitials from "../../utils/getInitials";

const AvatarInitialName = ({ value = "" }) => {
  return (
    <Avatar
      sx={{
        height: 35,
        width: 35,
        bgcolor: "elevation1.main",
        color: "text.primary",
        fontWeight: "600",
      }}
    >
      {getInitials(value || "")}
    </Avatar>
  );
};

export default memo(AvatarInitialName);
