import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React, { memo } from "react";
import { Link } from "react-router-dom";

const BaseItem = ({
  width,
  selected,
  title,
  content,
  Icon,
  ActiveIcon,
  onClick = () => {},
  subtitle,
  disabled,
  value,
  height = 40,
  sx,
}) => {
  return (
    <ListItemButton
      disabled={disabled}
      onClick={(e) => {
        onClick(value);
      }}
      title={title}
      sx={{
        height,
        width,
        bgcolor: (t) => selected && `${t.palette.primary.main}25`,
        transition: "none",
        overflow: "hidden",
        position: "relative",
        borderRadius: 100,
        color: (t) => `${t.palette.primary.main}60`,
        ":hover": {
          bgcolor: (t) =>
            selected ? `${t.palette.primary.main}25` : "action.hover",
        },
        "&& .MuiTouchRipple-rippleVisible": {
          animationDuration: "50ms",
        },
        ...sx,
      }}
    >
      {Icon && (
        <ListItemIcon>
          {ActiveIcon && selected ? (
            <ActiveIcon
              sx={{
                fontSize: "20px",
                color: "secondary.main",
              }}
            />
          ) : (
            <Icon
              sx={{
                fontSize: "20px",
                color: !selected ? "action.active" : "secondary.main",
                opacity: selected ? 1 : 0.85,
              }}
            />
          )}
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          variant: "body1",
          fontSize: ".875rem",
          fontWeight: "600",
          noWrap: true,
          color: "text.primary",
          ml: Icon ? -2.2 : undefined,
        }}
        secondaryTypographyProps={{
          fontSize: ".8rem",
          mt: -0.3,
          ml: Icon ? -2.2 : undefined,
        }}
        primary={title}
        secondary={subtitle}
      />
      {content}
    </ListItemButton>
  );
};

function SidebarItem({
  path,
  title,
  subtitle,
  disableLink,
  Icon,
  ActiveIcon,
  width = "100%",
  disableHiddenLabel,
  onClick,
  content,
  disabled,
  isSelected,
  value,
  target,
  height,
  sx,
}) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const selected = isSelected;

  if (disableLink) {
    return (
      <BaseItem
        onClick={onClick}
        height={height}
        Icon={Icon}
        ActiveIcon={ActiveIcon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        subtitle={subtitle}
        disabled={disabled}
        value={value}
        sx={sx}
      />
    );
  }

  return (
    <Link
      id={`sidebar_item_${path}`}
      style={{
        textDecoration: "none",
        outline: "none",
        cursor: disabled ? "default" : "pointer",
        color: `${main}45`,
      }}
      target={target}
      to={disabled ? "#" : path}
    >
      <BaseItem
        disabled={disabled}
        value={value}
        Icon={Icon}
        ActiveIcon={ActiveIcon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        subtitle={subtitle}
        onClick={onClick}
        height={height}
        sx={sx}
      />
    </Link>
  );
}

export default memo(SidebarItem);
