import { Badge, Box, ListItemIcon, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -1,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
    fontWeight: "700",
    fontSize: ".6rem",
  },
}));

const ButtonBox = memo(
  ({
    activated,
    Icon,
    ActiveIcon,
    title,
    tooltip,
    badge,
    palette,
    tour_id,
    onClick = () => {},
  }) => {
    const [hovered, setHovered] = useState(false);
    const [clicking, setClicking] = useState(false);

    const handleClick = (e) => {
      onClick();
      setHovered(false);
    };

    const handleMouseEnter = () => setHovered(true);

    const handleMouseLeave = () => setHovered(false);

    return (
      <Box
        title={tooltip || title}
        component={"div"}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={() => setClicking(true)}
        onMouseUp={() => setClicking(false)}
        tour_id={activated ? "" : tour_id}
        sx={{
          width: "100%",
          py: 1.3,
          px: 1,
          display: "flex",
          position: "relative",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 0,
          transition: "none",
          cursor: "pointer",
        }}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            alignItems: "center",
            color:
              activated || hovered
                ? palette.secondary.main
                : palette.text.secondary,
            justifyContent: "center",
            bgcolor:
              hovered && activated
                ? `${palette.primary.main}40`
                : activated
                ? `${palette.primary.main}30`
                : hovered
                ? "action.hover"
                : "transparent",
            borderRadius: 100,
            height: 30,
          }}
        >
          <StyledBadge color="primary" badgeContent={activated ? 0 : badge}>
            {activated
              ? ActiveIcon && (
                  <ActiveIcon
                    sx={{
                      fontSize: "26px",
                      transform: clicking
                        ? "scale(1.1)"
                        : hovered
                        ? "scale(1.05)"
                        : "scale(1)",
                      transition: ".1s ease",
                    }}
                  />
                )
              : Icon && (
                  <Icon
                    sx={{
                      fontSize: "26px",
                      transform: clicking
                        ? "scale(1.1)"
                        : hovered
                        ? "scale(1.05)"
                        : "scale(1)",
                      transition: ".1s ease",
                    }}
                  />
                )}
          </StyledBadge>
        </ListItemIcon>
        <Typography
          mt={0.3}
          fontWeight={"600"}
          fontSize={".8rem"}
          lineHeight={"normal"}
          textAlign={"center"}
          noWrap
          sx={{
            opacity: activated || hovered ? 1 : 0.75,
          }}
          color={"text.primary"}
        >
          {title}
        </Typography>
      </Box>
    );
  }
);

function SidebarButtonItem({
  disableLink,
  path,
  title,
  Icon,
  badge,
  tour_id,
  onClick = () => {},
  ActiveIcon,
  isSelected,
  tooltip,
}) {
  const { palette } = useTheme();

  return disableLink ? (
    <ButtonBox
      tour_id={tour_id}
      Icon={Icon}
      activated={isSelected}
      badge={badge}
      palette={palette}
      title={title}
      onClick={onClick}
      ActiveIcon={ActiveIcon}
      tooltip={tooltip}
    />
  ) : (
    <Link
      draggable={false}
      style={{
        textDecoration: "none",
        color: isSelected ? palette.primary.main : palette.text.secondary,
      }}
      to={path}
    >
      <ButtonBox
        tour_id={tour_id}
        Icon={Icon}
        activated={isSelected}
        badge={badge}
        palette={palette}
        title={title}
        tooltip={tooltip}
        ActiveIcon={ActiveIcon}
      />
    </Link>
  );
}

export default memo(SidebarButtonItem);
