import { nanoid } from "@reduxjs/toolkit";
import imageCompression from "browser-image-compression";
import { getBase64, getBlob } from "./more/images_transform";

function bytesToMB(bytes) {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;
  return megabytes.toFixed(2); // Formata o valor com 2 casas decimais
}

export const formatFilesToReceipts = async (
  acceptedFiles = [],
  generateBlob = false
) => {
  const prepared = await Promise.all(
    acceptedFiles.map(async (file) => {
      const receiptId = nanoid(5);
      let compressedFile = file;

      console.log("originalSize: ", bytesToMB(file.size));

      // Verifica se o arquivo é uma imagem e se deve ser comprimido
      if (file.type?.includes("image")) {
        try {
          compressedFile = await imageCompression(file, {
            initialQuality: 0.9,
            maxSizeMB: 1, // Ajuste o tamanho máximo da imagem em MB
            maxWidthOrHeight: 1280, // Ajuste a largura ou altura máxima
            useWebWorker: true, // Usa web workers para melhorar o desempenho
          });
        } catch (error) {
          console.error(`Error compressing file ${file.name}:`, error);
        }
      }
      console.log("compressedSize: ", bytesToMB(compressedFile.size));

      const url = URL.createObjectURL(compressedFile);
      const base64 = await getBase64(url);

      const receipt = {
        id: receiptId,
        filename: compressedFile.name,
        url,
        is_image: !compressedFile.type?.includes("application"),
        type: compressedFile.type,
        mimetype: compressedFile.type,
        base64,
      };

      if (generateBlob) {
        const blob = await getBlob(file);
        receipt.blob = new Blob([blob], { type: file.type });
      }

      try {
        return receipt;
      } catch (error) {
        console.error(`Error processing file ${compressedFile.name}:`, error);
        return null; // ou alguma outra forma de lidar com o erro
      }
    })
  );

  // Filtra qualquer valor nulo que pode ter sido retornado em caso de erro
  return prepared.filter((file) => file !== null);
};
