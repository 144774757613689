import { Alert, Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";

function DropzoneBase({
  children,
  getInputProps,
  getRootProps,
  isDragActive,
  isDragAccept,
  isDragReject,
  sx,
}) {
  const { primary, error, background, elevation1 } = useTheme().palette;

  const activeStyle = {
    border: "2px dotted",
    borderColor: primary.main,
    backgroundColor: elevation1?.main,
  };

  const acceptStyle = {
    border: "2px dotted",
    borderColor: primary.main,
    backgroundColor: elevation1?.main,
  };

  const rejectStyle = {
    backgroundColor: `${error.main}10`,
    borderColor: error.main,
  };

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box sx={sx} {...getRootProps({ style })}>
      {isDragReject && (
        <Alert severity="error" sx={{ width: "100%" }}>
          Formato não aceito. Tente adicionar arquivos PNG, JPEG, WEBP ou PDF
        </Alert>
      )}
      {children}
    </Box>
  );
}

export default DropzoneBase;
