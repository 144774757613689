import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Box,
    Collapse,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { ADMIN_RULE_MESSAGE_TYPES } from "../../../../../../utils/initialStates";
import { DEFAULT_RULES_MESSAGE } from "../../../../../../utils/rules";

export const AlertRules = memo(({ severity = "error", rules = [] }) => {
  const [expanded, setExpanded] = useState(true);
  const { Icon, text, color } = ADMIN_RULE_MESSAGE_TYPES[severity || "error"];
  return (
    <Alert
      onClick={() => setExpanded(true)}
      icon={!expanded ? <Icon /> : <></>}
      severity={severity}
      sx={{
        mb: 1,
        p: 1,
        pb: 0,
        position: "relative",
        cursor: expanded ? "default" : "pointer",
      }}
    >
      <AlertTitle>{expanded ? "Atenção" : text}</AlertTitle>
      <IconButton
        color="inherit"
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
        sx={{ position: "absolute", top: 5, right: 5 }}
      >
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Collapse timeout={0} unmountOnExit in={expanded}>
        <Stack gap={0.5}>
          {rules?.map((rule, idx) => (
            <ListItem
              disablePadding
              sx={{ alignItems: "flex-start" }}
              disableGutters
              key={idx.toString()}
            >
              <Icon color={color} fontSize="small" sx={{ mr: 1, mt: 0.7 }} />
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "600",
                  fontSize: ".9rem",
                }}
                primary={
                  rule?.displayMessage ||
                  DEFAULT_RULES_MESSAGE[rule?.severity] ||
                  ""
                }
              />
            </ListItem>
          ))}
        </Stack>
      </Collapse>
    </Alert>
  );
});

const RulesIndicator = ({ rules = [], mainSeverity }) => {
  const [menu, setMenu] = useState(null);
  const { Icon, text, color } =
    ADMIN_RULE_MESSAGE_TYPES[mainSeverity || "info"];
  return (
    <>
      <Tooltip title={text}>
        <IconButton
          onClick={(e) => setMenu(e.target)}
          id="expense_rules_indicator_button"
          color={color}
        >
          {Icon && <Icon />}
        </IconButton>
      </Tooltip>
      <Popover
        transitionDuration={50}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
        slotProps={{
          paper: {
            sx: {
              width: 500,
            },
          },
        }}
      >
        <DialogTitle>
          <Typography fontSize={"1.2rem"} fontWeight={"600"}>
            Alertas
          </Typography>
          <Box flex={1} />
          <IconButton onClick={() => setMenu(null)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List disablePadding>
            {rules?.map((rule, idx) => {
              const { Icon, color } = ADMIN_RULE_MESSAGE_TYPES[rule?.severity];
              return (
                <ListItem key={idx.toString()}>
                  <ListItemIcon>
                    <Icon fontSize="small" color={color} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ ml: -2 }}
                    primaryTypographyProps={{ fontWeight: "600" }}
                    primary={
                      rule?.displayMessage ||
                      DEFAULT_RULES_MESSAGE[rule?.severity] ||
                      ""
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Popover>
    </>
  );
};
export default memo(RulesIndicator);
