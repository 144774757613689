import {
  AccountCircle,
  AccountCircleOutlined,
  Badge,
  BadgeOutlined,
  CreditCard,
  PersonAdd,
  PersonAddOutlined,
} from "@mui/icons-material";
import { Divider, List } from "@mui/material";
import React, { memo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { isPathSelected } from "../../../Sidebar";
import SidebarRoundedItem from "../../../components/SidebarRoundedItem";

const SettingsSidebar = () => {
  const { pathname } = useLocation();

  const getIsSelected = useCallback(
    (path) => isPathSelected(`/settings/${path}`, pathname),
    [pathname]
  );

  return (
    <>
      <List disablePadding>
        <SidebarRoundedItem
          Icon={BadgeOutlined}
          path={"/settings/general"}
          title={"Dados pessoais"}
          isSelected={getIsSelected("general")}
          ActiveIcon={Badge}
        />
        <SidebarRoundedItem
          Icon={AccountCircleOutlined}
          path={"/settings/ds"}
          title={"Delegar conta"}
          isSelected={getIsSelected("ds")}
          ActiveIcon={AccountCircle}
        />
        <SidebarRoundedItem
          Icon={PersonAddOutlined}
          path={"/settings/aaaa"}
          title={"Aprovador substituto"}
          isSelected={getIsSelected("aaaa")}
          ActiveIcon={PersonAdd}
        />
        <SidebarRoundedItem
          Icon={CreditCard}
          path={"/settings/billing"}
          title={"Plano e faturamento"}
          isSelected={getIsSelected("billing")}
          ActiveIcon={CreditCard}
        />
        <Divider sx={{ my: 1 }} />
        <SidebarRoundedItem
          path={"/settings/dsds"}
          title={"Política de privacidade"}
          isSelected={getIsSelected("dsds")}
        />
        <SidebarRoundedItem
          path={"/settings/sss"}
          title={"Termos de uso"}
          isSelected={getIsSelected("sss")}
        />
      </List>
    </>
  );
};

export default memo(SettingsSidebar);
