import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";
import { DEFAULT_ENTITY_STATE } from "../../utils";

const baseName = "routePolicies";

const initialState = {
  personal: DEFAULT_ENTITY_STATE,
  approver: DEFAULT_ENTITY_STATE,
  financial: DEFAULT_ENTITY_STATE,
  admin: DEFAULT_ENTITY_STATE,
};

export const getRoutePolicies = createAsyncThunk(
  `${baseName}/getRoutePolicies`,
  async (role) => {
    const res = await HttpClient.get({
      url: "/routePolicy",
      params: {
        role,
      },
    });
    return res?.data;
  }
);

export const routePoliciesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupRoutePolicies(state, { payload }) {
      const { data, role } = payload || {};
      if (!state[role]) return;
      //CONFIGURAR ENTRADA DE DADOS
      state[role].data =
        (data || [])?.map((item) => ({ ...item, id: item?._id })) || [];
    },

    //ADM ACTIONS
    addRoutePolicy(state, { payload }) {
      state.admin.data.push(payload);
    },
    updateRoutePolicy(state, { payload }) {
      const { id, changes } = payload;
      const index = state.admin.data.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.admin.data[index] = {
          ...state.admin.data[index],
          ...changes,
        };
      }
    },
    removeRoutePolicy(state, { payload }) {
      state.admin.data = [
        ...state.admin.data?.filter((item) => item?.id !== payload),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoutePolicies.pending, (state, action) => {
        const role = action.meta.arg || "personal";
        state[role].status = "loading";
        state[role].error = null;
      })
      .addCase(getRoutePolicies.rejected, (state, action) => {
        const role = action.meta.arg || "personal";
        state[role].status = "failed";
        state[role].error = action.error.message || null;
      })
      .addCase(getRoutePolicies.fulfilled, (state, action) => {
        const role = action.meta.arg || "personal";
        state[role].status = "succeeded";
        state[role].error = null;

        //Dados API
        const data = action.payload || [];
        routePoliciesSlice.caseReducers.setupRoutePolicies(state, {
          payload: {
            data,
            role,
          },
        });
      });
  },
});

export const {
  addRoutePolicy,
  removeRoutePolicy,
  setupRoutePolicies,
  updateRoutePolicy,
} = routePoliciesSlice.actions;

export default routePoliciesSlice.reducer;

export const selectRoutePoliciesByRole = createSelector(
  [(state) => state.routePolicies, (state, role) => role],
  (routePoliciesState = {}, role = "") => {
    const prevData = routePoliciesState[role] || DEFAULT_ENTITY_STATE;

    return {
      ...prevData,
      total: prevData?.data?.length || 0,
    };
  }
);

export const selectRoutePolicyById = createSelector(
  [selectRoutePoliciesByRole, (state, role, policyId) => policyId],
  (routePoliciesState = {}, policyId = "") => {
    return (
      routePoliciesState?.data?.find((item) => item?.id === policyId) || {}
    );
  }
);
