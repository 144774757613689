import { Box, Button, Typography } from "@mui/material";
import React, { memo } from "react";
import Lottie from "react-lottie";
import scanningLottie from "../../../../assets/lotties/scanning.json";

const ScanningContent = ({ scannerAbortControllerRef }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      gap={1}
      minHeight={400}
    >
      <Lottie
        speed={1.3}
        options={{
          loop: true,
          autoplay: true,
          animationData: scanningLottie,
        }}
      />
      <Typography
        fontWeight={"500"}
        color={"text.secondary"}
        fontSize={"1.2rem"}
      >
        Analisando comprovante...
      </Typography>
      <Button onClick={() => scannerAbortControllerRef?.current?.abort()}>
        Cancelar
      </Button>
    </Box>
  );
};

export default memo(ScanningContent);
