import { OFFLINE_SCHEDULE_RETRY } from "@redux-offline/redux-offline/lib/constants";
import {
  saveAccountData,
  setSyncing,
  syncAccountData,
  updateLastSync,
  updateSynced,
} from "../store/features/accountSlice";
import { openSnackbar } from "../store/features/base/snackbarBaseSlice";
import {
  setupCustomFields
} from "../store/features/configs/customFieldsSlice";
import {
  setupExpensePolicies
} from "../store/features/configs/expensePoliciesSlice";
import {
  setupExpenseTypes
} from "../store/features/configs/expenseTypesSlice";
import {
  setupPaymentTypes
} from "../store/features/configs/paymentTypesSlice";
import {
  setupProjects
} from "../store/features/configs/projectsSlice";
import {
  setupRejectionReasons
} from "../store/features/configs/rejectionReasonsSlice";
import {
  setupRoutePolicies
} from "../store/features/configs/routePoliciesSlice";
import {
  setupSnippets
} from "../store/features/configs/snippetsSlice";
import { store } from "../store/store";
import { refreshToken } from "./auth";
import { HttpClient } from "./httpClient";

export const syncAll = async ({
  onError = () => {},
  onSuccess = () => {},
  onFinally = () => {},
} = {}) => {
  try {
    //Data de sync.
    const startSyncTimestamp = new Date().toISOString();

    const settingsResponse = await HttpClient.get(`/settings/sync-all`);

    const {
      expense_types,
      projects,
      payment_types,
      expense_policies,
      route_policies,
      snippets,
      open_expenses,
      account,
      user,
      rejection_reasons,
      custom_fields,
    } = settingsResponse.data;

    if (user) {
      store.dispatch(
        saveAccountData({
          userId: user?._id,
          accountId: account?._id,
          currency: account?.currency,
          companyName: account?.name,
          org: account?.org,
          accountStatus: account?.status,
          name: user?.name,
          lastname: user?.lastname,
          email: user?.email,
          occupation: user?.occupation?.name,
          org: user?.org?.name,
          accountStatus: account?.status,
          accountHasLogo: account?.has_logo,
          isApprover: Boolean(user?.roles?.approver),
          isAdmin: Boolean(user?.roles?.admin),
          isMaster: Boolean(user?.roles?.master),
          hasPhoto: Boolean(user?.has_profile_photo),
          photoVersion: user?.profile_photo_version,
        })
      );
    }

    //Configurar todos os redutores
    store.dispatch(setupExpensePolicies(expense_policies));
    store.dispatch(setupExpenseTypes(expense_types));
    store.dispatch(setupProjects(projects));
    store.dispatch(setupPaymentTypes(payment_types));
    store.dispatch(setupRoutePolicies(route_policies));
    store.dispatch(setupSnippets(snippets));
    store.dispatch(setupRejectionReasons(rejection_reasons));
    store.dispatch(setupCustomFields(custom_fields));
    // store.dispatch(
    //   setupExpenses(open_expenses?.map((exp) => ({ ...exp, status: "O" })))
    // );

    //Resync requests
    // store.dispatch(
    //   fetchRequests({
    //     skip: 0,
    //     limit: 50,
    //     resync: true,
    //   })
    // );

    //Atualizar datas de sync
    store.dispatch(updateSynced());
    store.dispatch(updateLastSync(startSyncTimestamp));

    //commit
    onSuccess();
  } catch (error) {
    onError(error);
    const status = error?.response?.status?.toString();
    if (status === "401") {
      await refreshToken();
    }
  } finally {
    onFinally();
  }
};

//Função para alterar estado carregando da sync
const toggleSyncing = (bool) => {
  store.dispatch(setSyncing(bool));
};

export const synchronize = async () => {
  //Pegar estado da loja
  const state = store.getState();

  //Caso ja estiver sincronizando ou não estiver logado, cancelar sync
  if (state.account.checkingAccess || state.account.syncing) {
    return;
  }

  //Começar a carregar
  toggleSyncing(true);

  try {
    //Última data de sincronização
    const lastSync = state.account.lastSync;

    //Se não tiver nenhuma data de última sync, forçar syncAll
    if (!lastSync) {
      await syncAll();
      toggleSyncing(false);
      return;
    }

    //Nova data de sync
    const now = new Date().toISOString();

    //GET events, passando última data de sync
    const syncResponse = await HttpClient.get("/sync", {
      refDate: new Date(lastSync).toISOString(),
    });

    //Se não tiver respostas, cancelar operação
    if (!syncResponse) {
      toggleSyncing(false);
      return;
    }

    //Dados da resposta
    const { events, ok, forceResyncAll } = syncResponse.data;

    //Forçar syncAll caso 'forceResyncAll'
    if (forceResyncAll) {
      await syncAll();
      toggleSyncing(false);
      return;
    }

    if (ok) {
      //Precessar eventos e despachar thunks pela loja, com callback de falha
      const failed = processSyncEvents(events);

      //Caso houver alguma falha durante o processamento, fazer syncAll
      if (failed) {
        await syncAll();
        toggleSyncing(false);
        return;
      }

      //Se tudo deu certo, atualizar a última data de sincronização com sucesso
      store.dispatch(updateLastSync(now));
    }
  } catch (error) {
    //Lidar com erro de sincronização
    await handleSyncError(error);
  } finally {
    //Terminou de  carregar
    toggleSyncing(false);
    //Agendar uma nova tentativa para efetivar dados parados na fila offline
    store.dispatch({ type: OFFLINE_SCHEDULE_RETRY, payload: { delay: 250 } });
  }
};

// Processar eventos e atualizar a loja
const processSyncEvents = (events) => {
  //Armazenar estado de falha
  let failed = false;

  try {
    //Realizar um FOR, para lidar com data evento em específico
    for (const effect of events) {
      switch (effect.entity) {
        case "expenses":
          // store.dispatch(syncExpenses());
          break;
        case "requests":
          // const totalRequests = store.getState().requests.ids.length;
          // store.dispatch(
          //   fetchRequests({
          //     skip: 0,
          //     limit: totalRequests < 15 ? 15 : totalRequests,
          //     resync: true,
          //   })
          // );
          break;
        case "approver":
          // store.dispatch(fetchApprovalRequests());
          break;
        case "expenseTypes":
          // store.dispatch(syncExpenseTypes());
          break;
        case "paymentTypes":
          // store.dispatch(syncPaymentTypes());
          break;
        case "expensePolicies":
          // store.dispatch(syncExpensePolicies());
          break;
        case "projects":
          // store.dispatch(syncProjects());
          break;
        case "snippets":
          // store.dispatch(syncSnippets());
          break;
        case "rejectionReasons":
          // store.dispatch(syncRejectionReasons());
          break;
        case "routePolicies":
          // store.dispatch(syncRoutePolicies());
          break;
        case "customFields":
          // store.dispatch(syncCustomFields());
          break;
        case "account":
          store.dispatch(syncAccountData());
          break;
        default:
          //Sem nenhuma condição adicionada para o evento recebido, definir falha
          failed = true;
          break;
      }
    }
  } catch (error) {
    //Falha, caso catch
    failed = true;
  }
  //Retornar status de falha
  return failed;
};

const handleSyncError = async (error) => {
  //Pegar status do erro em string
  const status = error?.response?.status?.toString();

  //Se o erro for por motivo de acesso, tentar revogar
  if (status === "401") {
    const { isAuth } = await refreshToken();
    if (isAuth) {
      //Se deu certo, e está logado. Tentar novamente sincronizar
      synchronize();
    }
  } else {
    await syncAll({
      onError: () => {
        store.dispatch(openSnackbar({ message: "Falha ao sincronizar dados" }));
      },
    });
  }
};
