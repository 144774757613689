import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./features";

//offline

//persist
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { root_migrations } from "./migrations";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["account", "settings"],
  blacklist: ["expenses"],
  // transforms: [PersistTransformer],
  version: 0,
  migrate: createMigrate(root_migrations, {
    debug: false,
  }),
};

const appReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers({
      autoBatch: { type: "tick" },
    });
  },
});

const persistor = persistStore(store);

export { persistor, store };
