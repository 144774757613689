import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { memo, useState } from "react";
import BoxContent from "../../../../../routes/components/BoxContent";

const FilterMenu = (props) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Box
      component={"div"}
      className="right_container"
      width={"100%"}
      height={"100%"}
      p={2}
      pr={expanded ? 2 : 0}
      pl={0.5}
      pt={0}
      sx={{
        transition: ".15s ease",
      }}
    >
      <BoxContent
        sx={{
          width: expanded ? "22em" : 0,
          overflow: "hidden",
          transition: ".15s ease",
        }}
      >
        <Stack p={2} direction={"row"} alignItems={"center"}>
          <Typography variant="h5" fontWeight={"600"}>
            Filtros
          </Typography>
          <Box flex={1} />
        </Stack>
        <Box flex={1} flexBasis={0} overflow={"auto"}></Box>
        <IconButton
          color="inherit"
          disableRipple
          onClick={() => setExpanded(false)}
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            boxShadow: 2,
            bgcolor: "elevation1.main",
            ":hover": { bgcolor: "elevation2.main" },
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </BoxContent>

      <Tooltip title="Mostrar filtros" placement="left">
        <Box
          component={"div"}
          onClick={() => setExpanded(true)}
          boxShadow={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          width={50}
          height={40}
          position={"fixed"}
          bgcolor={"elevation1.main"}
          zIndex={1000}
          right={expanded ? -100 : 0}
          pl={1}
          bottom={25}
          sx={{
            transition: ".15s ease",
            borderTopLeftRadius: 100,
            borderBottomLeftRadius: 100,
            ":hover": {
              width: 60,
              cursor: "pointer",
              bgcolor: "elevation2.main",
            },
          }}
        >
          <KeyboardArrowLeft sx={{ fontSize: "28px" }} color="inherit" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default memo(FilterMenu);
