import {
  AssignmentOutlined,
  BusinessOutlined,
  Groups2Outlined,
  PeopleAltOutlined,
} from "@mui/icons-material";
import { Box, Divider, Skeleton, Stack } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import UserListItem from "../../../../components/USERS/UserListItem";
import Baseline from "../../../../components/form/Baseline";
import { expenseInitialState } from "../../../../utils/initialStates";
import AmountConversionInfo from "../../components/conversion/AmountConversionInfo";
import AmounInput from "../../components/inputs/AmounInput";
import CategoryInput from "../../components/inputs/CategoryInput";
import DateInput from "../../components/inputs/DateInput";
import DistanceInput from "../../components/inputs/DistanceInput";
import LocalInput from "../../components/inputs/LocalInput";
import ObsInput from "../../components/inputs/ObsInput";
import PaymentInput from "../../components/inputs/PaymentInput";
import ProjectInput from "../../components/inputs/ProjectInput";
import RoutePoliciesInput from "../../components/inputs/RoutePoliciesInput";
import AccommodationInfo from "./AccommodationInfo";
import VehicleInfo from "./VehicleInfo";

const variant = "outlined";

const FormContent = ({
  values = expenseInitialState,
  onChangeValue = () => {},
  loading,
  disabled,
  autoFocusInputs = [],
  isEditable = true,
  role,
  user = {},
  group = {},
  branch = {},
  reportTitle = "",
  isMatched,
  hasPermissionToEdit,
  isRoute,
}) => {
  const handleChangeCurrency = useCallback(
    (v) => onChangeValue("currency", v),
    []
  );
  const handleChangeAmount = useCallback((v) => onChangeValue("amount", v), []);
  const handleChangeCategory = useCallback(
    (v) => onChangeValue("category", v),
    []
  );
  const handleChangeDistance = useCallback(
    (v) => onChangeValue("distance", v),
    []
  );
  const handleChangeDate = useCallback((v) => onChangeValue("date", v), []);
  const handleChangeRoutePolicy = useCallback(
    (v) => onChangeValue("routePolicy", v),
    []
  );
  const handleChangePayment = useCallback(
    (v) => onChangeValue("payment", v),
    []
  );
  const handleChangeProject = useCallback(
    (v) => onChangeValue("project", v),
    []
  );
  const handleChangeNotes = useCallback((v) => onChangeValue("notes", v), []);

  const handleChangeLocal = useCallback((v) => onChangeValue("place", v), []);

  const showAllFields = useMemo(
    () => ["approver", "financial"].includes(role),
    [role]
  );

  const margin = useMemo(
    () => (isEditable ? "dense" : hasPermissionToEdit ? "none" : "dense"),
    [isEditable, hasPermissionToEdit]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={!isEditable ? 2.5 : 1}>
      {loading ? (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <Stack
              key={index}
              mt={index === 0 ? 0 : -2}
              direction={"row"}
              alignItems={"center"}
              gap={2}
            >
              <Skeleton variant="circular" height={30} width={30} />
              <Skeleton
                sx={{ flex: 1, maxWidth: index % 2 === 0 ? 280 : 300 }}
                height={55}
              />
            </Stack>
          ))}
        </>
      ) : (
        <>
          {showAllFields && (
            <Box mb={-1}>
              <Baseline
                label={"Usuário"}
                Icon={PeopleAltOutlined}
                value={
                  <UserListItem
                    clickable={false}
                    disableGutters
                    dense
                    user={user}
                  />
                }
              />
            </Box>
          )}
          {!isRoute && (
            <Box mb={isEditable ? 1 : 0}>
              <AmounInput
                isEditable={isEditable}
                autoFocus={autoFocusInputs.includes("amount")}
                readOnly={isMatched || disabled}
                currency={values?.currency}
                value={values?.amount}
                onChangeCurrency={handleChangeCurrency}
                onChange={handleChangeAmount}
                disabled={isMatched}
              />

              {Boolean(values?.conversionAmount) &&
                values?.displayAmount?.currency !==
                  values?.originalAmount?.currency && (
                  <AmountConversionInfo
                    conversionAmount={values?.conversionAmount}
                    originalAmount={values?.originalAmount}
                    displayAmount={values?.displayAmount}
                  />
                )}
            </Box>
          )}
          {isRoute && (
            <DistanceInput
              isEditable={isEditable || hasPermissionToEdit}
              readOnly={disabled}
              variant={variant}
              value={values.distance || ""}
              onChange={handleChangeDistance}
              margin={margin}
            />
          )}
          {!isRoute && (
            <>
              <CategoryInput
                isEditable={isEditable || hasPermissionToEdit}
                readOnly={disabled}
                variant={variant}
                value={values.category}
                onChange={handleChangeCategory}
                margin={margin}
              />
              <LocalInput
                disabled={disabled}
                isEditable={isEditable || hasPermissionToEdit}
                variant={variant}
                readOnly={disabled}
                value={values?.place}
                onChange={handleChangeLocal}
                categoryType={values?.category?.type}
                margin={margin}
              />
              {values?.category?.type === "accommodation" && (
                <AccommodationInfo
                  dailyNumber={values?.dailyNumber}
                  dailyRate={values?.dailyRate}
                  variant={variant}
                  isEditable={isEditable || hasPermissionToEdit}
                  onChangeValue={onChangeValue}
                  margin={margin}
                />
              )}
              {values?.category?.type === "fuel" && (
                <VehicleInfo
                  disabled={disabled}
                  isEditable={isEditable || hasPermissionToEdit}
                  variant={variant}
                  values={{
                    vehicle: values?.vehicle,
                    fuelType: values?.fuelType,
                    liters: values?.liters,
                    odometer: values?.odometer,
                    kml: values?.kml,
                    pricePerLiter: values?.pricePerLiter,
                  }}
                  onChangeValue={onChangeValue}
                  role={role}
                  margin={margin}
                />
              )}
            </>
          )}

          <DateInput
            isEditable={isEditable}
            readOnly={isMatched || disabled}
            variant={variant}
            value={values?.date ? new Date(values?.date) : null}
            onChange={handleChangeDate}
            disabled={isMatched}
          />

          {isRoute && (
            <RoutePoliciesInput
              isEditable={isEditable}
              readOnly={disabled}
              variant={variant}
              value={values?.routePolicy}
              onChange={handleChangeRoutePolicy}
              date={values?.date ? new Date(values?.date) : new Date()}
            />
          )}

          {!isRoute && (
            <PaymentInput
              isEditable={isEditable}
              readOnly={isMatched || disabled}
              variant={variant}
              disabled={isMatched}
              value={values.payment}
              onChange={handleChangePayment}
            />
          )}

          <ProjectInput
            isEditable={isEditable || hasPermissionToEdit}
            readOnly={disabled}
            variant={variant}
            value={values.project}
            onChange={handleChangeProject}
            margin={margin}
          />
          <ObsInput
            isEditable={isEditable || hasPermissionToEdit}
            readOnly={disabled}
            variant={variant}
            value={values.notes}
            onChange={handleChangeNotes}
            margin={margin}
          />
          {showAllFields && (
            <>
              {!isEditable && !hasPermissionToEdit && (
                <Divider sx={{ my: -1 }} />
              )}
              <Baseline
                Icon={Groups2Outlined}
                label={"Grupo"}
                value={group?.name}
                emptyLabel={"Sem grupo"}
              />
              <Baseline
                Icon={BusinessOutlined}
                label={"Filial"}
                value={branch?.name}
                emptyLabel={"Sem filial"}
              />
              <Baseline
                Icon={AssignmentOutlined}
                label={"Relatório"}
                value={reportTitle}
                emptyLabel={"Sem relatório"}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(FormContent);
