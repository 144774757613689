import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";

function ModalDelete({
  onDelete = async () => {},
  onClose,
  open,
  title = "Excluir",
  subtitle,
  buttonText,
}) {
  const [loading, setLoading] = useState(false);

  async function handleDelete(e) {
    e.stopPropagation();
    setLoading(true);
    await onDelete();
    setLoading(false);
    onClose();
  }

  return (
    <Dialog
      PaperProps={{
        onClick: (e) => e.stopPropagation(),
        sx: {
          maxWidth: 450,
        },
      }}
      onClose={(e) => {
        onClose();
        e.stopPropagation();
      }}
      open={open}
      fullWidth
    >
      {loading && <LinearProgress />}
      <DialogTitle fontWeight={'600'} fontSize={"1.2rem"}>{title}</DialogTitle>
      {subtitle && (
        <DialogContentText sx={{ wordBreak: "break-word" }} mx={3}>
          {subtitle}
        </DialogContentText>
      )}
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleDelete}
          disabled={loading}
        >
          {buttonText || (loading ? "Excluindo" : "Excluir")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDelete;
