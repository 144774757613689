import { useCallback, useState } from "react";
import { formatFilesToReceipts } from "../utils/receipts";

const useReceiptsUpload = (generateBlob = false) => {
  const [receipts, setReceipts] = useState([]);

  const onUploadFiles = useCallback(async (acceptedFiles) => {
    const receipts = await formatFilesToReceipts(acceptedFiles, generateBlob);
    if (receipts?.length) {
      setReceipts((prev) => [...receipts, ...prev]);
    }
  }, []);

  const onRemoveReceipt = useCallback((id) => {
    setReceipts((prev) => prev.filter((receipt) => receipt.id !== id));
  }, []);

  return {
    receipts,
    setReceipts,
    onUploadFiles,
    onRemoveReceipt,
  };
};

export default useReceiptsUpload;
