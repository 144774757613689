const isDev =
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_DEBUG === "true";
// const isStaging =  || process.env.REACT_APP_ENV === "staging";

export const BASE_URL = isDev
  ? "https://app.notepay.io"
  : // : isStaging
    // ? "https://app.notepay-dev.com"
    window.location.origin;

export const API_BASE_URL = `${BASE_URL}/api`;

export const AUTH_CLIENT_URL = `${BASE_URL}/auth/v1`;

export const AUTH_BASE_URL = isDev
  ? "https://auth.notepay.io"
  : // : isStaging
    // ? "https://auth.notepay-dev.com"
    window.location.origin.replace("app", "auth");

export const ADMIN_BASE_URL = isDev
  ? "https://admin.notepay.io"
  : // : isStaging
    // ? "https://admin.notepay-dev.com"
    window.location.origin.replace("app", "admin");

export const WSS_URL = isDev
  ? "wss://ws.notepay.io"
  : // : isStaging
    // ? "wss://ws.notepay-dev.com"
    `wss://${window.location.hostname.replace("app", "ws")}`;

export const RECEIPTS_URL = isDev
  ? "https://receipts.notepay.io"
  : // : isStaging
    // ? "https://receipts.notepay-dev.com"
    process.env.REACT_APP_RECEIPTS_URL || "https://receipts.notepay.com.br";
