import { InfoOutlined, SendOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SendExpensesWrapper from "../../../actions/expenses/SendExpensesWrapper";
import { APIActions } from "../../../api/actions";
import NoDataContent from "../../../components/form/NoDataContent";
import useExpenseRouteType from "../../../hooks/useExpenseRouteType";
import {
  ApproveButton,
  RejectButton,
} from "../../../pages/expenses/components/approver/SelectionApprovalButtons";
import { setError as setErrorSlice } from "../../../store/features/base/errorBaseSlice";
import {
  closeElement,
  selectModalInfo,
} from "../../../store/features/base/modalsSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import {
  expensesSelectors,
  updateExpense,
} from "../../../store/features/expensesSlice";
import { formatFilesToReceipts } from "../../../utils/receipts";
import MapContent from "../../route/components/MapContent";
import RouteSection from "../../route/components/route-section/RouteSection";
import RightTabBox from "../../route/new/components/RightTabBox";
import ReceiptsViewer from "../components/receipts/ReceiptsViewer";
import Timeline from "../components/timeline/Timeline";
import FormContent from "../new/components/FormContent";
import { formatExpenseFromApi, saveExpense } from "../utils";
import { AlertRules } from "./components/header/components/RulesIndicator";
import DialogHeader from "./components/header/DialogHeader";
import RejectedInfo from "./components/RejectedInfo";
import RouteAmountResume from "./components/RouteAmountResume";
import StepApproversInfo from "./components/StepApproversInfo";
import TabsNav from "./components/TabsNav";

const ModalExpenseView = (props) => {
  const dispatch = useDispatch();

  const { open, payload } = useSelector((state) =>
    selectModalInfo(state, "modalExpenseView")
  );

  const onClose = useCallback(() => {
    dispatch(closeElement("modalExpenseView"));
  }, []);

  const expenseId = useMemo(() => payload?.expenseId, [payload]);
  const role = useMemo(() => payload?.role, [payload]);
  const preloadedData = useSelector((state) =>
    expensesSelectors[role]?.selectById(state, expenseId)
  );

  const [sending, setSending] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({});
  const [receipts, setReceipts] = useState([]);
  const [tab, setTab] = useState(0);
  const [rightTab, setRightTab] = useState(1);
  const [changed, setChanged] = useState(false);

  //utils
  const isRoute = useMemo(() => values?.type === "route", [values?.type]);
  const isMineInApproval = useMemo(
    () => role === "personal" && values?.status === "pending",
    [values?.status, role]
  );
  const canCancelSend = useMemo(
    () => values?.status === "pending" && role === "personal",
    [values?.status, role]
  );
  const isApprover = useMemo(
    () => values?.status === "pending" && role === "approver",
    [values?.status, role]
  );
  const isEditable = useMemo(
    () =>
      ["opened", "rejected"].includes(values?.status) && role === "personal",
    [values?.status, role]
  );
  const hasPermissionToEdit = useMemo(
    () =>
      values?.status === "finished"
        ? false
        : ["approver", "financial"].includes(role)
        ? true
        : true,
    [role, values?.status]
  );
  const mainSeverity = useMemo(() => {
    const rules = values?.alerts?.rules || [];
    if (rules?.some((rule) => rule?.severity === "error")) return "error";
    if (rules?.some((rule) => rule?.severity === "warning")) return "warning";
    return "info";
  }, [values?.alerts?.rules]);

  const originalValuesRef = useRef({});
  const isFirstRender = useRef(true);
  const valuesRef = useRef(values || {});
  const receiptsRef = useRef(receipts || {});
  const routesRef = useRef([]);

  // Ref to store the current AbortController
  const abortControllerRef = useRef(null);
  const saveAbortControllerRef = useRef(null);

  const handleChangeValue = useCallback((prop, value) => {
    setValues((prev) => {
      const newValue = { ...prev, [prop]: value };
      return newValue;
    });
    if (!isFirstRender.current) {
      setChanged(true);
    }
  }, []);

  //route functions
  const {
    calculating,
    onAddRoute,
    routes,
    setRoutes,
    setRoundTrip,
    directionsResponse,
    onCalcRoute,
    onRemoveRoute,
    onReoderRoutes,
    onRevertRoutes,
    onToggleRoundTrip,
    onUpdateRoute,
    roundTrip,
    getDirectionsResponse,
    resetState: resetRouteState,
  } = useExpenseRouteType({
    onChangeValue: handleChangeValue,
    distance: values?.distance || 0,
    isLoading: loading,
  });

  const resetState = () => {
    setChanged(false);
    setLoading(true);
    setValues({});
    setReceipts([]);
    resetRouteState();
    setError(null);
    // Cancel any pending request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setLoading(true);
    }
    if (saveAbortControllerRef.current) {
      saveAbortControllerRef.current.abort();
      saveAbortControllerRef.current = null;
    }
  };

  const getExpensesDetails = useCallback(async () => {
    setLoading(true);
    setError(null);

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setValues((prev) => ({
      ...prev,
      ...(preloadedData || {}),
    }));
    try {
      const data = await APIActions.expenses.detail({
        expenseId,
        signal: abortController.signal,
        role,
      });
      setValues((prev) => ({
        ...prev,
        ...formatExpenseFromApi(data),
      }));
      setReceipts(data?.receipts || []);
      if (data?.type === "route") {
        const initialRoutes = [
          data?.route?.from || "",
          ...(data?.route?.waypoints || []),
          data?.route?.to || "",
        ];
        setRoutes(initialRoutes);
        setRoundTrip(Boolean(data?.route?.roundTrip));
        setRightTab(0);
        getDirectionsResponse(initialRoutes, true);
      }
      setChanged(false);
      originalValuesRef.current = data;
      setTimeout(() => {
        isFirstRender.current = false;
      }, 300);
    } catch (error) {
      if (error?.name !== "CanceledError") {
        setError(
          error?.response?.data?.message ||
            "Não foi possível carregar os dados da despesa"
        );
      }
    } finally {
      setLoading(false);
    }
  }, [role, expenseId, preloadedData]);

  useEffect(() => {
    if (expenseId) {
      isFirstRender.current = true;

      // Cancel any pending request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        setLoading(true);
      }
      getExpensesDetails();
    }
  }, [expenseId]);

  useEffect(() => {
    valuesRef.current = { ...values };
  }, [values]);

  useEffect(() => {
    receiptsRef.current = [...receipts];
  }, [receipts]);

  useEffect(() => {
    routesRef.current = [...routes];
  }, [routes]);

  //receipts functions
  const handleUploadFiles = useCallback(async (acceptedFiles) => {
    const receipts = await formatFilesToReceipts(acceptedFiles);
    if (receipts?.length) {
      setReceipts((prev) => [...receipts, ...prev]);
    }
    if (!isFirstRender.current) {
      setChanged(true);
    }
  }, []);

  const handleRemoveReceipt = useCallback((id) => {
    setReceipts((prev) => prev.filter((receipt) => receipt.id !== id));
    if (!isFirstRender.current) {
      setChanged(true);
    }
  }, []);

  const handleSave = useCallback(async () => {
    setSaving(true);
    const values = valuesRef.current || {};
    const receipts = receiptsRef.current || [];
    const routes = routesRef.current || [];
    const isRoute = values?.type === "route";
    const abortController = new AbortController();
    saveAbortControllerRef.current = abortController;
    const { ok, data, error } = await saveExpense({
      expenseId,
      values,
      receipts,
      role,
      routes,
      isRoute,
      signal: abortController.signal,
      sendToApproval: false,
    });
    if (ok) {
      dispatch(
        openSnackbar({ message: isRoute ? "Percurso salvo" : "Despesa salva" })
      );
      dispatch(
        updateExpense({
          role,
          id: expenseId,
          changes: data,
        })
      );
      onClose();
    } else {
      dispatch(setErrorSlice({ title: "Erro ao salvar despesa", error }));
    }
    setSaving(false);
  }, [expenseId, role]);

  const handleSend = useCallback(
    async (event) => {
      setSending(true);
      const values = valuesRef.current || {};
      const receipts = receiptsRef.current || [];
      const routes = routesRef.current || [];
      const isRoute = values?.type === "route";
      const abortController = new AbortController();
      saveAbortControllerRef.current = abortController;
      const { ok, data, error } = await saveExpense({
        sendToApproval: true,
        signal: abortController.signal,
        expenseId,
        role,
        values,
        isRoute,
        routes,
        receipts,
      });
      if (ok) {
        dispatch(
          openSnackbar({
            message: isRoute ? "Percurso enviado" : "Despesa enviada",
          })
        );
        dispatch(
          updateExpense({
            role,
            id: expenseId,
            changes: data,
          })
        );
        onClose();
      } else {
        dispatch(setErrorSlice({ title: error }));
      }
      setSending(false);
    },
    [expenseId, role]
  );

  return (
    <Drawer
      transitionDuration={50}
      anchor="right"
      open={open}
      variant={"persistent"}
      SlideProps={{
        onExited: resetState,
        unmountOnExit: true,
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "54em",
          boxShadow: 5,
        },
      }}
    >
      {saving && <LinearProgress />}
      <DialogHeader
        canCancelSend={canCancelSend}
        isEditable={isEditable}
        role={role}
        disabled={loading || saving || sending}
        loading={loading}
        expenseId={expenseId}
        onClose={onClose}
        originalValuesRef={originalValuesRef}
        onSendToApproval={handleSend}
        status={values?.status}
        category={values?.category}
        sentAt={values?.sentAt}
        approvedAt={values?.approvedAt}
        rejectedAt={values?.rejectedAt}
        currentStep={values?.approvalFlow?.currentStep}
        amount={values?.displayAmount?.amount}
        currency={values?.displayAmount?.currency}
        transactionStatus={values?.transactionStatus}
        transaction={values?.transaction}
        hasPermissionToEdit={hasPermissionToEdit}
        alerts={values?.alerts || {}}
        mainSeverity={mainSeverity}
        isRoute={isRoute}
        to={values?.to}
      />
      <TabsNav
        tab={tab}
        onChange={setTab}
        role={role}
        expenseId={expenseId}
        originalValuesRef={originalValuesRef}
      />
      <Divider />
      {Boolean(error) ? (
        <NoDataContent
          Icon={InfoOutlined}
          titleSize="1.3rem"
          title={error}
          subtitle={"Verifique sua conexão e tente novamente"}
        >
          <Button onClick={getExpensesDetails}>Tentar novamente</Button>
        </NoDataContent>
      ) : (
        <>
          <DialogContent
            sx={{
              p: 0,
              display: "flex",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            {tab === 0 && (
              <>
                <Box
                  boxShadow={2}
                  zIndex={10}
                  width={"25em"}
                  height={"100%"}
                  overflow={"scroll"}
                  p={2}
                  pb={isRoute ? 14 : 10}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  {isMineInApproval && !loading && (
                    <>
                      <StepApproversInfo
                        loading={loading}
                        currentStep={values?.approvalFlow?.currentStep}
                        stepApprovers={
                          values?.approvalFlow?.stepApprovers || []
                        }
                      />
                      <Divider sx={{ my: 2, mx: -2 }} />
                    </>
                  )}
                  {values?.status === "rejected" && !loading && (
                    <>
                      <RejectedInfo events={values?.events || []} />
                      <Divider sx={{ my: 2, mb: 1, mx: -2 }} />
                    </>
                  )}

                  {(mainSeverity === "error" || mainSeverity === "warning") && (
                    <AlertRules
                      severity={mainSeverity}
                      rules={values?.alerts?.rules || []}
                    />
                  )}
                  {isRoute && (
                    <Box ml={-1} mb={1}>
                      <RouteSection
                        key={expenseId}
                        roundTrip={roundTrip}
                        routes={routes}
                        onAddRoute={onAddRoute}
                        onCalcFunction={onCalcRoute}
                        onDragEnd={onReoderRoutes}
                        onRevertRoutes={onRevertRoutes}
                        onRemoveRoute={onRemoveRoute}
                        onToggleRoundTrip={onToggleRoundTrip}
                        onUpdateRoute={onUpdateRoute}
                        disableMyLocation
                        isEditable={isEditable}
                      />
                    </Box>
                  )}
                  <FormContent
                    key={expenseId}
                    isMatched={values?.transactionStatus === "isMatched"}
                    loading={loading}
                    disabled={false}
                    isEditable={isEditable}
                    values={values}
                    user={values?.user}
                    role={role}
                    onChangeValue={handleChangeValue}
                    branch={values?.org}
                    group={values?.group}
                    reportTitle={values?.reportTitle}
                    hasPermissionToEdit={false}
                    isRoute={isRoute}
                  />
                  {isRoute && (
                    <RouteAmountResume
                      distance={values?.distance || 0}
                      rate={values?.routePolicy?.currentRate || 0}
                      loading={loading}
                    />
                  )}
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  height={"100%"}
                  flex={1}
                >
                  {isRoute && (
                    <RightTabBox
                      value={rightTab}
                      onChange={setRightTab}
                      receiptsNum={receipts?.length || 0}
                    />
                  )}
                  {rightTab === 0 && isRoute && (
                    <MapContent
                      directionsResponse={directionsResponse}
                      loadingMap={calculating}
                    />
                  )}
                  {(isRoute ? rightTab === 1 : true) && (
                    <ReceiptsViewer
                      loading={loading}
                      receipts={receipts}
                      onUploadFiles={handleUploadFiles}
                      onRemove={handleRemoveReceipt}
                      isEditable={isEditable}
                    />
                  )}
                </Box>
              </>
            )}
            {tab === 1 && (
              <Box width={"100%"} p={3}>
                <Timeline events={values?.events} loading={loading} />
              </Box>
            )}
          </DialogContent>
          {!isMineInApproval && values?.status !== "finished" && (
            <DialogActions
              sx={{
                boxShadow: 4,
                height: 55,
                "& .MuiButtonBase-root": { height: 35 },
              }}
            >
              {loading ? (
                <>
                  <Skeleton height={55} width={100} />
                  <Skeleton height={55} width={100} />
                  <Box flex={1} />
                </>
              ) : (
                <>
                  {isApprover ? (
                    <>
                      <ApproveButton
                        expensesIds={[expenseId]}
                        onApprove={onClose}
                      />
                      <RejectButton
                        expensesIds={[expenseId]}
                        onApprove={onClose}
                      />
                      <Box flex={1} />
                      <Button onClick={onClose}>Cancelar</Button>
                    </>
                  ) : (
                    <>
                      {hasPermissionToEdit && (
                        <>
                          <Button
                            onClick={(e) => handleSave(e, true)}
                            disabled={!changed || loading || saving || sending}
                            sx={{ px: 4 }}
                            variant="contained"
                            disableElevation
                          >
                            {saving ? "Salvando" : "Salvar"}
                          </Button>
                          <Button onClick={onClose}>Cancelar</Button>
                          <Box flex={1} />
                          {isEditable && (
                            <SendExpensesWrapper
                              onSendToApproval={handleSend}
                              expensesIds={[expenseId]}
                              renderComponent={({ openModal }) => (
                                <Button
                                  disabled={loading || saving || sending}
                                  onClick={openModal}
                                  endIcon={<SendOutlined />}
                                  variant="outlined"
                                >
                                  {sending ? "Enviando" : "Enviar"}
                                </Button>
                              )}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </DialogActions>
          )}
        </>
      )}
    </Drawer>
  );
};

export default memo(ModalExpenseView);
