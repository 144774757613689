import React from "react";
import PermissionsList from "../../../components/USERS/PermissionsList";
import PaperSection from "../../../components/form/PaperSection";

const PermissionsSection = ({ permissions, onChangePermissions }) => {
  return (
    <PaperSection
      disableElevation
      title="Permissões de acesso"
      subtitle={
        "Controle os acessos e permissões deste usuário na empresa."
      }
    >
      <PermissionsList
        permissions={permissions}
        onChangePermissions={onChangePermissions}
      />
    </PaperSection>
  );
};

export default PermissionsSection;
