import { PostAddOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { memo } from "react";
import Baseline from "../../../../components/form/Baseline";
import SelectorBox from "../SelectorBox";

function ProjectInput({
  value,
  onChange,
  readOnly,
  loading,
  size,
  variant = "filled",
  isEditable = true,
  margin = "dense",
}) {
  if (!isEditable) {
    return (
      <Baseline
        Icon={PostAddOutlined}
        label={"Projeto"}
        value={value?.name}
        emptyLabel={"Sem projeto"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={PostAddOutlined}>
        <Autocomplete
          fullWidth
          value={value || null}
          readOnly={readOnly}
          autoHighlight
          options={[]}
          size={size || "small"}
          onChange={(e, v) => {
            onChange(v || "");
          }}
          getOptionLabel={(option) => ""}
          renderOption={(props, option, state) => {
            return (
              <MenuItem {...props} selected={state.selected}>
                {""}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin={margin}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              label={"Projeto"}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(ProjectInput);
