import { setHours } from "date-fns";
import { APIActions } from "../../api/actions";
import { clearCache } from "../../api/cache";

export const formatExpenseToApi = ({
  expenseData = {},
  routes,
  receipts,
  isRoute,
}) => {
  const {
    notes,
    category,
    date,
    amount,
    project,
    payment,
    currency,
    place,
    dailyNumber,
    dailyRate,
    odometer,
    liters,
    vehicle,
    fuelType,
    sendToApproval,
    routePolicy,
  } = expenseData;
  let formattedData = {
    type: isRoute ? "route" : "regular",
    notes,
    currency,
    category: category?._id || null,
    project: project?._id || null,
    date: setHours(new Date(date), 3),
    amount: parseFloat(amount) || 0,
    payment: payment?._id
      ? {
          id: payment?._id,
          isAdvance: payment?.type === "advance" || payment?.isAdvance,
        }
      : null,
    placeId: place?.id || null,
    categoryProperties: Boolean(category)
      ? category?.type === "accommodation"
        ? {
            dailyNumber,
            dailyRate,
          }
        : category?.type === "fuel"
        ? {
            odometer: parseFloat(odometer),
            liters: parseFloat(liters),
            vehicle: vehicle?._id || undefined,
            fuelType,
          }
        : {}
      : null,
    receipts,
    sendToApproval: Boolean(sendToApproval),
  };
  if (!Array.isArray(receipts)) {
    delete formattedData.receipts;
  }
  if (isRoute && Array.isArray(routes) && routes?.length) {
    formattedData.from = routes[0] || null;
    formattedData.to = routes[routes.length - 1] || null;
    formattedData.waypoints = routes.slice(1, -1) || null;
  }
  if (isRoute && routePolicy?._id) {
    formattedData.routePolicyId = routePolicy?._id || null;
  }
  return formattedData;
};

export const formatExpenseFromApi = (expenseData = {}) => {
  return {
    ...expenseData,
    currency: expenseData?.originalAmount?.currency,
    amount: expenseData?.originalAmount?.amount || "",
    ...expenseData?.categoryProperties,
    ...(expenseData?.route || {}),
    routePolicy: {
      _id: expenseData?.route?.policyId,
      name: expenseData?.route?.policyName,
      currentRate: expenseData?.route?.rate,
    },
  };
};

export const saveExpense = async ({
  sendToApproval = false,
  values = {},
  receipts = [],
  routes,
  expenseId,
  signal,
  role,
  isRoute,
}) => {
  try {
    const responseData = await APIActions.expenses.update({
      expenseId,
      role,
      signal,
      changes: formatExpenseToApi({
        expenseData: {
          ...values,
          sendToApproval,
        },
        receipts,
        routes,
        isRoute,
      }),
    });
    clearCache("/advances");
    return {
      ok: true,
      data: responseData,
      error: null,
    };
  } catch (error) {
    return {
      ok: false,
      data: null,
      error,
    };
  }
};
