import { Box, Tab, Tabs } from "@mui/material";
import React, { memo } from "react";

const RightTabBox = ({ value, onChange, receiptsNum = 0 }) => {
  return (
    <Box borderBottom={1} borderColor={"divider"}>
      <Tabs
        value={value}
        onChange={(e, v) => onChange(v)}
        sx={{
          minHeight: 35,
          "& .MuiTabs-root": {
            minHeight: 35,
            position: "relative",
          },
          "& .MuiTab-root": {
            minHeight: 35,
          },
        }}
      >
        <Tab value={0} label="Mapa" />
        <Tab
          value={1}
          label={`Comprovantes${receiptsNum ? ` (${receiptsNum})` : ""}`}
        />
      </Tabs>
    </Box>
  );
};

export default memo(RightTabBox);
