import { Avatar, Skeleton, useTheme } from "@mui/material";
import React from "react";

function AvatarType({
  size = 35,
  fz = "1.3rem",
  icon = "receipt_long",
  sx,
  loading,
  color = "primary",
  showBgcolor,
}) {
  const theme = useTheme().palette[color || "primary"].main;

  return loading ? (
    <Skeleton variant="circular" sx={{ width: size, height: size, ...sx }} />
  ) : (
    <Avatar
      sx={{
        bgcolor: showBgcolor ? `${theme}10` : `transparent`,
        width: size,
        height: size,
        ...sx,
      }}
    >
      <span style={{ color: theme, fontSize: fz }} className="material-icons">
        {icon || "receipt_long"}
      </span>
    </Avatar>
  );
}

export default AvatarType;
