import { LocationOnOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  MenuItem,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { HttpClient } from "../../../../api/httpClient";
import Baseline from "../../../../components/form/Baseline";
import SelectorBox from "../SelectorBox";

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const LocalInput = ({
  value,
  variant,
  size,
  inputRef,
  isEditable,
  onChange = () => {},
  readOnly,
  categoryType = null,
  margin = "dense",
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetch = React.useMemo(
    () =>
      debounce(async (text, callback) => {
        setLoading(true);
        HttpClient.get({
          url: `/expenses/places/search`,
          params: {
            text,
            placeType: categoryType,
          },
          shouldCache: true,
        })
          .then(({ data: responseData }) => {
            callback(Array.isArray(responseData) ? responseData : []);
          })
          .finally(() => setLoading(false));
      }, 400),
    [categoryType]
  );

  const renderPopper = useCallback((props) => {
    return <Popper {...props} style={styles.popper} />;
  }, []);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setData(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (responseData) => {
      if (active) {
        if (value && !responseData?.find((place) => place?.id === value?.id)) {
          setData([value, ...responseData]);
        } else {
          setData(responseData);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  if (!isEditable) {
    return (
      <Baseline
        Icon={LocationOnOutlined}
        label={"Local"}
        value={value?.name}
        emptyLabel={"Não informado"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={LocationOnOutlined}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          autoComplete
          loading={loading}
          openOnFocus={false}
          readOnly={readOnly}
          options={data || []}
          value={value || null}
          filterOptions={(options, state) => options || []}
          PopperComponent={renderPopper}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem
                sx={{ height: 70, gap: 2, alignItems: "center" }}
                {...props}
                selected={selected}
              >
                <LocationOnOutlined color="primary" />
                <Box>
                  <Stack gap={1} direction={"row"} alignItems={"center"}>
                    <Typography variant="inherit" fontWeight={"600"}>
                      {option?.name}
                    </Typography>
                  </Stack>
                  <Typography noWrap variant="body2" color={"text.secondary"}>
                    {option?.address}
                  </Typography>
                </Box>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="type_id"
              margin={margin}
              inputRef={inputRef}
              fullWidth
              variant={variant}
              placeholder="Hotéis, restaurantes e mais"
              label={"Local"}
            />
          )}
        />
      </SelectorBox>
    );
  }
};

export default memo(LocalInput);
