import { Fab, Typography } from "@mui/material";
import React, { memo } from "react";

// bgcolor: (t) =>
//   t.palette.mode === "dark"
//     ? `#4B5A6F`
//     : isStaging
//     ? "#9E9E9E"
//     : `#B0CEEA`,
// boxShadow: {
//   xs: 4,
//   md: 0,
// },
// ":hover": {
//   boxShadow: 5,
//   bgcolor: (t) =>
//     t.palette.mode === "dark"
//       ? lighten(`#4B5A6F`, 0.1)
//       : lighten(isStaging ? "#9E9E9E" : `#B0CEEA`, 0.1),
// },

const CustomFab = ({ Icon, label, onClick }) => {
  return (
    <Fab
      //   tour_id="creation_button"
      color="default"
      onClick={onClick}
      variant="extended"
      size="medium"
      sx={{
        transition: "none",
        borderRadius: 100,
        height: 50,
        pr: 3,
        bgcolor: (t) => `#FFF`,
        boxShadow: 3,
        ":hover": {
          boxShadow: 5,
          bgcolor: (t) => `${t.palette.primary.main}10`,
        },
      }}
    >
      {Icon && <Icon sx={{ fontSize: "1.4rem", color: "primary.main" }} />}
      <Typography
        color={"primary.main"}
        fontSize={"1rem"}
        ml={2}
        variant="inherit"
        fontWeight={"600"}
      >
        {label}
      </Typography>
    </Fab>
  );
};

export default memo(CustomFab);
