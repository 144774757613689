import {
  AttachMoney,
  Circle,
  PaymentOutlined,
  WalletOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Collapse,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import {
  getPayments,
  selectPaymentsByRole,
} from "../../../../store/features/configs/paymentsSlice";
import { CREDIT_CARDS_MODEL } from "../../../../utils/creditCards";
import formatAmount from "../../../../utils/formatAmount";
import { formatPaymentTypeText } from "../../../../utils/formaters";
import { curr } from "../../../../utils/more/currency_country";
import SelectorBox from "../SelectorBox";

export const getPaymentIcon = ({ type = "", cardType = "" }) => {
  return type === "credit-card" || type === "debit-card" ? (
    Boolean(cardType) ? (
      <img width={"30px"} src={CREDIT_CARDS_MODEL[cardType]?.image} />
    ) : (
      <PaymentOutlined color="action" />
    )
  ) : type === "advance" ? (
    <WalletOutlined color="action" />
  ) : type === "cash" ? (
    <AttachMoney color="action" />
  ) : (
    <PaymentOutlined color="action" />
  );
};

const PaymentItem = memo(({ option, selected, isLoading, ...props }) => {
  return (
    <MenuItem {...props} selected={selected} sx={{ gap: 1, height: 60 }}>
      <Box width={30} ml={-1}>
        {getPaymentIcon({ cardType: option?.card?.type, type: option?.type })}
      </Box>
      <div>
        <Typography variant="inherit" fontWeight={"600"}>
          {option?.name}
        </Typography>
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          {Boolean(option?.card) && (
            <>
              <Typography
                variant="body2"
                color={"text.secondary"}
                fontSize={".8rem"}
              >
                {option?.card?.number}
              </Typography>
              <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
            </>
          )}
          <Typography
            variant="body2"
            color={"text.secondary"}
            fontSize={".8rem"}
            fontWeight={"500"}
          >
            {option?.type === "advance" ? (
              isLoading ? (
                <Skeleton height={15} width={120} />
              ) : (
                `${curr(option?.currency || "BRL")} ${formatAmount(
                  option?.balance || 0
                )} disponível`
              )
            ) : option?.isRefundable ? (
              "Reembolsável"
            ) : (
              "Não reembolsável"
            )}
          </Typography>
        </Box>
      </div>
    </MenuItem>
  );
});

function PaymentInput({
  value,
  onChange = () => {},
  readOnly,
  variant = "filled",
  size,
  isEditable = true,
  role = "personal",
  disabled,
  endAdornment,
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectPaymentsByRole(state, role));

  const loading = status === "loading";

  const onOpen = () => {
    dispatch(getPayments(role));
  };

  const formattedData = useMemo(
    () =>
      data?.map((payment) => ({
        ...payment,
        typeName: formatPaymentTypeText(payment?.type),
      })),
    [data]
  );

  if (!isEditable) {
    return (
      <>
        <Baseline
          Icon={(props) => (
            <Box {...props} width={22.5}>
              {getPaymentIcon({
                cardType: value?.card?.type,
                type: value?.type,
              })}
            </Box>
          )}
          label={"Forma de pagamento"}
          value={
            value?.name ? (
              <Box>
                <Typography variant="inherit">{value?.name}</Typography>
                <Typography
                  fontSize={".8rem"}
                  fontWeight={"500"}
                  color={"text.secondary"}
                >
                  {value?.isRefundable ? "Reembolsável" : "Não reembolsável"}
                </Typography>
              </Box>
            ) : (
              ""
            )
          }
          emptyLabel={"Sem forma de pagamento"}
        />
      </>
    );
  } else {
    return (
      <SelectorBox
        Icon={(props) =>
          value ? (
            <Box {...props} width={22.5}>
              {getPaymentIcon({
                cardType: value?.card?.type,
                type: value?.type,
              })}
            </Box>
          ) : (
            <PaymentOutlined {...props} />
          )
        }
        iconSx={{ mt: 2 }}
        sx={{ alignItems: "flex-start" }}
      >
        <Autocomplete
          onOpen={onOpen}
          size={size || "small"}
          fullWidth
          disabled={disabled}
          autoHighlight
          readOnly={readOnly}
          options={formattedData || []}
          loading={loading}
          value={value || null}
          onChange={(e, v) => {
            onChange(v || "");
          }}
          getOptionLabel={(option) => option?.name}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          renderOption={(props, option, state) => {
            return (
              <PaymentItem
                {...props}
                option={option}
                selected={state.selected}
                isLoading={loading}
              />
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={15} />
                      ) : null}
                      {!disabled && !readOnly && params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                },
              }}
              fullWidth
              variant={variant}
              label={"Forma de pagamento"}
              sx={{ mb: 0 }}
              helperText={
                <Collapse timeout={100} in={Boolean(value)}>
                  <Typography
                    color={"primary.main"}
                    fontSize={".85rem"}
                    fontWeight={"500"}
                    component={"span"}
                  >
                    {value?.isRefundable ? "Reembolsável" : "Não reembolsável"}
                  </Typography>
                </Collapse>
              }
            />
          )}
        />
        {endAdornment && (
          <Box height={"78%"} display={"flex"} alignItems={"center"} pl={1}>
            {endAdornment}
          </Box>
        )}
      </SelectorBox>
    );
  }
}

export default memo(PaymentInput);
