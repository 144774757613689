import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";
import { DEFAULT_ENTITY_STATE } from "../../utils";

const baseName = "payments";

const initialState = {
  personal: DEFAULT_ENTITY_STATE,
  approver: DEFAULT_ENTITY_STATE,
  financial: DEFAULT_ENTITY_STATE,
  admin: DEFAULT_ENTITY_STATE,
};

export const getPayments = createAsyncThunk(
  `${baseName}/getPayments`,
  async (role) => {
    const res = await HttpClient.get({
      url: "/paymentMethods",
      params: {
        role,
        expand: role === "admin" ? ["user", "groups"] : undefined,
      },
    });
    return res?.data;
  }
);

export const paymentsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupPayments(state, { payload }) {
      const { data, role } = payload || {};
      if (!state[role]) return;
      //CONFIGURAR ENTRADA DE DADOS
      state[role].data =
        data?.map((item) => ({ ...item, id: item?._id })) || [];
    },
    resetPaymentsStatus(state, { payload }) {
      state.admin.status = "idle";
      state.approver.status = "idle";
      state.financial.status = "idle";
      state.personal.status = "idle";
    },

    //ADM ACTIONS
    addPayment(state, { payload }) {
      const { data, role } = payload;
      if (!role) return;
      state[role].data.push(data);
    },
    updatePayment(state, { payload }) {
      const { id, changes } = payload;
      const role = payload?.role || "admin";
      const index = state.admin.data.findIndex((item) => item.id === id);
      if (index !== -1) {
        state[role].data[index] = {
          ...state[role].data[index],
          ...changes,
        };
      }
    },
    removePayment(state, { payload }) {
      const { role, id } = payload;
      if (!role) return;
      state[role].data = [
        ...state[role].data?.filter((item) => item?.id !== id),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state, action) => {
        const role = action.meta.arg || "personal";
        state[role].status = "loading";
        state[role].error = null;
      })
      .addCase(getPayments.rejected, (state, action) => {
        const role = action.meta.arg || "personal";
        state[role].status = "failed";
        state[role].error = action.error.message || null;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        const role = action.meta.arg || "personal";
        state[role].status = "succeeded";
        state[role].error = null;

        //Dados API
        const data = action.payload || [];
        paymentsSlice.caseReducers.setupPayments(state, {
          payload: {
            data,
            role,
          },
        });
      });
  },
});

export const {
  addPayment,
  removePayment,
  setupPayments,
  updatePayment,
  resetPaymentsStatus,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;

export const selectPaymentsByRole = createSelector(
  [(state) => state.payments, (state, role) => role],
  (paymentsState = {}, role = "") => {
    const prevData = paymentsState[role] || DEFAULT_ENTITY_STATE;

    return {
      ...prevData,
      total: prevData?.data?.length || 0,
    };
  }
);

export const selectPaymentById = createSelector(
  [selectPaymentsByRole, (state, role, categoryId) => categoryId],
  (paymentsState = {}, categoryId = "") => {
    return paymentsState?.data?.find((item) => item?.id === categoryId) || {};
  }
);
