import React from "react";
import { Outlet } from "react-router-dom";
import ModalRegisterUser from "../../modals/users/ModalRegisterUser";
import ModalInviteUsers from "../../modals/users/invite/ModalInviteUsers";

const AdmWrapper = () => {
  document.title = "Administrador - Notepay";
  return (
    <>
      <ModalRegisterUser />
      <ModalInviteUsers />
      <Outlet />
    </>
  );
};

export default AdmWrapper;
