import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  duration: 4000,
  hiddenClose: false,
  action: null,
  error: false,
  actionText: "",
  loadingSnackbar: false,
};

export const snackbarBaseSlice = createSlice({
  name: "snackbarBase",
  initialState,
  reducers: {
    openSnackbar: (state, { payload }) => {
      state.open = true;
      if (payload) {
        if (payload.message) state.message = payload.message;
        if (payload.duration) state.duration = payload.duration;
        if (payload.action) {
          state.action = payload.action;
        } else {
          state.action = initialState.action;
        }
        if (payload.actionText) {
          state.actionText = payload.actionText;
        } else {
          state.actionText = initialState.actionText;
        }
        state.error = Boolean(payload.error);
        state.hiddenClose = Boolean(payload.hiddenClose);
      }
    },
    openLoadingSnackbar: (state, { payload }) => {
      state.loadingSnackbar = true;
      if (payload) {
        if (payload.message) state.message = payload.message;
        if (payload.actionText) state.actionText = payload.actionText;
      }
    },
    closeLoadingSnackbar(state) {
      state.loadingSnackbar = initialState.loadingSnackbar;
      state.message = initialState.message;
      state.actionText = initialState.actionText;
    },
    closeSnackbar: (state) => {
      state.open = false;
      setTimeout(() => {
        state.message = initialState.message;
        state.action = initialState.action;
        state.error = initialState.error;
        state.actionText = initialState.actionText;
        state.duration = initialState.duration;
        state.hiddenClose = initialState.hiddenClose;
      }, 500);
    },
  },
});

export const {
  openSnackbar,
  closeSnackbar,
  closeLoadingSnackbar,
  openLoadingSnackbar,
} = snackbarBaseSlice.actions;

export const selectSnackbarState = createSelector(
  [(state) => state.snackbarBase],
  (state) => state
);

export default snackbarBaseSlice.reducer;
