export default function (r = [], getId = "id") {
  const obj = {
    ids: [],
    entities: {},
  };
  if (!r.length) {
    return {
      ids: [],
      entities: {},
    };
  }
  for (let index = 0; index < r.length; index++) {
    const d = r[index];
    obj.ids.push(d[getId] || d._id);
    obj.entities[d[getId] || d._id] = {
      id: d[getId] || d._id,
      ...d,
    };
    delete obj.entities._id;
  }
  return obj;
}
