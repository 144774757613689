import {
  DocumentScanner,
  LocationOn,
  ReceiptLong
} from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../store/features/base/modalsSlice";

const CustomMenuItem = ({ label, Icon, onClick }) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{ fontWeight: "600", fontSize: "1rem", height: 43, pr: 3 }}
    >
      {Icon && <Icon sx={{ mr: 3 }} fontSize="small" color="primary" />}
      {label}
    </MenuItem>
  );
};

function MenuCreation({
  anchorEl,
  onClose,
  hiddenScanner,
  transformOrigin = { horizontal: "left", vertical: "top" },
  anchorOrigin = { vertical: "top", horizontal: "center" },
}) {
  const dispatch = useDispatch();

  const handleScan = useCallback(() => {
    dispatch(openElement({ name: "modalExpensesScanner" }));
    onClose();
  }, []);

  const handleCreateExpense = useCallback(() => {
    dispatch(openElement({ name: "modalNewExpense" }));
    onClose();
  }, []);

  const handleCreateRoute = useCallback(() => {
    dispatch(openElement({ name: "modalNewRoute" }));
    onClose();
  }, []);

  return (
    <Menu
      disableScrollLock
      transitionDuration={0}
      elevation={0}
      onClose={onClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      slotProps={{
        paper: {
          elevation: 3,
          sx: { borderRadius: 1.5, pb: 1, boxShadow: 10, minWidth: 240 },
        },
      }}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      <CustomMenuItem
        Icon={ReceiptLong}
        label="Criar despesa"
        onClick={handleCreateExpense}
      />
      {!hiddenScanner && (
        <CustomMenuItem
          Icon={DocumentScanner}
          label="Ler notinhas"
          onClick={handleScan}
        />
      )}
      <CustomMenuItem
        Icon={LocationOn}
        label="Criar percurso"
        onClick={handleCreateRoute}
      />
    </Menu>
  );
}

const ExpensesCreationWrapper = ({
  renderComponent = ({ opened, openMenu, closeMenu }) => <></>,
  menuProps,
}) => {
  const [menu, setMenu] = useState(null);

  const openMenu = (e) => {
    if (!e) return;
    setMenu(e.target);
  };
  const closeMenu = () => {
    setMenu(null);
  };

  return (
    <>
      {renderComponent({
        opened: Boolean(menu),
        openMenu,
        closeMenu,
      })}
      <MenuCreation anchorEl={menu} onClose={closeMenu} {...menuProps} />
    </>
  );
};

export default memo(ExpensesCreationWrapper);
