import { Inventory2Outlined } from "@mui/icons-material";
import { Box, Button, Skeleton } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openElement } from "../../../../../../store/features/base/modalsSlice";
import { selectRecentReports } from "../../../../../../store/features/reportsSlice";
import { includesOnSearch } from "../../../../../../utils/more/search";
import NoDataContent from "../../../../../form/NoDataContent";
import ListHeader from "./ListHeader";
import RecentListItem from "./RecentListItem";

const generateRandomWidth = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const RecentsList = ({ role = "" }) => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.reports[role]?.status);
  const isLoading = status === "loading";

  const data = useSelector((state) => selectRecentReports(state, role));

  const [search, setSearch] = useState("");

  const filteredData = useMemo(
    () => data?.filter((report) => includesOnSearch(search, [report?.title])),
    [search, data]
  );

  const renderItems = useCallback((report) => {
    return <RecentListItem key={report?._id} report={report} />;
  }, []);

  return (
    <>
      <ListHeader
        count={data?.length || 0}
        search={search}
        setSearch={setSearch}
      />
      <Box>
        {isLoading ? (
          [...new Array(10)].map((_, idx) => (
            <Skeleton
              key={idx.toString()}
              variant="rounded"
              height={25}
              width={generateRandomWidth(150, 190)}
              sx={{ borderRadius: 100, my: 2, mx: 1 }}
            />
          ))
        ) : data?.length ? (
          <>
            {!filteredData?.length && (
              <NoDataContent height={200} subtitle={"Nada encontrado"} />
            )}
            {filteredData?.map(renderItems)}
          </>
        ) : (
          <NoDataContent
            height={250}
            Icon={Inventory2Outlined}
            px={0}
            subtitle={"Nenhum relatório gerado recentemente"}
          >
            <Button
              onClick={() =>
                dispatch(
                  openElement({ name: "modalCreateReport", payload: { role } })
                )
              }
            >
              Gerar novo
            </Button>
          </NoDataContent>
        )}
      </Box>
    </>
  );
};

export default memo(RecentsList);
