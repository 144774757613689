import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { APIActions } from "../../queues/offline-queue/effect";
import getAccountLogoUrl from "../../utils/functions/getAccountLogoUrl";
import { curr } from "../../utils/more/currency_country";

const initialState = {
  accountError: null,
  checkingAccess: true,
  accountId: "",
  currency: "",
  baseInfo: {
    photoVersion: 0,
    userId: "",
    name: "",
    lastname: "",
    email: "",
    occupation: "",
    companyName: "",
    org: "",
  },
  accountHasLogo: false,
  hasPhoto: false,
  accountStatus: "",
  isApprover: false,
  isMaster: false,
  isAdmin: false,
  synced: false,
  syncing: false,
  accessExpiresAt: null,
  lastSync: null,
  receiptsAccessToken: {
    token: null,
    expiresAt: null,
  },
};

export const syncAccountData = createAsyncThunk(
  `userInfo/syncAccountData`,
  async () => {
    const res = await APIActions.account.sync();
    return res;
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    saveAccountData(state, { payload }) {
      state.accountId = payload.accountId || state.accountId;
      state.baseInfo = {
        ...state.baseInfo,
        userId: payload.userId || state.baseInfo.userId,
        name: payload.name || state.baseInfo.name,
        lastname: payload.lastname || state.baseInfo.lastname,
        email: payload.email || state.baseInfo.email,
        photoVersion: payload?.photoVersion || nanoid(4),
      };
      state.currency = payload.currency || state.currency;
      if ("companyName" in payload) {
        state.baseInfo.companyName = payload.companyName;
      }
      if ("org" in payload) {
        state.baseInfo.org = payload.org;
      }
      if ("occupation" in payload) {
        state.baseInfo.occupation = payload.occupation;
      }
      if ("accountHasLogo" in payload) {
        state.accountHasLogo = Boolean(payload.accountHasLogo);
      }
      if ("accountStatus" in payload) {
        state.accountStatus = payload.accountStatus;
      }
      if ("isApprover" in payload) {
        state.isApprover = payload.isApprover;
      }
      if ("isAdmin" in payload) {
        state.isAdmin = payload.isAdmin;
      }
      if ("isMaster" in payload) {
        state.isMaster = payload.isMaster;
      }
      if ("hasPhoto" in payload) {
        state.hasPhoto = Boolean(payload.hasPhoto);
      }
    },
    updateSynced(state) {
      state.synced = true;
      state.lastSync = new Date().toISOString();
    },
    updateLastSync(state, action) {
      state.lastSync = new Date(action.payload).toISOString();
    },
    resetSynced(state) {
      state.synced = initialState.synced;
      state.lastSync = initialState.lastSync;
    },
    setAccessExpiresAt(state, { payload }) {
      state.accessExpiresAt = new Date(payload).toISOString();
    },

    setReceiptsAccessToken(state, { payload }) {
      state.receiptsAccessToken = {
        token: payload?.token || null,
        expiresAt: payload?.expiresAt || null,
      };
    },

    updateBaseInfo(state, { payload }) {
      state.baseInfo = {
        ...state.baseInfo,
        ...payload,
      };
    },

    setSyncing(state, { payload }) {
      state.syncing = Boolean(payload);
    },
    toggleCheckingAccess(state, { payload }) {
      state.checkingAccess = Boolean(payload);
    },
    updatePhotoVersion(state, { payload }) {
      state.baseInfo.photoVersion = payload || nanoid(4);
    },
    setAccountError(state, { payload }) {
      state.accountError = payload;
    },
    setHasPhoto(state, { payload }) {
      state.hasPhoto = Boolean(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncAccountData.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncAccountData.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncAccountData.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload;
        const { user, account } = data;

        //Revogar token para comprovantes caso mude o acesso
        if (state.isApprover !== Boolean(user?.roles?.approver)) {
          accountSlice.caseReducers.setReceiptsAccessToken(state, {
            payload: {
              token: null,
              expiresAt: null,
            },
          });
        }

        accountSlice.caseReducers.saveAccountData(state, {
          payload: {
            userId: user?._id,
            accountId: account?._id,
            currency: account?.currency,
            companyName: account?.name,
            accountStatus: account?.status,
            name: user?.name,
            lastname: user?.lastname,
            email: user?.email,
            occupation: user?.occupation?.name,
            org: user?.org?.name,
            accountStatus: account?.status,
            isApprover: Boolean(user?.roles?.approver),
            isAdmin: Boolean(user?.roles?.admin),
            isMaster: Boolean(user?.roles?.master),
            accountHasLogo: account?.has_logo,
            hasPhoto: Boolean(user?.has_profile_photo),
            photoVersion: user?.profile_photo_version,
          },
        });
      });
  },
});

export const {
  saveAccountData,
  resetSynced,
  updateSynced,
  setAccessExpiresAt,
  setSyncing,
  updateLastSync,
  setAccountError,
  toggleCheckingAccess,
  updatePhotoVersion,
  setReceiptsAccessToken,
  setHasPhoto,
  updateBaseInfo,
} = accountSlice.actions;

export default accountSlice.reducer;

export const selectAccountError = (state) => state.account.accountError;

export const selectUserBaseInfo = (state) => state.account.baseInfo;

export const selectIsApprover = (state) =>
  state.account.isMaster || state.account.isApprover;
export const selectIsAdmin = (state) =>
  state.account.isMaster || state.account.isAdmin;

export const selectIsMaster = (state) => state.account.isMaster;

export const selectAccessExpiresAt = (state) => state.account.accessExpiresAt;
export const selectIsLogged = (state) => state.account.isLogged;
export const selectAccountCurrency = (state, enableFormatter) =>
  enableFormatter ? curr(state.account.currency) : state.account.currency;

export const selectIsSyncedAll = (state) => state.account.synced;
export const selectIsSyncing = (state) => state.account.syncing;

export const selectAccountStatus = (state) => state.account.accountStatus;

export const selectReceiptsAccessToken = (state) =>
  state.account.receiptsAccessToken;

export const getAccountLogoURL = (state) =>
  getAccountLogoUrl(state.account.accountId);
