import { createTheme, Grow } from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

// export const isStaging =
//   process.env.REACT_APP_ENV === "staging" ||
//   process.env.REACT_APP_ENV === "development";

export const isStaging = false;

// #1B72E8
// #047BC4 original

const primaryColor = {
  light: isStaging ? "#262626" : "#1B72E8",
  dark: "#9ACBFF",
};

const elevation1 = {
  light: "#EFF4FA",
  dark: "#27282C",
};

const backgroundPrimary = {
  light: isStaging ? "#F5F5F5" : "#F5F8FA",
  dark: "#202124",
};

const theme = (mode) =>
  createTheme({
    typography: {
      fontFamily: "'Figtree', sans-serif",
      allVariants: {
        letterSpacing: -0.15,
        fontVariantLigatures: "no-contextual",
        WebkitFontSmoothing: "antialiased",
        fontOpticalSizing: "auto",
        fontStyle: "normal",
      },
      button: {
        fontWeight: "600",
        textTransform: "none",
        fontSize: ".925rem",
      },
    },
    components: {
      MuiDialogActions: {
        styleOverrides: {
          root: {
            height: 60,
            padding: 23,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: ".9rem",
            fontWeight: "500",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#27282C" : "#FFF",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
        defaultProps: {
          TabIndicatorProps: {
            sx: {
              height: 3,
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "150ms",
            },
            paddingInline: "20px",
            borderRadius: 100,
          },
          outlinedPrimary: {
            borderColor:
              mode === "dark" ? "rgb(255,255,255,.2)" : "rgb(0,0,0,.2)",
            ":hover": {
              borderColor:
                mode === "dark" ? "rgb(255,255,255,.1)" : "rgb(0,0,0,.3)",
            },
          },
          outlinedInherit: {
            borderColor:
              mode === "dark" ? "rgb(255,255,255,.2)" : "rgb(0,0,0,.2)",
            ":hover": {
              borderColor:
                mode === "dark" ? "rgb(255,255,255,.1)" : "rgb(0,0,0,.3)",
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          "data-disableselect": true,
          onDragStart: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          TouchRippleProps: {
            "data-disableselect": true,
            onDragStart: (e) => {
              e.stopPropagation();
              e.preventDefault();
            },
          },
        },
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "150ms",
            },
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          "data-disableselect": true,
        },
        styleOverrides: {
          root: {
            fontSize: ".95rem",
            fontWeight: "500",
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "120ms",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiListItemButton: {
        defaultProps: {
          "data-disableselect": true,
        },
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiCardActionArea: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "300ms",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          componentsProps: {
            popper: {
              "data-disableselect": true,
              sx: {
                boxShadow: 0,
              },
            },
          },
        },
        styleOverrides: {
          arrow: {
            color: "#474747",
          },
          tooltip: {
            borderRadius: 3,
            backgroundColor: "#474747",
            fontSize: ".82rem",
            fontWeight: "600",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          sx: {
            cursor: "pointer",
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          transitionDuration: 100,
          "data-disableselect": true,
          disableScrollLock: true,
          slotProps: {
            paper: {
              "data-disableselect": true,
            },
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          transitionDuration: 50,
          "data-disableselect": true,
          disableScrollLock: true,
        },
      },
      MuiDialog: {
        defaultProps: {
          "data-disableselect": true,
          transitionDuration: 100,
          TransitionComponent: Transition,
          disableScrollLock: true,
          PaperProps: {
            elevation: 1,
            sx: {
              boxShadow: 10,
            },
          },
        },
        styleOverrides: {
          paper: {
            borderRadius: 5,
          },
        },
      },
      MuiDrawer: {
        defaultProps: {
          elevation: 3,
          transitionDuration: 150,
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#272932" : "#323232",
            color: "#fff",
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          loadingText: "Carregando...",
          noOptionsText: "Nada encontrado",
          openText: "Abrir",
          closeText: "Fechar",
          clearText: "Limpar",
          slotProps: {
            paper: {
              sx: {
                boxShadow: 5,
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: mode === "dark" ? "#FFF" : "#666666",
              cursor: "not-allowed",
            },
            "& .MuiInputLabel-root.Mui-disabled": {
              WebkitTextFillColor: mode === "dark" ? "#FFF" : "#666666",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#3E4146" : "#F8F9FA",
            "&:hover": {
              backgroundColor: mode === "dark" ? "#3E4146" : "#EFF1F3",
            },
            "&.Mui-focused": {
              backgroundColor: mode === "dark" ? "#3E4146" : "#F8F9FA",
            },
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          component: "div",
          display: "flex",
          alignItems: "center",
        },
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            zIndex: 10,
            minHeight: 60,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: ".95rem",
            fontWeight: "500",
            color: mode === "dark" ? "#FFF" : "#272727",
            opacity: mode === "dark" ? 1 : 0.8,
          },
          shrink: {
            fontSize: "1rem",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: "500",
          },
        },
      },
    },
    palette: {
      primary: {
        main: primaryColor[mode],
      },
      primaryDark: {
        main: "#AEDFF2",
      },
      background: {
        default: mode === "dark" ? "#202124" : "#FFF",
        paper: mode === "dark" ? "#202124" : "#FFF",
      },
      elevation1: {
        main: elevation1[mode],
      },
      elevation2: {
        main: "#D0E0F3",
      },
      backgroundPrimary: {
        main: mode === "dark" ? "#27282C" : "#FFF",
      },
      success: {
        main: mode === "dark" ? "#72C472" : "#2F7C31",
      },
      secondary: {
        main: mode === "dark" ? "#72C472" : "#0A1B3D",
      },

      error: {
        main: mode === "dark" ? "#FFB4AB" : "#C5221F",
      },
      text: {
        main: mode === "dark" ? "#FFFFFF" : "#4B4B4B",
      },
      surfaceVariant: {
        main: mode === "dark" ? "#202124" : "#EFF4FA",
      },
      capition: {
        main: "#B1AEAE",
      },
      cleanWhite: {
        main: mode === "dark" ? "#32333C" : "#FFFFFF",
      },
      warning: {
        main: "#de7915",
      },
      warningContainer: {
        main: "#FF9800",
      },
      action: {
        active: mode === "dark" ? "#FFF" : "#5F6367",
      },
      mode,
    },
  });

export default theme;
