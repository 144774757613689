import { Box } from "@mui/material";
import React, { memo } from "react";

const Container = ({ minimized, children }) => {
  return (
    <Box
      flex={1}
      maxWidth={minimized ? 350 : 400}
      height={minimized ? 50 : "55vh"}
      boxShadow={10}
      bgcolor={"background.default"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
      sx={{
        pointerEvents: "auto",
        transition: ".1s ease",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
      }}
    >
      {children}
    </Box>
  );
};

export default memo(Container);
