import { Box } from "@mui/material";
import React, { memo } from "react";
import { useThemeMode } from "../contexts/useThemeMode";

const NotepayLogo = ({ sx }) => {
  const { mode } = useThemeMode();

  return (
    <Box
      width={40}
      height={40}
      borderRadius={100}
      sx={{
        background: 'url("https://cdn.notepay.com.br/logo-icon-1.png")',
        backgroundPosition: "center",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        ...sx,
      }}
    ></Box>
  );
};

export default memo(NotepayLogo);
