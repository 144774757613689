import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isWithinInterval } from "date-fns";
import { HttpClient } from "../../api/httpClient";
import { getDateRange } from "../../utils/more/date_functions";
import normalize from "../../utils/normalize";

const baseName = "advances";

const adapters = {
  personal: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
  approver: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.sentAt?.localeCompare(a?.sentAt),
  }),
  financial: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
};

const DEFAULT_ADAPTER_INITIAL_STATE = {
  status: "idle",
  error: null,
};

const initialState = {
  personal: adapters.personal.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
  approver: adapters.approver.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
  financial: adapters.financial.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
};

export const getAdvances = createAsyncThunk(
  `${baseName}/getAdvances`,
  async ({ role, clearCache, signal, filters = {}, status }) => {
    const { date } = filters;
    const { type = "this-month", meta = {} } = date || {};
    const [fromDate, toDate] = getDateRange(type, meta);
    let params = { role, status, fromDate, toDate };
    if (role === "personal" || role === "approver") {
      delete params.status;
      delete params.fromDate;
      delete params.toDate;
    }
    const res = await HttpClient.get({
      url: "/advances",
      params: {
        ...params,
        status: status === "all" ? undefined : status,
      },
      shouldCache: !clearCache,
      signal,
    });
    return res?.data;
  }
);

export const advancesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addAdvance(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].addOne(state[role], {
          ...data,
          id: data?._id || data?.id,
        });
      }
    },
    removeManyAdvances(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].removeMany(state[role], data);
      }
    },
    updateManyAdvances(state, { payload }) {
      const { role, ids, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateMany(
          state[role],
          ids?.map((expenseId) => ({ id: expenseId, changes }))
        );
      }
    },
    updateAdvance(state, { payload }) {
      const { role, id, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateOne(state[role], {
          id,
          changes,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvances.pending, (state, action) => {
        const { role } = action.meta.arg;
        state[role].status = "loading";
      })
      .addCase(getAdvances.rejected, (state, action) => {
        const { role } = action.meta.arg;
        if (!state[role]) return;
        state[role].status = "failed";
        state[role].error =
          action.error.message || "Não foi possível buscar os adiantamentos";
      })
      .addCase(getAdvances.fulfilled, (state, action) => {
        const { role } = action.meta.arg;
        const advances = action.payload || [];
        if (!state[role]) return;
        state[role].status = "succeeded";
        const { entities } = normalize(advances);
        adapters[role].setAll(state[role], entities);
      });
  },
});

export const {
  addAdvance,
  removeManyAdvances,
  updateAdvance,
  updateManyAdvances,
} = advancesSlice.actions;

export const advancesSelectors = {
  personal: adapters.personal.getSelectors(
    (state) => state[baseName]?.personal
  ),
  approver: adapters.approver.getSelectors(
    (state) => state[baseName]?.approver
  ),
  financial: adapters.financial.getSelectors(
    (state) => state[baseName]?.financial
  ),
};

export const selectAdvancesByFilter = createSelector(
  [
    (state, role) => advancesSelectors[role].selectAll(state),
    (state, role, filter, date) => ({
      role,
      filter,
      date,
    }),
  ],
  (advances = [], payload = {}) => {
    const { role, filter, date } = payload;

    if (role === "financial") return [...advances];

    let filtered = [...advances];
    if (role === "personal") {
      switch (filter) {
        case "all":
          filtered = [...advances];
          break;
        // case "for_me":
        //   filtered = advances?.filter((advance) => advance?.type === "regular");
        //   break;
        // case "refund":
        //   filtered = advances?.filter((advance) => advance?.type === "refund");
        //   break;
        default:
          filtered = [...advances];
          break;
      }
    }
    if (date) {
      const [fromDate, toDate] = getDateRange(date?.type, date?.meta);
      filtered = filtered?.filter((advance) =>
        isWithinInterval(new Date(advance?.createdAt), {
          start: new Date(fromDate),
          end: new Date(toDate),
        })
      );
    }

    return filtered;
  }
);

export default advancesSlice.reducer;
