import React from "react";
import { IMaskInput } from "react-imask";
import { NumericFormat, PatternFormat } from "react-number-format";

export const CnpjFormat = React.forwardRef(function CnpjFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##.###.###/####-##"
    />
  );
});

export const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+55 (00) 00000-0000"
      prefix="55"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const CpfFormat = React.forwardRef(function CpfFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###.###.###-##"
    />
  );
});

export const CnpjPlaceholder = "00.000.000/0000-00";

export const CurrencyInputMask = React.forwardRef(function CurrencyInputMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      displayType="input"
      allowNegative={false}
      valueIsNumericString
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const NumberInputMask = React.forwardRef(function NumberInputMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      displayType="input"
      allowNegative={false}
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const CurrencyFormat = React.forwardRef(function CurrencyFormat(
  props,
  ref
) {
  const { ...other } = props;
  return (
    <NumericFormat
      {...other}
      displayType="text"
      allowNegative={false}
      fixedDecimalScale={true}
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});
