export const DEFAULT_ENTITY_STATE = {
  status: "idle",
  error: null,
  data: [],
};

export const persistedTables_initialState = {
  expenses_dataGridState_personal: {
    columns: {
      columnVisibilityModel: {
        hotelName: false,
        groupName: false,
        branchName: false,
        paymentName: false,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        rate: false,
      },
    },
  },
  expenses_dataGridState_approver: {
    columns: {
      columnVisibilityModel: {
        hotelName: false,
        groupName: false,
        branchName: false,
        paymentName: false,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        rate: false,
      },
    },
  },
  expenses_dataGridState_financial: {
    columns: {
      columnVisibilityModel: {
        hotelName: false,
        groupName: false,
        branchName: false,
        paymentName: false,
        projectName: false,
        from: false,
        to: false,
        distance: false,
        rate: false,
      },
    },
  },
  transactions_dataGridState_financial: {
    columns: {
      columnVisibilityModel: {
        mccText: false,
        localName: false,
      },
    },
  },
};
