import { BusinessCenterOutlined, Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranches,
  selectBranchesByRole,
} from "../../../store/features/configs/branchesSlice";
import AvatarInitialName from "../../avatar/AvatarInitialName";
import LoadingSection from "../../loading/LoadingSection";
import SelectInput from "../Select";
import { MenuItemStyle } from "./styles";

function BranchSelector({
  role,
  showIcon,
  disableClearable,
  width,
  margin,
  size,
  noLabel,
  value,
  autoFocus,
  variant = "outlined",
  inputRef,
  required,
  readOnly,
  onChange = () => {},
  defaultIsInitialValue,
  error,
  helperText,
}) {
  const dispatch = useDispatch();

  const { data, status } = useSelector((state) =>
    selectBranchesByRole(state, role)
  );

  const loading = status === "loading";

  useEffect(() => {
    if (defaultIsInitialValue) {
      if (status === "succeeded") {
        onChange(data?.find((item) => item?.is_default)?.id || "");
      }
    }
  }, []);

  useEffect(() => {
    if (value && status === "idle") {
      dispatch(getBranches(role));
    }
  }, [value]);

  const handleOpen = () => {
    if (status === "idle") {
      dispatch(getBranches(role));
    }
  };

  const getBranchNameById = useCallback(
    (branchId) => {
      if (loading) return <Skeleton height={20} width={"50%"} />;
      return data?.find((item) => item?.id === branchId)?.name || "";
    },
    [data, loading]
  );

  return (
    <>
      <SelectInput
        readOnly={readOnly}
        required={required}
        inputRef={inputRef}
        StartIcon={showIcon && BusinessCenterOutlined}
        endIcon={
          value &&
          !disableClearable &&
          !loading && (
            <Tooltip placement="right" title="Limpar campo">
              <IconButton
                onClick={() => {
                  onChange("");
                }}
                sx={{ mr: 3, p: 1.2 }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          )
        }
        value={value}
        margin={margin}
        width={width}
        autoFocus={autoFocus}
        noLabel={noLabel}
        label={"Filial"}
        variant={variant}
        onOpen={handleOpen}
        size={size}
        onChange={onChange}
        renderValue={getBranchNameById}
        error={error}
        helperText={helperText}
      >
        {status === "loading" && <LoadingSection h={100} size={30} />}
        {!Boolean(data?.length) && status === "succeeded" && (
          <Typography
            variant="body2"
            mx={2}
            mb={2}
            mt={1}
            color={"text.secondary"}
          >
            Nenhuma filial
          </Typography>
        )}
        {data?.map((item) => {
          return (
            <MenuItem key={item?.id} value={item?.id} sx={MenuItemStyle}>
              <AvatarInitialName value={item?.name[0] || ""} />
              {item?.name}
              <Box flex={1} />
              {item?.is_default && (
                <Chip
                  variant="filled"
                  sx={{ height: 25, fontWeight: "600" }}
                  label="Padrão"
                />
              )}
              {Boolean(item?.country) && (
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${item?.country?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${item?.country?.toLowerCase()}.png 2x`}
                  alt=""
                />
              )}
            </MenuItem>
          );
        })}
      </SelectInput>
      {/* <ModalCreateOrg
        open={create}
        onClose={() => setCreate(false)}
        onCreate={(orgId) => {
          setValue(orgId);
          onChange(orgId);
        }}
      /> */}
    </>
  );
}

export default BranchSelector;
