import {
  CancelScheduleSendOutlined,
  Circle,
  Close,
  DeleteOutlineOutlined,
  SendOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveExpensesWrapper from "../../../../../actions/expenses/RemoveExpensesWrapper";
import SendExpensesWrapper from "../../../../../actions/expenses/SendExpensesWrapper";
import ExpenseStatusTag from "../../../../../components/form/ExpenseStatusTag";
import formatAmount from "../../../../../utils/formatAmount";
import { curr } from "../../../../../utils/more/currency_country";
import { formatMoment } from "../../../../../utils/more/date_functions";
import CopyModel from "../actions/CopyModel";
import EditValuesModal from "./components/EditValuesModal";
import RulesIndicator from "./components/RulesIndicator";
import TransactionIndicator from "./components/TransactionIndicator";

const TimestampLabel = ({ children }) => {
  return (
    <>
      <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
      <Typography
        color={"text.secondary"}
        fontSize={".95rem"}
        fontWeight={"500"}
      >
        {children}
      </Typography>
    </>
  );
};

const DialogHeader = ({
  onClose,
  loading,
  role,
  canCancelSend,
  isEditable,
  expenseId,
  originalValuesRef,
  disabled,
  onSendToApproval,
  status,
  category,
  sentAt,
  approvedAt,
  rejectedAt,
  currentStep,
  amount,
  currency,
  transactionStatus,
  transaction,
  hasPermissionToEdit,
  alerts = {},
  mainSeverity,
  isRoute,
  to,
}) => {
  return (
    <Box
      height={65}
      py={1}
      px={3}
      pl={1.4}
      display={"flex"}
      alignItems={"flex-start"}
    >
      <IconButton title="Fechar" onClick={onClose} color="inherit">
        <Close sx={{ fontSize: "26px" }} />
      </IconButton>
      <Box ml={1}>
        {loading ? (
          <Skeleton width={300} height={40} />
        ) : isRoute ? (
          <Typography
            maxWidth={500}
            noWrap
            fontSize={"1.2rem"}
            fontWeight={"600"}
          >
            Percurso para {to}
          </Typography>
        ) : (
          <Typography
            maxWidth={500}
            noWrap
            fontSize={"1.2rem"}
            fontWeight={"600"}
          >
            {category?.name ? `${category?.name} - ` : "Despesa de "}
            {curr(currency)} {formatAmount(amount)}
          </Typography>
        )}
        {loading ? (
          <Skeleton height={20} width={100} />
        ) : (
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <ExpenseStatusTag status={status} role={role} />
            {Boolean(currentStep) && status === "pending" && (
              <TimestampLabel>Etapa {currentStep}</TimestampLabel>
            )}
            {Boolean(sentAt) && status === "pending" && (
              <TimestampLabel>Enviada em {formatMoment(sentAt)}</TimestampLabel>
            )}
            {Boolean(approvedAt) && status === "approved" && (
              <TimestampLabel>Em {formatMoment(approvedAt)}</TimestampLabel>
            )}
            {Boolean(rejectedAt) && status === "rejected" && (
              <TimestampLabel>Em {formatMoment(rejectedAt)}</TimestampLabel>
            )}
          </Box>
        )}
      </Box>
      <Box flex={1} />
      <Box
        m={-1}
        display={"flex"}
        alignItems={"center"}
        pt={1}
        gap={loading ? 2 : 0.5}
      >
        {loading ? (
          <>
            <Skeleton variant="rounded" width={150} height={30} />
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="circular" width={30} height={30} />
          </>
        ) : (
          <>
            {role !== "personal" && hasPermissionToEdit && (
              <EditValuesModal
                originalValuesRef={originalValuesRef}
                disabled={disabled}
                expenseId={expenseId}
                isRoute={isRoute}
              />
            )}
            {Boolean(alerts?.rules?.length) && (
              <RulesIndicator
                rules={alerts?.rules || []}
                mainSeverity={mainSeverity}
              />
            )}
            {!isRoute && (
              <>
                <TransactionIndicator
                  transactionStatus={transactionStatus}
                  transaction={transaction}
                />
                <Divider sx={{ mx: 1, height: 25 }} orientation="vertical" />
              </>
            )}

            <CopyModel
              disabled={disabled}
              originalValuesRef={originalValuesRef}
            />
            {isEditable && (
              <RemoveExpensesWrapper
                expensesIds={[expenseId]}
                role={role}
                renderComponent={({ openModal }) => (
                  <Tooltip title="Excluir">
                    <IconButton disabled={disabled} onClick={openModal}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </Tooltip>
                )}
              />
            )}
            {isEditable && (
              <SendExpensesWrapper
                expensesIds={[expenseId]}
                onSendToApproval={onSendToApproval}
                renderComponent={({ openModal }) => (
                  <Tooltip title="Enviar para aprovação">
                    <IconButton
                      disabled={disabled}
                      color="primary"
                      onClick={openModal}
                    >
                      <SendOutlined />
                    </IconButton>
                  </Tooltip>
                )}
              />
            )}
            {canCancelSend && (
              <Button
                color="primary"
                disabled={disabled}
                startIcon={<CancelScheduleSendOutlined />}
              >
                Cancelar envio
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default DialogHeader;
