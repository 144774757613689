import { MonetizationOnOutlined } from "@mui/icons-material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../../store/features/base/modalsSlice";
import CustomFab from "../../../../../buttons/CustomFab";

const CreationFab = ({ role = "" }) => {
  const dispatch = useDispatch();
  return (
    <>
      <CustomFab
        onClick={() => {
          dispatch(
            openElement({
              name: "modalNewAdvance",
              payload: {
                role,
              },
            })
          );
        }}
        Icon={MonetizationOnOutlined}
        label={role === "personal" ? "Solicitar" : "Criar"}
      />
    </>
  );
};

export default memo(CreationFab);
