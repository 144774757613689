import { Circle, LocationOnOutlined, RepeatRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RouteInputBox from "./RouteInputBox";

function RouteSection({
  routes,
  onUpdateRoute = () => {},
  onRevertRoutes = () => {},
  onCalcFunction = () => {},
  onAddRoute = () => {},
  onRemoveRoute = () => {},
  onDragEnd = () => {},
  onToggleRoundTrip = () => {},
  roundTrip,
  disableMyLocation,
  isEditable = true,
}) {
  const [loadingCurrentPos, setLoadingCurrentPos] = useState(false);

  const handleClickFromCurrentPosition = (idx) => {
    const geocoder = new window.google.maps.Geocoder();
    setLoadingCurrentPos(true);
    window.navigator.geolocation.getCurrentPosition(
      (s) => {
        const latlng = {
          lat: s.coords.latitude,
          lng: s.coords.longitude,
        };
        geocoder.geocode(
          {
            location: latlng,
          },
          function (results, status) {
            if (status === "OK") {
              if (results[0]) {
                //This is yout formatted address
                onUpdateRoute(idx, results[0]?.formatted_address || "");
                onCalcFunction();
              }
            }
            setLoadingCurrentPos(false);
          }
        );
      },
      (err) => {
        setLoadingCurrentPos(false);
        return;
      },
      {
        enableHighAccuracy: true,
      }
    );
  };

  if (!isEditable) {
    return (
      <Stack gap={1} p={2}>
        {routes.map((route, idx) => (
          <Stack
            key={idx.toString()}
            direction={"row"}
            alignItems={"center"}
            width={"100%"}
            gap={2.5}
          >
            <Box
              width={30}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {idx === 0 ? (
                <Circle
                  color="primary"
                  sx={{
                    fontSize: "1rem",
                    border: 1,
                    borderColor: "background.default",
                    boxShadow: 1,
                    borderRadius: 100,
                  }}
                />
              ) : (
                <LocationOnOutlined color="error" fontSize="small" />
              )}
            </Box>
            <Tooltip title={route}>
              <Typography fontWeight={"600"} noWrap>
                {route}
              </Typography>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    );
  } else {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Box>
          <Box display={"flex"} alignItems="center" position="relative">
            <Box
              sx={{
                height: routes.length * 55 - 72,
                position: "absolute",
                width: 1.4,
                bgcolor: (t) => t.palette.grey[400],
                zIndex: 1,
                mt: 1,
                left: routes.length > 2 ? 30 : 28.5,
              }}
            />
            <Droppable droppableId="routes">
              {(provided) => (
                <Box
                  flex={1}
                  display={"flex"}
                  py={3}
                  pl={1}
                  pr={1}
                  pt={2}
                  alignItems="flex-start"
                  flexDirection={"column"}
                  gap={1.7}
                  height={routes.length * 55}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {routes.map((route, idx) => (
                    <Draggable
                      key={idx.toString()}
                      draggableId={"_" + idx.toString()}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <RouteInputBox
                          innerRef={provided.innerRef}
                          value={route}
                          provided={provided}
                          isOrigin={idx === 0}
                          isDestination={idx === routes.length - 1}
                          enableRemove={routes.length > 2}
                          onRemove={() => onRemoveRoute(idx)}
                          onPlaceChanged={(value) => {
                            onUpdateRoute(idx, value);
                          }}
                          loadingCurrentPos={loadingCurrentPos}
                          calculate={onCalcFunction}
                          onClickFromCurrentPosition={() =>
                            handleClickFromCurrentPosition(idx)
                          }
                          disableMyLocation={disableMyLocation}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            {routes.length === 2 && (
              <Tooltip title="Reverter rota">
                <IconButton
                  onClick={onRevertRoutes}
                  color="inherit"
                  size="small"
                  disableTouchRipple
                  sx={{
                    mt: 2,
                  }}
                >
                  <RepeatRounded />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box pl={6} pr={4} py={1} display="flex" alignItems={"center"}>
            <Button
              sx={{ px: 1, height: 30, borderRadius: 100, fontSize: ".9rem" }}
              onClick={onAddRoute}
              size="small"
              disableRipple
            >
              Adicionar destino
            </Button>
            <Box flex={1} />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onClick={onToggleRoundTrip}
                  checked={Boolean(roundTrip)}
                />
              }
              label="Ida e volta"
            />
          </Box>
        </Box>
      </DragDropContext>
    );
  }
}

export default memo(RouteSection);
