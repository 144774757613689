import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { PERMISSIONS, defaultPermissionsState } from "../../utils/permissions";
import AutocompleteBranches from "../inputs/autocomplete/AutocompleteBranches";

const PermissionListItem = ({
  divider,
  checked,
  primary = "",
  secondary = "",
  onClick,
  strong,
}) => {
  return (
    <ListItem dense divider={divider} disableGutters>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={70}
      >
        <Switch
          checked={checked}
          onClick={() => onClick(!checked)}
        />
      </Box>
      <ListItemText
        secondaryTypographyProps={{ fontSize: ".85rem" }}
        primaryTypographyProps={{
          fontSize: "1rem",
          fontWeight: strong ? "600" : "500",
        }}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};

const PermissionsList = ({
  sx,
  permissions = defaultPermissionsState,
  onChangePermissions = (permission, field, value) => {},
}) => {
  return (
    <List sx={sx} disablePadding>
      {Object.keys(PERMISSIONS).map((permission) => {
        const { enabled, all_orgs, orgs } = permissions[permission];

        return (
          <Box key={permission}>
            <PermissionListItem
              strong
              primary={PERMISSIONS[permission]?.label}
              secondary={PERMISSIONS[permission]?.description}
              checked={enabled}
              onClick={(value) =>
                onChangePermissions(permission, "enabled", value)
              }
            />
            <Collapse
              unmountOnExit
              in={enabled && PERMISSIONS[permission]?.byBranch}
            >
              <>
                <Box
                  bgcolor={"background.default"}
                  borderRadius={2}
                  p={0.5}
                  px={2}
                  mb={2}
                  ml={2}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography fontWeight={"500"}>Filiais</Typography>
                    <Box flex={1} />
                    <RadioGroup
                      value={all_orgs ? "all" : "select"}
                      onChange={(e, v) => {
                        if (v === "all") {
                          onChangePermissions(permission, "all_orgs", true);
                          return;
                        }
                        if (v === "select") {
                          onChangePermissions(permission, "all_orgs", false);
                          return;
                        }
                      }}
                      row
                    >
                      <FormControlLabel
                        control={<Radio size="small" value={"all"} />}
                        label="Todas"
                      />
                      <FormControlLabel
                        control={<Radio size="small" value={"select"} />}
                        label="Selecionar"
                      />
                    </RadioGroup>
                  </Box>
                  <Collapse unmountOnExit in={!all_orgs}>
                    <AutocompleteBranches
                      hiddenLabel
                      autoFocus
                      label=""
                      placeholder={"Selecione"}
                      sx={{ my: 1 }}
                      size={"small"}
                    />
                  </Collapse>
                </Box>
                <Divider sx={{ mb: 2 }} />
              </>
            </Collapse>
          </Box>
        );
      })}

      {/* <Collapse in={permissions.admin.active}>
        <Box>
          <Typography
            fontWeight={"600"}
            variant="overline"
            color={"text.secondary"}
            ml={2}
          >
            Opções de administração
          </Typography>
          <PermissionListItem
            strong
            divider
            primary="Editar configurações gerais da conta"
            secondary="Permita que o usuário gerencie os dados da sua empresa: categorias de despesas, formas de pagamentos, fluxos de aprovação, motivos de rejeição, regras de despesas, projetos e políticas de percurso."
            checked={permissions.admin.settings}
            onClick={useCallback(
              (value) => onChangePermissions("admin.settings", value),
              []
            )}
          />
          <PermissionListItem
            strong
            divider
            primary="Gerenciar e configurar usuários"
            secondary="Permita que o usuário gerencie as configurações e acessos de toda a sua equipe, exceto do proprietário da conta"
            checked={permissions.admin.users_control}
            onClick={useCallback(
              (value) => onChangePermissions("admin.users_control", value),
              []
            )}
          />
          <PermissionListItem
            strong
            divider
            primary="Analisar estatísticas gerenciais"
            secondary="Permita que o usuário obtenha insights importantes da sua empresa, visualizando gráficos de gastos totais ao longo de um período"
            checked={permissions.admin.analysis}
            onClick={useCallback(
              (value) => onChangePermissions("admin.analysis", value),
              []
            )}
          />
          <PermissionListItem
            strong
            primary="Gerenciar relatórios de despesas"
            secondary="Permita que o usuário gerencie os relatórios de despesas da empresa, acessando o painel de controle da empresa"
            checked={permissions.admin.panel_control}
            onClick={useCallback(
              (value) => onChangePermissions("admin.panel_control", value),
              []
            )}
          />
          <Divider />
          <PermissionListItem
            strong
            primary="Acesso ao painel de integração SAP"
            secondary="Permita que o usuário acesse o painel de integração SAP para gerenciar conexões e configurações"
            checked={permissions.admin.panel_sap}
            onClick={useCallback(
              (value) => onChangePermissions("admin.panel_sap", value),
              []
            )}
          />
        </Box>
      </Collapse> */}
    </List>
  );
};

export default memo(PermissionsList);
