import {
  AccountTreeOutlined,
  AddOutlined,
  CheckOutlined,
  CloseOutlined,
  CurrencyExchange,
  SendOutlined,
} from "@mui/icons-material";
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, List, ListItem, Paper, Typography } from "@mui/material";
import React, { memo } from "react";
import NotepayLogo from "../../../../components/NotepayLogo";
import UserListItem from "../../../../components/USERS/UserListItem";
import EventTitle from "./components/EventTitle";

export const BoxContent = ({ sx, children, onClick }) => {
  return (
    <Box
      onClick={onClick}
      borderRadius={1.5}
      sx={{
        ...sx,
        transition: "none",
        ":hover": {
          boxShadow: 4,
        },
      }}
      mt={1}
      p={1.5}
      border={1}
      borderColor={(t) =>
        t.palette.mode === "light" ? "rgba(0,0,0,.2)" : "rgba(255,255,255,.2)"
      }
      position={"relative"}
      variant={"elevation"}
      elevation={0}
      boxShadow={0}
      bgcolor={"background.default"}
      component={Paper}
    >
      {children}
    </Box>
  );
};

const Content = memo(
  ({ timestamp, step, approvers, comment, title = "", rejectedBy = null }) => {
    return (
      <BoxContent
        sx={{
          transition: "none",
        }}
      >
        <EventTitle
          timestamp={timestamp}
          color={rejectedBy ? "error.main" : "success.main"}
        >
          {title}{" "}
          {Boolean(step) && (
            <>
              na <strong>etapa {step}</strong>,{" "}
            </>
          )}{" "}
          {rejectedBy && "por:"}
        </EventTitle>
        {rejectedBy ? (
          <List>
            {rejectedBy?.fromAuditor ? (
              <ListItem disableGutters>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={45}
                >
                  <NotepayLogo sx={{ width: 40 }} />
                </Box>
                <Typography ml={0.5} fontWeight={"600"} fontSize={".95rem"}>
                  Auditor Notepay
                </Typography>
              </ListItem>
            ) : (
              <UserListItem
                user={rejectedBy}
                clickable={false}
                disableGutters
                dense
              />
            )}
          </List>
        ) : (
          <List>
            <Typography color={"text.secondary"} mt={0} fontSize={".8rem"}>
              Aprovadores
            </Typography>
            {approvers
              ?.filter((ap) => ap?.status === "approved")
              .map((ap) => (
                <UserListItem
                  dense
                  key={ap?._id}
                  user={ap || {}}
                  clickable={false}
                  disableGutters
                />
              ))}
          </List>
        )}
        {rejectedBy && (
          <>
            <Typography
              fontSize={".83rem"}
              fontWeight={"500"}
              color={"text.secondary"}
            >
              Motivo da rejeição
            </Typography>
            <Typography
              mt={0.5}
              variant="body2"
              fontSize={".89rem"}
              fontWeight={"500"}
              color={comment ? "text.primary" : "text.secondary"}
            >
              {comment || "Nenhum motivo informado"}
            </Typography>
          </>
        )}
      </BoxContent>
    );
  }
);

function EventItem({ event, type, thirdPerson }) {
  let dot = <></>;
  let content = <></>;
  let color = "grey";

  switch (type) {
    case "created":
      dot = <AddOutlined color="action" fontSize="small" />;
      color = "grey";
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"text.primary"}>
            Despesa criada
          </EventTitle>
        </BoxContent>
      );
      break;
    case "sent":
      color = "primary";
      dot = <SendOutlined color="primary" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"primary.main"}>
            Enviada para aprovação
          </EventTitle>
        </BoxContent>
      );
      break;
    case "approved":
      color = "success";
      dot = <CheckOutlined color="success" fontSize="small" />;
      content = (
        <Content
          title="Despesa aprovada"
          timestamp={event.timestamp}
          step={event.step}
          approvers={event?.stepApprovers || []}
          rejectedBy={null}
        />
      );
      break;
    case "rejected":
      const rejectedApprover = event?.stepApprovers?.find(
        (ap) => ap?.status === "rejected"
      );
      color = "error";
      dot = <CloseOutlined color="error" fontSize="small" />;
      content = (
        <Content
          title="Rejeitada"
          timestamp={event?.timestamp}
          step={event?.step}
          rejectedBy={rejectedApprover || {}}
          comment={rejectedApprover?.comment || ""}
        />
      );
      break;
    case "WF001":
      color = "grey";
      dot = <AccountTreeOutlined color="action" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"text.primary"}>
            As {thirdPerson ? "" : "suas"} configurações de aprovação foram
            alteradas
          </EventTitle>
          <Typography fontSize={".8rem"} color={"text.secondary"}>
            Pode ser que {thirdPerson ? "o usuário" : "você"} tenha sido
            adicionado a outro fluxo de aprovação, ou o fluxo que{" "}
            {thirdPerson ? "ele(a)" : "você"} já estava foi alterado ou
            removido.
          </Typography>
        </BoxContent>
      );
      break;
    case "finished":
      color = "primary";
      dot = <CurrencyExchange color="primary" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"primary.main"}>
            Compensada
          </EventTitle>
          <Typography fontSize={".85rem"} color={"text.secondary"}>
            A despesa foi marcada como compensada pela administração
          </Typography>
        </BoxContent>
      );
      break;

    default:
      break;
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent>{content}</TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: "action.focus" }} />
        <TimelineDot variant="outlined" color={color}>
          {dot}
        </TimelineDot>
      </TimelineSeparator>
    </TimelineItem>
  );
}

export default memo(EventItem);
