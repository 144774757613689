import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  title: "Erro",
  subtitle: "",
  error: null,
  content: <></>,
};

export const errorBaseSlice = createSlice({
  name: "errorBase",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      if (payload?.error?.name === "CanceledError") return;

      state.open = true;
      if (payload) {
        if (payload.title) state.title = payload.title;
        if (payload.subtitle) state.subtitle = payload.subtitle;
        if (payload.content) state.content = payload.content;
        if (payload.error) state.error = payload.error;
      }
    },
    closeError: (state) => {
      state.open = false;
      state.content = initialState.content;
      state.subtitle = initialState.subtitle;
      state.title = initialState.title;
    },
  },
});

export const { setError, closeError } = errorBaseSlice.actions;

export default errorBaseSlice.reducer;
