export default (text) => {
  // Divide o texto em palavras usando espaço como delimitador
  let words = text?.split(" ");

  // Mapeia as palavras para suas primeiras letras e junta essas letras
  let initials = words?.map((word) => word[0]).join("");

  // Retorna as iniciais em maiúsculas
  return initials?.toUpperCase();
};
