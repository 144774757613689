import { Box, useScrollTrigger } from "@mui/material";
import React, {
  Suspense,
  lazy,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import useRouteContext from "../../../hooks/useRouteContext";
import "../../../main.css";
import rolesSettings from "../../../utils/rolesSettings";

import { useDispatch } from "react-redux";
import { closeElement } from "../../../store/features/base/modalsSlice";
import LoadingSection from "../../loading/LoadingSection";
import AdvancesSidebar from "./views/advances/AdvancesSidebar";
import ExpensesSidebarPessoal from "./views/expenses/pessoal/ExpensesSidebarPessoal";
import ReportsSidebar from "./views/reports/ReportsSidebar";
import SettingsSidebar from "./views/settings/SettingsSidebar";

const AdmSidebar = lazy(() => import("./views/adm/AdmSidebar"));

const SidebarContentWrapper = () => {
  const dispatch = useDispatch();
  const { routeId, role } = useRouteContext();

  const renderContent = useCallback(() => {
    if (routeId === "expenses") return <ExpensesSidebarPessoal />;
    if (routeId === "reports") return <ReportsSidebar />;
    if (routeId === "advances") return <AdvancesSidebar />;
    if (routeId === "admin") return <AdmSidebar />;
    if (routeId === "settings") return <SettingsSidebar />;
    return <></>;
  }, [routeId]);

  const expandedSidebarContent = useSelector(
    (state) => state.settings.expandedSidebarContent
  );

  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    target: content,
    disableHysteresis: true,
    threshold: 0,
  });

  const noHasSidebar = useMemo(
    () =>
      routeId === "home" ||
      ("hasSidebar" in rolesSettings[routeId]
        ? !rolesSettings[routeId]?.hasSidebar
        : !rolesSettings[routeId]?.profiles[role]?.hasSidebar),
    [routeId, role]
  );

  const isExpanded = useMemo(() => {
    if (noHasSidebar) {
      return false;
    } else {
      return expandedSidebarContent;
    }
  }, [expandedSidebarContent, noHasSidebar]);

  return (
    <Box
      width={isExpanded ? "14.35em" : 0}
      height={"100%"}
      component={"div"}
      display={"flex"}
      flexDirection={"column"}
      onClick={(e) => dispatch(closeElement("modalExpenseView"))}
      sx={{
        transition: "none",
        overflowX: "hidden",
        boxShadow: scrolled
          ? "inset 0 10px 7px -11px rgba(0, 0, 0, 0.3)"
          : "none",
        borderTopRightRadius: 1,
      }}
      mr={-2}
    >
      {isExpanded && (
        <Box
          ref={(node) => node && setContent(node)}
          overflow={"scroll"}
          className="hover-scrollbar"
          flex={1}
          flexBasis={0}
          display={"flex"}
          flexDirection={"column"}
          sx={{ overflowX: "hidden" }}
          p={1}
          pr={1}
          pb={5}
        >
          <Suspense fallback={<LoadingSection size={20} />}>
            {renderContent()}
          </Suspense>
        </Box>
      )}
    </Box>
  );
};

export default memo(SidebarContentWrapper);
