import { AutoAwesome } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../store/features/base/modalsSlice";

const CustomButton = styled(Button)({
  background: "#FFF",
  color: "#333",
  height: "45px",
  marginRight: "10px",
  textTransform: "none",
  display: "inline-flex",
  alignItems: "center",
  gap: "5px",
  fontSize: "15px",
  "&:hover": {
    background: "#D9E4FA",
    borderColor: "transparent",
  },
});

const ScannerButton = () => {
  const dispatch = useDispatch();
  return (
    <CustomButton
      className="scanner-button"
      variant="outlined"
      sx={{
        borderColor: "divider",
        "& .MuiSvgIcon-root": {
          transition: ".2s ease",
        },
        ":hover": {
          "& .MuiSvgIcon-root": {
            transform: "scale(1.2)",
          },
        },
      }}
      startIcon={<AutoAwesome sx={{ color: "primary.main" }} />}
      onClick={() =>
        dispatch(
          openElement({
            name: "modalExpensesScanner",
          })
        )
      }
    >
      Ler notinhas
    </CustomButton>
  );
};

export default memo(ScannerButton);
