import { Settings, SettingsOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isPathSelected } from "../../sidebar/Sidebar";

const SettingsButton = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSelected = isPathSelected("/settings", pathname);

  return (
    <IconButton
      color={"action"}
      sx={{
        bgcolor: isSelected ? "action.focus" : undefined,
      }}
      onClick={() => {
        localStorage.setItem("settings_prev_route", pathname);
        isSelected ? navigate("/") : navigate("/settings");
      }}
    >
      {isSelected ? <Settings /> : <SettingsOutlined />}
    </IconButton>
  );
};

export default memo(SettingsButton);
