import { Place } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  DialogContent,
  Drawer,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { APIActions } from "../../../api/actions";
import HelpIconButton from "../../../components/buttons/HelpIconButton";
import NoDataContent from "../../../components/form/NoDataContent";
import useReceiptsUpload from "../../../hooks/useReceiptsUpload";
import useRouteContext from "../../../hooks/useRouteContext";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { setError } from "../../../store/features/base/errorBaseSlice";
import {
  closeElement,
  selectModalInfo,
} from "../../../store/features/base/modalsSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { addExpense } from "../../../store/features/expensesSlice";
import formatAmount from "../../../utils/formatAmount";
import { routeInitialState } from "../../../utils/initialStates";
import { scrollToExpense } from "../../../utils/scroll";
import FormContent from "../../expenses/new/components/FormContent";
import RouteSection from "../components/route-section/RouteSection";
import { calculateRoute, formatRouteToApi } from "../utils";
import ModalHeader from "./components/ModalHeader";
import RightContent from "./components/RightContent";

const createExpense = async ({
  receipts = [],
  values = {},
  role,
  sendToApproval = false,
  routes = [],
  roundTrip,
}) => {
  const expenseId = nanoid(8);
  try {
    const responseData = await APIActions.expenses.add(
      {
        id: expenseId,
        ...formatRouteToApi({
          expenseData: values,
          routes,
          roundTrip,
          receipts,
        }),
        sendToApproval,
        type: "route",
      },
      role
    );
    return {
      ok: true,
      expenseId,
      data: responseData,
    };
  } catch (error) {
    return { ok: false, error, expenseId, data: null };
  }
};

const ModalNewRoute = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const { role } = useRouteContext();

  const currency = useSelector(selectAccountCurrency);

  const { open, payload } = useSelector((state) =>
    selectModalInfo(state, "modalNewRoute")
  );

  const onClose = useCallback(
    () => dispatch(closeElement("modalNewRoute")),
    []
  );

  const [calculating, setCalculating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roundTrip, setRoundTrip] = useState(false);
  const [routes, setRoutes] = useState(["", ""]);
  const [values, setValues] = useState({
    ...routeInitialState,
    ...(payload?.initialState || {}),
  });
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const { receipts, onRemoveReceipt, onUploadFiles, setReceipts } =
    useReceiptsUpload();

  //utils
  const totalAmount = useMemo(() => {
    return (
      parseFloat(values?.distance || 0) *
      parseFloat(values?.routePolicy?.currentRate || 0)
    );
  }, [values?.routePolicy, values?.distance]);

  const isOk = useMemo(
    () => Boolean(directionsResponse) && values?.routePolicy,
    [Boolean(directionsResponse), values?.routePolicy]
  );

  //refs
  const abortControllerRef = useRef(null);

  const resetState = () => {
    setReceipts([]);
    setValues((prev) => ({
      ...routeInitialState,
    }));
    setRoutes(["", ""]);
    setDirectionsResponse(null);
    setRoundTrip(false);
    setLoading(false);
    setCalculating(false);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  useEffect(() => {
    if (open && payload?.initialState) {
      setValues((prev) => ({
        ...prev,
        ...(payload?.initialState || {}),
      }));
    }
  }, [open]);

  const handleChangeValues = useCallback((prop, value) => {
    setValues((prev) => ({ ...prev, [prop]: value }));
  }, []);

  useEffect(() => {
    if (roundTrip) {
      setValues((prev) => ({
        ...prev,
        distance: parseFloat(values?.distance || 0) * 2,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        distance: parseFloat(values?.distance || 0) / 2,
      }));
    }
  }, [roundTrip]);

  const getDirectionsResponse = async (array) => {
    if (array.every(Boolean)) {
      setCalculating(true);
      const { results, distance } = await calculateRoute(array);
      if (!results) return;
      if (roundTrip) {
        handleChangeValues("distance", parseFloat(distance) * 2);
      } else {
        handleChangeValues("distance", parseFloat(distance));
      }
      setDirectionsResponse(results);
      setCalculating(false);
    }
  };

  const handleToggleRoundTrip = useCallback(() => {
    setRoundTrip((prev) => !prev);
  }, []);

  const handleReoderRoutes = useCallback(
    (response = {}) => {
      const startIndex = response.source.index;
      const endIndex = response.destination.index;
      let result = Array.from(routes);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setRoutes([...result]);
      getDirectionsResponse([...result]);
    },
    [routes]
  );

  const handleUpdateRoute = useCallback((idx, value) => {
    setRoutes((prev) => {
      prev.splice(idx, 1, value);
      return [...prev];
    });
  }, []);

  const handleRevertRoutes = useCallback(() => {
    let reversed = Object.assign([], routes);
    reversed.reverse();
    setRoutes([...reversed]);
    getDirectionsResponse([...reversed]);
  }, [routes]);

  const handleCalcFunction = useCallback(
    () => getDirectionsResponse(routes),
    [routes]
  );
  const handleAddRoute = useCallback(() => {
    setRoutes((prev) => {
      prev.push("");
      return [...prev];
    });
  }, []);

  const handleRemoveRoute = useCallback((idx) => {
    setRoutes((prev) => {
      prev.splice(idx, 1);
      getDirectionsResponse([...prev]);
      return [...prev];
    });
  }, []);

  //Função de criar despesa a partir do botão 'Criar'
  const handleCreate = async () => {
    setLoading(true);
    const { ok, data, expenseId, error } = await createExpense({
      role,
      receipts,
      values,
      roundTrip,
      routes,
      sendToApproval: false,
    });
    if (ok) {
      dispatch(
        addExpense({
          role,
          data: {
            id: expenseId,
            ...data,
          },
        })
      );
      scrollToExpense(expenseId);
      dispatch(openSnackbar({ message: "Percurso criado" }));
      onClose();
      params.set("expensesFilter", "opened");
      setParams(params);
    } else {
      dispatch(setError({ title: "Erro ao criar percurso", error }));
    }
    setLoading(false);
  };

  //Função de enviar despesa
  const handleSend = async () => {
    setLoading(true);
    const { ok, data, expenseId, error } = await createExpense({
      role,
      receipts,
      values,
      roundTrip,
      routes,
      sendToApproval: true,
    });
    if (ok) {
      dispatch(
        addExpense({
          role,
          data: {
            id: expenseId,
            ...data,
          },
        })
      );
      scrollToExpense(expenseId);
      dispatch(openSnackbar({ message: "Percurso enviado" }));
      onClose();
    } else {
      dispatch(setError({ title: "Erro ao criar e enviar percurso", error }));
    }
    setLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => onClose()}
      variant="temporary"
      transitionDuration={80}
      SlideProps={{
        onExited: resetState,
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "60em",
          boxShadow: 5,
        },
      }}
    >
      {loading && <LinearProgress />}
      <ModalHeader onClose={onClose} />

      <DialogContent sx={{ p: 0, display: "flex", alignItems: "flex-start" }}>
        <Box
          boxShadow={2}
          zIndex={10}
          flex={1}
          maxWidth={"28em"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box flex={1} flexBasis={0} overflow={"scroll"} pb={10}>
            <Box mt={-1} px={1.5}>
              <RouteSection
                routes={routes}
                onCalcFunction={handleCalcFunction}
                onAddRoute={handleAddRoute}
                onRemoveRoute={handleRemoveRoute}
                onUpdateRoute={handleUpdateRoute}
                onDragEnd={handleReoderRoutes}
                onRevertRoutes={handleRevertRoutes}
                roundTrip={roundTrip}
                onToggleRoundTrip={handleToggleRoundTrip}
              />
            </Box>
            {Boolean(directionsResponse) ? (
              <Box p={2}>
                <FormContent
                  isRoute
                  isEditable
                  disabled={loading}
                  values={{ ...values, currency }}
                  role={role}
                  onChangeValue={handleChangeValues}
                />
              </Box>
            ) : (
              <NoDataContent
                iconSize="4rem"
                Icon={Place}
                subtitle={"Selecione a rota para ver mais opções"}
              />
            )}
          </Box>
          <Collapse in={Boolean(directionsResponse)}>
            <Box p={2} boxShadow={3}>
              <Stack direction={"row"} alignItems={"flex-start"}>
                <Box flex={1}>
                  <Typography
                    fontSize={".95rem"}
                    fontWeight={"500"}
                    color="text.secondary"
                  >
                    Total estimado:
                  </Typography>
                  <Typography fontSize={"1.3rem"} fontWeight={"600"}>
                    {currency} {formatAmount(totalAmount)}
                  </Typography>
                </Box>
                <HelpIconButton size="medium" title={"Sobre o total"}>
                  {currency}{" "}
                  {formatAmount(values?.routePolicy?.currentRate || 0)} x{" "}
                  {formatAmount(values?.distance || 0)} km = {currency}{" "}
                  {formatAmount(totalAmount)}
                </HelpIconButton>
              </Stack>
              <Stack mt={2} direction={"row"} alignItems={"center"} gap={1}>
                <Button
                  onClick={handleCreate}
                  disabled={loading || !isOk}
                  variant="contained"
                  disableElevation
                >
                  Criar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
                <Box flex={1} />
                <Button
                  onClick={handleSend}
                  disabled={loading || !isOk}
                  variant="outlined"
                >
                  Enviar
                </Button>
              </Stack>
            </Box>
          </Collapse>
        </Box>
        <RightContent
          receipts={receipts}
          calculating={calculating}
          directionsResponse={directionsResponse}
          onUploadFiles={onUploadFiles}
          onRemoveReceipt={onRemoveReceipt}
        />
      </DialogContent>
    </Drawer>
  );
};

export default memo(ModalNewRoute);
