import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React from "react";

export default class DialogAlert extends React.PureComponent {
  static componentInstance;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: "",
      message: "",
      content: null,
      actions: [],
      loading: false,
      disableCloseButton: false,
      onClose: () => {},
    };

    DialogAlert.componentInstance = this;

    this.timeout = null;
    // Bind the methods to the class instance
    this._show = this._show.bind(this);
    this._close = this._close.bind(this);
  }

  static show({
    title,
    message,
    actions,
    onClose,
    disableCloseButton,
    content,
  }) {
    DialogAlert.componentInstance._show({
      title,
      message,
      actions,
      onClose,
      disableCloseButton,
      content,
    });
  }

  _show({ title, message, actions, onClose, disableCloseButton, content }) {
    clearTimeout(this.timeout);
    this.setState({
      visible: true,
      title,
      message,
      actions,
      onClose,
      disableCloseButton,
      content,
    });
  }
  _close() {
    clearTimeout(this.timeout);
    this.setState(
      {
        visible: false,
      },
      () => {
        this.timeout = setTimeout(() => {
          this.setState({
            title: "",
            message: "",
            actions: [],
            loading: false,
            disableCloseButton: false,
            content: null,
          });
        }, 1500);
      }
    );
  }

  async _onClickAction(action) {
    if (action?.onClick && typeof action?.onClick === "function") {
      this.setState({
        loading: true,
      });
      await action?.onClick();
      this.setState({
        loading: false,
      });
    }
    this._close();
  }

  render() {
    const {
      message,
      title,
      visible,
      actions,
      loading,
      onClose,
      disableCloseButton,
      content,
    } = this.state;
    return (
      <Dialog
        sx={{
          zIndex: (t) => t.zIndex.snackbar + 100,
        }}
        transitionDuration={150}
        PaperProps={{
          sx: {
            minWidth: 400,
            maxWidth: 500,
            borderRadius: 1,
          },
        }}
        TransitionProps={{
          onExited: () => {
            this.setState({
              title: "",
              message: "",
              actions: [],
              onClose: () => {},
            });
          },
        }}
        onClose={() => {
          if (onClose) {
            onClose();
          }
          this._close();
        }}
        open={visible}
      >
        {loading && <LinearProgress />}
        <DialogTitle fontWeight={"600"} fontSize={"1.3rem"}>
          {title}
          <Box flex={1} />
          {!disableCloseButton && (
            <IconButton sx={{ m: -1 }} onClick={this._close}>
              <Close />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          {message && (
            <DialogContentText sx={{ wordBreak: "break-word" }}>
              {message}
            </DialogContentText>
          )}
          {Boolean(content) && <div>{content}</div>}
        </DialogContent>
        <DialogActions>
          {actions?.length ? (
            actions?.map((action, idx) => (
              <Button
                sx={{ borderRadius: 100 }}
                onClick={() => this._onClickAction(action)}
                key={idx.toString()}
                variant={action?.main ? "contained" : action?.variant}
                disabled={loading && action?.main}
                disableElevation
              >
                {action.text}
              </Button>
            ))
          ) : (
            <Button onClick={this._close} sx={{ borderRadius: 100 }}>
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
