import { Box, Paper, Typography } from "@mui/material";
import React from "react";

function PaperSection({
  children,
  title,
  subtitle,
  action,
  disableElevation = true,
  sx,
  titleSx,
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2,
        borderColor: (t) => (t.palette.mode === "dark" ? "#474747" : "#D8D8D8"),
        p: 2,
        boxShadow: !disableElevation && 1,
        ...sx,
      }}
    >
      {(title || subtitle) && (
        <Box mb={2} display="flex" alignItems={"center"} sx={titleSx}>
          <Box flex={1}>
            <Typography
              sx={{
                opacity: 0.9,
              }}
              variant="h5"
              fontSize={"1.25rem"}
              fontWeight={"600"}
            >
              {title}
            </Typography>
            <Typography
              mt={subtitle && 0.4}
              fontSize={".9rem"}
              color="text.secondary"
            >
              {subtitle}
            </Typography>
          </Box>
          {action}
        </Box>
      )}
      {children}
    </Paper>
  );
}

export default PaperSection;
