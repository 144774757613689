import { Close } from "@mui/icons-material";
import { Box, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";

const ModalHeader = ({ onClose = () => {} }) => {
  return (
    <DialogTitle gap={1} height={50}>
      <Typography
        fontSize={"1.3rem"}
        color={"text.secondary"}
        fontWeight={"500"}
      >
        Novo percurso
      </Typography>
      <Box flex={1} />
      <IconButton
        disableTouchRipple
        onClick={onClose}
        sx={{ m: -1 }}
        color="inherit"
      >
        <Close />
      </IconButton>
    </DialogTitle>
  );
};

export default ModalHeader;
