import { InfoOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import formatAmount from "../../../../utils/formatAmount";
import { curr } from "../../../../utils/more/currency_country";

const AmountConversionInfo = ({
  displayAmount = {},
  originalAmount = {},
  conversionAmount = {},
}) => {
  return (
    <Box pl={6}>
      <Typography
        mt={1}
        fontWeight={"500"}
        color={"text.secondary"}
        variant="body2"
        fontSize={".825rem"}
      >
        Valor convertido
      </Typography>
      <Typography fontWeight={"500"} sx={{ opacity: 0.8 }} fontSize={"1.4rem"}>
        {curr(displayAmount?.currency)} {formatAmount(displayAmount?.amount)}
      </Typography>
      <Stack direction={"row"} alignItems={"center"} gap={0.5}>
        <InfoOutlined color="primary" sx={{ fontSize: ".8rem" }} />
        <Typography color={"text.secondary"} variant="caption">
          Taxa de câmbio: 1 {originalAmount?.currency} ={" "}
          {formatAmount(conversionAmount?.rate)} {displayAmount?.currency}
        </Typography>
      </Stack>
    </Box>
  );
};

export default memo(AmountConversionInfo);
