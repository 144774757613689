import { memo, useState } from "react";
import DialogAlert from "../../classes/DialogAlert";

const SendExpensesWrapper = ({
  expensesIds = [],
  onSendToApproval,
  renderComponent = ({ openModal = () => {} }) => {},
}) => {
  const [notes, setNotes] = useState("");

  const handleDefaultSend = () => {};

  const handleClick = (e) => {
    DialogAlert.show({
      title:
        expensesIds?.length === 1
          ? "Enviar a despesa para aprovação?"
          : `Enviar ${expensesIds?.length} para aprovação?`,
      disableCloseButton: true,
    //   content: (
    //     <Box py={2}>
    //       <TextInput
    //         value={notes}
    //         onChange={setNotes}
    //         variant={"standard"}
    //         autoFocus
    //         hiddenLabel
    //         placeholder={"Alguma observação?"}
    //       />
    //     </Box>
    //   ),
    //   onClose: () => setNotes(""),
      actions: [
        {
          text: "Cancelar",
        },
        {
          main: true,
          text: "Enviar",
          onClick: onSendToApproval || handleDefaultSend,
        },
      ],
    });
  };

  return renderComponent({
    openModal: handleClick,
  });
};

export default memo(SendExpensesWrapper);
