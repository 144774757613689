import { ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import ExitButton from "./components/ExitButton";
import SectionPaper from "./components/SectionPaper";

export const ButtonItem = ({
  Icon,
  children,
  divider = true,
  color,
  onClick,
}) => {
  return (
    <ListItemButton
      onClick={onClick}
      divider={divider}
      sx={{
        transition: "none",
        ":hover": {
          bgcolor: (t) => `${t.palette[color || "primary"].main}25`,
        },
      }}
    >
      <Icon sx={{ mr: 3 }} color={color || "action"} />
      <ListItemText
        primaryTypographyProps={{
          fontWeight: "600",
          fontSize: ".9rem",
          color,
        }}
        primary={children}
      />
    </ListItemButton>
  );
};

function FooterActions(props) {
  return (
    <SectionPaper
      sx={{ p: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
    >
      <ExitButton />
    </SectionPaper>
  );
}

export default FooterActions;
