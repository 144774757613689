import { Box, CircularProgress } from "@mui/material";
import React from "react";

function LoadingSection({ h = 300, w = "100%", size = 35, thickness = 5 }) {
  return (
    <Box
      width={w}
      height={h}
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
    >
      <CircularProgress size={size} thickness={thickness} />
    </Box>
  );
}

export default LoadingSection;
