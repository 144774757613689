import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { memo } from "react";
import ExpensesCreationWrapper from "../../../pages/expenses/components/actions/ExpensesCreationWrapper";
import CustomFab from "../../buttons/CustomFab";

function CreationButton(props) {
  return (
    <>
      <Box>
        <ExpensesCreationWrapper
          renderComponent={({ openMenu }) => (
            <CustomFab onClick={openMenu} Icon={Add} label={"Criar"} />
          )}
        />
      </Box>
    </>
  );
}

export default memo(CreationButton);
