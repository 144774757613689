import { ImageOutlined } from "@mui/icons-material";
import { Box, LinearProgress } from "@mui/material";
import React, { memo } from "react";
import DropzoneReceipt from "../../../../components/dropzone/dropzone-receipt/DropzoneReceipt";

const ReceiptsViewer = ({
  receipts = [],
  onUploadFiles = () => {},
  onRemove = () => {},
  loading,
  isEditable,
}) => {
  return (
    <Box
      flex={1}
      flexBasis={0}
      bgcolor={"elevation1.main"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      p={receipts?.length ? 0 : 2}
      overflow={"auto"}
    >
      {loading ? (
        <Box
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          pb={10}
        >
          <ImageOutlined sx={{ fontSize: "5rem" }} color="disabled" />
          <LinearProgress sx={{ width: 100, mt: 2 }} />
        </Box>
      ) : (
        <DropzoneReceipt
          files={receipts}
          onUploadFiles={onUploadFiles}
          onRemove={onRemove}
          isEditable={isEditable}
        />
      )}
    </Box>
  );
};

export default memo(ReceiptsViewer);
