import {
  Circle,
  ExpandLess,
  ExpandMore,
  NotesOutlined,
} from "@mui/icons-material";
import { Box, Collapse, IconButton, ListItem, Typography } from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import Baseline from "../../../../components/form/Baseline";
import NotepayLogo from "../../../../components/NotepayLogo";
import UserListItem from "../../../../components/USERS/UserListItem";

const RejectedInfo = ({ events = [], loading }) => {
  const [expanded, setExpanded] = useState(true);

  const rejectedEvent = useMemo(() => {
    if (events) {
      const revertedEvents = [...(events || [])].reverse();
      const ev = (revertedEvents || []).find((ev) => ev?.type === "rejected");
      return ev;
    }
  }, [events]);

  const approver = useMemo(() => {
    return rejectedEvent?.stepApprovers?.find(
      (user) => user?.status === "rejected"
    );
  }, [rejectedEvent]);

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Box flex={1}>
          <Typography
            fontSize={"1.2rem"}
            fontWeight={"600"}
            display={"flex"}
            alignItems={"center"}
            component={"div"}
            gap={1}
          >
            <Circle color="error" sx={{ fontSize: ".5rem" }} />
            Rejeitada{" "}
            {rejectedEvent?.step ? `na etapa ${rejectedEvent?.step}` : ""}
          </Typography>
        </Box>
        <IconButton
          sx={{ m: -1 }}
          disableTouchRipple
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box mt={1}>
          <Typography fontSize={".8rem"} color={"text.secondary"}>
            Aprovador:
          </Typography>
          {approver?.fromAuditor ? (
            <ListItem disableGutters>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={45}
              >
                <NotepayLogo sx={{ width: 40 }} />
              </Box>
              <Typography ml={0.5} fontWeight={"600"} fontSize={".95rem"}>
                Auditor Notepay
              </Typography>
            </ListItem>
          ) : (
            <UserListItem clickable={false} dense user={approver || {}} />
          )}
          <Baseline
            sx={{ ml: -0.5, mt: 1, alignItems: "flex-start" }}
            Icon={NotesOutlined}
            label={"Motivo"}
            value={approver?.comment}
            emptyLabel={"Nenhum motivo informado"}
            valueSx={{
              lineHeight: 1.25,
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default memo(RejectedInfo);
