import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import ErrorLottie from "../assets/lotties/error.json";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super();
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent="center"
          flexDirection={"column"}
          alignItems="center"
          pb={"200px"}
        >
          <Lottie
            height={200}
            options={{
              animationData: ErrorLottie,
            }}
          />
          <Typography
            maxWidth={600}
            fontWeight={"600"}
            variant="h4"
            gutterBottom
            textAlign={"center"}
          >
            Ops, algo deu errado.
          </Typography>
          <Typography maxWidth={600} mb={2} textAlign={"center"}>
            Vamos tentar novamente?
          </Typography>
          <Button
            disableElevation
            size="large"
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
          >
            Atualizar página
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}
