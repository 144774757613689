import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import TextInput from "../../components/inputs/text-input";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { removeManyExpenses } from "../../store/features/expensesSlice";

const ApprovalModal = ({
  open,
  onClose,
  expensesIds = [],
  onApprove = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");

  const role = "approver";

  const dispatch = useDispatch();

  const handleApprove = async () => {
    setLoading(true);
    try {
      await APIActions.expenses.approve({
        expensesIds,
        comment: notes,
        role,
      });
      dispatch(
        openSnackbar({
          message:
            expensesIds?.length === 1
              ? "Despesa aprovada"
              : `${expensesIds?.length} despesas aprovadas`,
        })
      );
      dispatch(
        removeManyExpenses({
          data: expensesIds,
          role,
        })
      );
      onClose();
      onApprove();
    } catch (error) {
      dispatch(
        setError({
          title:
            expensesIds?.length === 1
              ? "Erro ao aprovar despesa"
              : "Erro ao aprovar despesas",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      onClose={onClose}
      TransitionProps={{ unmountOnExit: true }}
      open={open}
      fullWidth
      maxWidth="xs"
    >
      {loading && <LinearProgress />}
      <DialogTitle fontSize={"1.5rem"} fontWeight={"600"}>
        {expensesIds?.length === 1
          ? `Deseja aprovar a despesa?`
          : `Deseja aprovar ${expensesIds?.length} despesas?`}
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextInput
          autoFocus
          disabled={loading}
          margin={"normal"}
          placeholder={"Adicionar comentário de aprovação (Opcional)"}
          variant={"outlined"}
          multiline
          value={notes}
          onChange={setNotes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading}
          variant="contained"
          disableElevation
          onClick={handleApprove}
        >
          {loading ? "Aprovando" : "Aprovar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalModal;
