import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../../api/httpClient";
import { DEFAULT_ENTITY_STATE } from "../../utils";

const baseName = "rules";

const initialState = DEFAULT_ENTITY_STATE;

export const getRules = createAsyncThunk(`${baseName}/getRules`, async () => {
  const res = await HttpClient.get({
    url: "/rules",
  });
  return res?.data;
});

export const rulesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupRules(state, { payload }) {
      const { data } = payload || {};
      //CONFIGURAR ENTRADA DE DADOS
      state.data = data?.map((item) => ({ ...item, id: item?._id })) || [];
    },

    //ADM ACTIONS
    addRule(state, { payload }) {
      state.data.unshift(payload);
    },
    updateRule(state, { payload }) {
      const { id, changes } = payload;
      const index = state.data.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.data[index] = {
          ...state.data[index],
          ...changes,
        };
      }
    },
    removeRule(state, { payload }) {
      state.data = [...state.data?.filter((item) => item?.id !== payload)];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRules.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getRules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(getRules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;

        //Dados API
        const data = action.payload || [];
        rulesSlice.caseReducers.setupRules(state, {
          payload: {
            data,
          },
        });
      });
  },
});

export const { addRule, removeRule, setupRules, updateRule } =
  rulesSlice.actions;

export default rulesSlice.reducer;

export const selectRulesState = createSelector(
  [(state) => state.rules],
  (rulesState = {}) => {
    const prevData = rulesState || DEFAULT_ENTITY_STATE;
    return {
      ...prevData,
      total: prevData?.data?.length || 0,
    };
  }
);

export const selectRuleById = createSelector(
  [selectRulesState, (state, ruleId) => ruleId],
  (rulesState = {}, ruleId = "") => {
    return rulesState?.data?.find((item) => item?.id === ruleId) || {};
  }
);
