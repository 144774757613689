import { combineReducers } from "@reduxjs/toolkit";
import accountSlice from "./accountSlice";
import expensesSlice from "./expensesSlice";

//bases
import errorBaseSlice from "./base/errorBaseSlice";
import snackbarBaseSlice from "./base/snackbarBaseSlice";

//persist
import advancesSlice from "./advancesSlice";
import approvalRequestsSlice from "./approvalRequestsSlice";
import modalsSlice from "./base/modalsSlice";
import settingsSlice from "./base/settingsSlice";
import auditSlice from "./configs/auditSlice";
import branchesSlice from "./configs/branchesSlice";
import categoriesSlice from "./configs/categoriesSlice";
import customFieldsSlice from "./configs/customFieldsSlice";
import expensePoliciesSlice from "./configs/expensePoliciesSlice";
import expenseTypesSlice from "./configs/expenseTypesSlice";
import groupsSlice from "./configs/groupsSlice";
import paymentsSlice from "./configs/paymentsSlice";
import paymentTypesSlice from "./configs/paymentTypesSlice";
import projectsSlice from "./configs/projectsSlice";
import rejectionReasonsSlice from "./configs/rejectionReasonsSlice";
import routePoliciesSlice from "./configs/routePoliciesSlice";
import rulesSlice from "./configs/rulesSlice";
import snippetsSlice from "./configs/snippetsSlice";
import usersSlice from "./configs/usersSlice";
import vehiclesSlice from "./configs/vehiclesSlice";
import notificationsSlice from "./notificationsSlice";
import reportsSlice from "./reportsSlice";
import totalsSummarySlice from "./totalsSummarySlice";
import transactionsSlice from "./transactionsSlice";

// const persistConfig = {
//   account: {
//     key: "account",
//     storage,
//     blacklist: ["checkingAccess", "accountError", "syncing"],
//     version: 0,
//     migrate: createMigrate(account_migrations, {
//       debug: false,
//     }),
//   },
//   settings: {
//     key: "settings",
//     storage,
//     blacklist: ["currentPosition"],
//   },
// };

export const rootReducer = combineReducers({
  //ACCOUNT
  account: accountSlice,
  totalsSummary: totalsSummarySlice,

  //MAIN
  expenses: expensesSlice,
  reports: reportsSlice,
  advances: advancesSlice,
  transactions: transactionsSlice,

  approvalRequests: approvalRequestsSlice,

  //LAYOUT
  errorBase: errorBaseSlice,
  snackbarBase: snackbarBaseSlice,
  modalsBase: modalsSlice,
  settings: settingsSlice,

  //SETTINGS

  //v2
  users: usersSlice,
  branches: branchesSlice,
  groups: groupsSlice,
  categories: categoriesSlice,
  payments: paymentsSlice,
  vehicles: vehiclesSlice,
  rules: rulesSlice,
  audit: auditSlice,
  routePolicies: routePoliciesSlice,
  projects: projectsSlice,
  //

  expenseTypes: expenseTypesSlice,
  paymentTypes: paymentTypesSlice,
  expensePolicies: expensePoliciesSlice,
  snippets: snippetsSlice,
  rejectionReasons: rejectionReasonsSlice,
  customFields: customFieldsSlice,
  notifications: notificationsSlice,
});
