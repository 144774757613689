import { Description } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import SidebarRoundedItem from "../../../../components/SidebarRoundedItem";

const RecentListItem = ({ report = {} }) => {
  const progress = useMemo(
    () => parseInt(report?.progress) || 0,
    [report?.progress]
  );

  const completed = useMemo(
    () => report?.status === "completed" || progress >= 100,
    [report?.status, progress]
  );

  const renderAvatar = useCallback(
    () => (
      <Box
        width={25}
        height={25}
        borderRadius={100}
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Description
          fontSize="small"
          color={completed ? "primary" : "disabled"}
          sx={{
            opacity: 0.8,
            transform: `scale(${completed ? 1 : 0})`,
            transition: ".2s ease",
          }}
        />
        <CircularProgress
          id={`report_${report?._id}_circularProgress`}
          color={"primary"}
          variant={progress <= 5 ? "indeterminate" : "determinate"}
          value={progress}
          sx={{
            position: "absolute",
            transition: ".2s ease",
            opacity: report?.status === "processing" ? 1 : 0,
          }}
          size={22}
          thickness={5}
        />
      </Box>
    ),
    [report, completed, progress]
  );

  return (
    <SidebarRoundedItem
      target="_blank"
      disabled={report?.status === "processing"}
      disableLink={!report?.pdfUrl}
      path={report?.pdfUrl}
      Icon={renderAvatar}
      title={report?.title}
    />
  );
};

export default memo(RecentListItem);
