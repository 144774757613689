import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isAfter, isWithinInterval, parseISO, subDays } from "date-fns";
import { HttpClient } from "../../api/httpClient";
import { getDateRange } from "../../utils/more/date_functions";
import normalize from "../../utils/normalize";

const baseName = "reports";

const adapters = {
  personal: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
  approver: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.sentAt?.localeCompare(a?.sentAt),
  }),
  financial: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
};

const DEFAULT_ADAPTER_INITIAL_STATE = {
  status: "idle",
  error: null,
};

const initialState = {
  personal: adapters.personal.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
  approver: adapters.approver.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
  financial: adapters.financial.getInitialState(DEFAULT_ADAPTER_INITIAL_STATE),
};

export const getReports = createAsyncThunk(
  `${baseName}/getReports`,
  async ({ role, clearCache, signal, filters = {} }) => {
    const res = await HttpClient.get({
      url: "/reports",
      params: { role },
      shouldCache: !clearCache,
      signal,
    });
    return res?.data;
  }
);

export const reportsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    addReport(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].addOne(state[role], {
          ...data,
          id: data?._id || data?.id,
        });
      }
    },
    removeManyReports(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].removeMany(state[role], data);
      }
    },
    updateManyReports(state, { payload }) {
      const { role, ids, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateMany(
          state[role],
          ids?.map((reportId) => ({ id: reportId, changes }))
        );
      }
    },
    updateReport(state, { payload }) {
      const { role, id, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateOne(state[role], {
          id,
          changes,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReports.pending, (state, action) => {
        const { role } = action.meta.arg;
        state[role].status = "loading";
      })
      .addCase(getReports.rejected, (state, action) => {
        const { role } = action.meta.arg;
        if (!state[role]) return;
        state[role].status = "failed";
        state[role].error =
          action.error.message || "Não foi possível buscar os relatórios";
      })
      .addCase(getReports.fulfilled, (state, action) => {
        const { role } = action.meta.arg;
        const reports = action.payload || [];
        if (!state[role]) return;
        state[role].status = "succeeded";
        const { entities } = normalize(
          reports?.map((report) => ({ ...report, progress: null }))
        );
        adapters[role].setAll(state[role], entities);
      });
  },
});

export const { addReport, removeManyReports, updateReport, updateManyReports } =
  reportsSlice.actions;

export const reportsSelectors = {
  personal: adapters.personal.getSelectors(
    (state) => state[baseName]?.personal
  ),
  approver: adapters.approver.getSelectors(
    (state) => state[baseName]?.approver
  ),
  financial: adapters.financial.getSelectors(
    (state) => state[baseName]?.financial
  ),
};

export const selectReportsByFilter = createSelector(
  [
    (state, role) => reportsSelectors[role].selectAll(state),
    (state, role, filter, date) => ({
      role,
      filter,
      date,
    }),
  ],
  (reports = [], payload = {}) => {
    const { role, filter, date } = payload;

    let filtered = [...reports];
    if (role === "personal") {
      switch (filter) {
        case "all":
          filtered = [...reports];
          break;
        case "for_me":
          filtered = reports?.filter((report) => report?.type === "regular");
          break;
        case "refund":
          filtered = reports?.filter((report) => report?.type === "refund");
          break;
        default:
          filtered = [...reports];
          break;
      }
    }
    if (date) {
      const [fromDate, toDate] = getDateRange(date?.type, date?.meta);
      filtered = filtered?.filter((report) =>
        isWithinInterval(new Date(report?.createdAt), {
          start: new Date(fromDate),
          end: new Date(toDate),
        })
      );
    }

    return filtered;
  }
);

export const selectRecentReports = createSelector(
  [(state, role) => reportsSelectors[role]?.selectAll(state)],
  (reports = []) => {
    const today = new Date();
    const thresholdDate = subDays(today, 5);

    return reports
      ?.filter((report) => {
        const itemDate = parseISO(report?.createdAt);
        return isAfter(itemDate, thresholdDate);
      })
      ?.slice(0, 10);
  }
);

export default reportsSlice.reducer;
