import { WSS_URL } from "../urls";
import messageCapture from "./messageCapture";

class WebSockets {
  static _accessToken = "";
  static _isOnline = true;
  static _client = null;
  static _reconnectAttempts = 0;
  static _maxReconnectAttempts = 10;

  static setAccessToken(token) {
    this._accessToken = token;
  }

  static setConnectionIsOnline(bool) {
    if (!bool) {
      this._client?.close();
    }
    this._isOnline = !!bool;
  }

  static sendMessage(message) {
    if (this._client && this._client.readyState === WebSocket.OPEN) {
      this._client.send(message);
    } else {
      console.error("Cannot send message, WebSocket is not open.");
    }
  }

  static onOpen() {
    console.log("WebSocket client connected");
    this._reconnectAttempts = 0; // Reset reconnection attempts
  }

  static onMessage(message) {
    const dataFromServer = message.data;
    console.log("Mensagem do WSS: ", dataFromServer);
    messageCapture(dataFromServer);
  }

  static onClose(event) {
    console.log("WebSocket client disconnected", event);

    if (
      this._isOnline &&
      this._reconnectAttempts < this._maxReconnectAttempts
    ) {
      this._reconnectAttempts++;
      setTimeout(() => {
        console.log(`Tentando reconexão WS (${this._reconnectAttempts})`);
        this.start();
      }, 5000);
    } else {
      console.error("Max reconnection attempts reached.");
    }
  }

  static onError(error) {
    console.error("WebSocket encountered an error:", error);
  }

  static start() {
    this._client = new WebSocket(`${WSS_URL}?accessToken=${this._accessToken}`);

    this._client.onopen = this.onOpen.bind(this);
    this._client.onmessage = this.onMessage.bind(this);
    this._client.onclose = this.onClose.bind(this);
    this._client.onerror = this.onError.bind(this);
  }
}

export default WebSockets;
