import { TodayOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { memo } from "react";
import Baseline from "../../../../components/form/Baseline";
import Input from "../../../../components/inputs/DateInput";
import { formatDate } from "../../../../utils/more/date_functions";
import SelectorBox from "../SelectorBox";

function DateInput({
  size,
  variant = "filled",
  value,
  onChange = () => {},
  error,
  isEditable = true,
  readOnly,
  disabled,
  endAdornment,
}) {
  if (!isEditable) {
    return (
      <Baseline
        Icon={TodayOutlined}
        label={"Data da despesa"}
        value={value ? formatDate(value) : ""}
      />
    );
  } else {
    return (
      <SelectorBox Icon={TodayOutlined}>
        <Input
          readOnly={readOnly}
          margin="dense"
          disableHelperText
          value={value || new Date()}
          disabled={disabled}
          onChange={onChange}
          size={size || "small"}
          disableOpenPicker={disabled || readOnly}
          variant={variant}
          label={"Data da despesa"}
          maxDate={new Date()}
        />
        {endAdornment && (
          <Box display={"flex"} alignItems={"center"} pl={1}>
            {endAdornment}
          </Box>
        )}
      </SelectorBox>
    );
  }
}

export default memo(DateInput);
