import { Close, SearchOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../../../../../inputs/text-input";

const ListHeader = ({ search, setSearch, count }) => {
  const [searchMode, setSearchMode] = useState(false);
  useEffect(() => {
    setSearch("");
  }, [searchMode]);
  return (
    <Box
      height={40}
      display={"flex"}
      alignItems={"center"}
      px={1}
      pb={1}
      pr={0}
    >
      {searchMode ? (
        <>
          <TextInput
            sx={{ flex: 1 }}
            size={"small"}
            autoFocus
            placeholder={"Pesquisar..."}
            variant={"standard"}
            value={search}
            onChange={setSearch}
          />
          <IconButton size="small" onClick={() => setSearchMode(false)}>
            <Close />
          </IconButton>
        </>
      ) : (
        <>
          <Typography
            fontWeight={"500"}
            variant="body2"
            color={"text.secondary"}
          >
            Recentes {count > 0 ? `(${count})` : ""}
          </Typography>
          <Box flex={1} />
          <Tooltip title="Pesquisar">
            <IconButton size="small" onClick={() => setSearchMode(true)}>
              <SearchOutlined />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default ListHeader;
