import { Close } from "@mui/icons-material";
import { Box, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";
import ScannerExpenseToggle from "./ScannerExpenseToggle";

const ModalHeader = ({
  onClose = () => {},
  fetchExpenseDataOnUpload,
  onChangeFetchExpenseDataOnUpload = () => {},
}) => {
  return (
    <DialogTitle height={50} gap={1}>
      <IconButton
        disableTouchRipple
        onClick={onClose}
        sx={{ m: -1, mr: 1 }}
        color="inherit"
      >
        <Close />
      </IconButton>
      <Typography fontSize={"1.3rem"} fontWeight={"500"}>
        Criar despesa
      </Typography>
      <Box flex={1} />
      <ScannerExpenseToggle
        checked={fetchExpenseDataOnUpload}
        onChange={onChangeFetchExpenseDataOnUpload}
      />
    </DialogTitle>
  );
};

export default memo(ModalHeader);
