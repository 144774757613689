import {
  addExpense,
  updateScanningReceipt,
} from "../../store/features/expensesSlice";
import { store } from "../../store/store";

export default (expensePayload = "") => {
  const payload = JSON.parse(expensePayload);
  const receipt = store.getState().expenses.scanningReceipts[payload?.id];
  if (payload && receipt) {
    store.dispatch(
      addExpense({
        role: "personal",
        data: payload,
      })
    );
    store.dispatch(
      updateScanningReceipt({
        id: payload?.id,
        changes: {
          status: "completed",
        },
      })
    );
  }
};
