import { useLocation } from "react-router-dom";
import { getLastSegment } from "../utils/formaters";

const useRouteContext = () => {
  const { pathname } = useLocation();

  const role = getLastSegment(pathname);

  const isSettings = pathname?.includes("/settings");
  const isExpenses = pathname?.includes("/expenses");
  const isAdvances = pathname?.includes("/advances");
  const isTransactions = pathname?.includes("/transactions");
  const isReports = pathname?.includes("/reports");
  const isTravels = pathname?.includes("/travels");
  const isInsights = pathname?.includes("/insights");
  const isAdm = pathname?.includes("/admin");

  let context = {
    title: "",
    contentTitle: "",
    routeId: "",
    roleTitle: "",
  };

  if (isSettings) {
    context.title = "Configurações da conta";
    context.routeId = "settings";
  }

  if (isExpenses) {
    context.title = "Despesas";
    context.routeId = "expenses";
    if (role === "personal") {
      context.contentTitle = "Minhas despesas";
    }
    if (role === "approver") {
      context.contentTitle = "Todas para aprovar";
    }
    if (role === "financial") {
      context.contentTitle = "Todas as despesas";
    }
  }
  if (isAdvances) {
    context.title = "Adiantamentos";
    context.routeId = "advances";
    if (role === "personal") {
      context.contentTitle = "Meus adiantamentos";
    }
    if (role === "approver") {
      context.contentTitle = "Todos para aprovar";
    }
    if (role === "financial") {
      context.contentTitle = "Todas os adiantamentos";
    }
  }
  if (isTransactions) {
    context.title = "Transações | Conciliação bancária";
    context.routeId = "transactions";
    context.contentTitle = "Transações";
  }
  if (isReports) {
    context.title = "Relatórios";
    context.routeId = "reports";
    if (role === "personal") {
      context.contentTitle = "Meus relatórios";
    }
    if (role === "approver") {
      context.contentTitle = "Meus relatórios";
    }
    if (role === "financial") {
      context.contentTitle = "Todos os relatórios";
    }
  }
  if (isTravels) {
    context.title = "Viagens";
    context.routeId = "travels";
  }
  if (isInsights) {
    context.title = "Insights";
    context.routeId = "insights";
  }
  if (isAdm) {
    context.title = "Administrador";
    context.routeId = "admin";
  }

  if (role === "expenses") {
    context.roleTitle = "Pessoal";
  }
  if (role === "approver") {
    context.roleTitle = "Aprovador";
  }
  if (role === "financial") {
    context.roleTitle = "Financeiro";
  }
  if (role === "manager") {
    context.roleTitle = "Gestor";
  }

  return {
    title: context?.title,
    routeId: context?.routeId || "home",
    contentTitle: context?.contentTitle,
    roleTitle: context?.roleTitle,
    role,
  };
};

export default useRouteContext;
