import { Close } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { memo } from "react";

function DialogFullScreenHeader({
  title,
  children,
  onClose,
  scrolled,
  loading,
  Icon,
}) {
  return (
    <AppBar
      sx={{
        boxShadow: scrolled && 2,
        zIndex: 10,
        borderBottom: 1,
        borderColor: "divider",
        transition: "none",
      }}
      color="transparent"
      position="relative"
      elevation={0}
    >
      <Toolbar variant="dense">
        <IconButton sx={{ p: 2, ml: -1 }} onClick={onClose}>
          <Close sx={{ fontSize: "25px" }} />
        </IconButton>
        {Icon && (
          <Avatar
            sx={{
              bgcolor: (t) => `${t.palette.primary.main}20`,
              mr: 1,
              display: { md: "flex", xs: "none" },
            }}
          >
            <Icon color="primary" />
          </Avatar>
        )}
        <Typography noWrap ml={1} fontSize="1.375rem" fontWeight={"600"}>
          {title}
        </Typography>
        <Box flex={1} />
        <Button
          size="large"
          sx={{ mr: 1, display: { md: "flex", xs: "none" } }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        {children}
      </Toolbar>
      <LinearProgress
        sx={{
          position: "absolute",
          left: 0,
          width: "100%",
          bottom: -3,
          height: 3,
          visibility: loading ? "visible" : "hidden",
        }}
      />
    </AppBar>
  );
}

export default memo(DialogFullScreenHeader);
