export const PERMISSIONS = {
  traveler: {
    label: "Viajante",
    description:
      "O usuário pode solicitar reservas de viagens, hotéis e carros.",
    actions: null,
    byBranch: true,
  },
  approver: {
    label: "Aprovador",
    description:
      "Permita que o usuário aprove despesas, adiantamentos e viagens.",
    actions: null,
    byBranch: false,
  },
  financial: {
    label: "Financeiro",
    description: "Direitos de relatório para todas as despesas.",
    actions: null,
    byBranch: true,
  },
  analyst: {
    label: "Analista",
    description: "Pode visualizar o módulo Insights.",
    actions: null,
    byBranch: true,
  },
  admin: {
    label: "Administrador",
    description: "Capacidade de gerenciar todas as configurações da conta.",
    actions: null,
    byBranch: false,
  },
};

export const defaultPermissionsState = {
  traveler: {
    enabled: true,
    all_orgs: true,
    orgs: [],
  },
  approver: { enabled: false },
  financial: { enabled: false, all_orgs: true, orgs: [] },
  analyst: { enabled: false, all_orgs: true, orgs: [] },
  admin: { enabled: false, all_orgs: true, orgs: [] },
};
