import { ImageList } from "@mui/material";
import React, { memo, useCallback } from "react";
import Fancybox from "../../../fancybox";
import ReceiptItem from "./ReceiptItem";

const ReceiptsGrid = ({
  receipts = [],
  isEditable,
  onRemove,
  cols = 2,
  gap = 8,
  enableProcessingIndicators,
}) => {
  const renderItems = useCallback(
    (file, idx) => {
      const id = file?.id;
      const key = id + idx?.toString();

      const item = (
        <ReceiptItem
          key={key}
          file={file}
          isEditable={isEditable}
          enableProcessingIndicators={enableProcessingIndicators}
          onClickRemove={onRemove}
          isPDF={file?.mimetype === "application/pdf" || !file?.is_image}
        />
      );

      return file?.mimetype === "application/pdf" || !file?.is_image ? (
        item
      ) : (
        <a key={key}>{item}</a>
      );
    },
    [isEditable, onRemove, enableProcessingIndicators]
  );
  return (
    <Fancybox>
      <ImageList sx={{ p: 2 }} variant="masonry" cols={cols} gap={gap}>
        {receipts?.map(renderItems)}
      </ImageList>
    </Fancybox>
  );
};

export default memo(ReceiptsGrid);
