export const expenseStatusText = {
  all: "Todas",
  opened: "Para enviar",
  pending: "Em aprovação",
  approved: "Aprovadas",
  rejected: "Rejeitadas",
  finished: "Compensadas",
};

export const expenseStatusColor = (theme) => ({
  opened: theme.palette.text.secondary,
  pending: theme.palette.warning.main,
  approved: theme.palette.success.main,
  rejected: theme.palette.error.main,
  finished: theme.palette.primary.main,
});
