import React from "react";
import PaperSection from "../../../components/form/PaperSection";
import DateInput from "../../../components/inputs/DateInput";

function ValidSection({ validTo, onChange }) {
  return (
    <PaperSection
      title={"Validade de acesso"}
      subtitle="O acesso do usuário será válido até"
    >
      <DateInput
        width={300}
        size={"small"}
        hiddenLabel
        label={''}
        variant={"filled"}
        value={validTo}
        onChange={onChange}
      />
    </PaperSection>
  );
}

export default ValidSection;
