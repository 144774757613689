import {
  Button,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
} from "date-fns";
import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIActions } from "../../../api/actions";
import CurrencyInput from "../../../components/inputs/CurrencyInput";
import TextInput from "../../../components/inputs/text-input";
import { setError } from "../../../store/features/base/errorBaseSlice";
import {
  closeElement,
  selectModalInfo,
} from "../../../store/features/base/modalsSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { addReport } from "../../../store/features/reportsSlice";
import { formatRoleText } from "../../../utils/formaters";
import { monthsName } from "../../../utils/more/date_functions";
import CloseableDialogTitle from "../../CloseableDialogTitle";

const shortcutsItems = [
  {
    id: "this-month",
    label: "Este mês",
    getValue: () => {
      const today = new Date();
      return [startOfMonth(today), endOfMonth(today)];
    },
  },
  {
    id: "last-month",
    label: "Mês passado",
    getValue: () => {
      const today = new Date();
      const prevMonth = subMonths(today, 1);
      return [startOfMonth(prevMonth), endOfMonth(prevMonth)];
    },
  },
  {
    id: "this-week",
    label: "Esta semana",
    getValue: () => {
      const today = new Date();
      return [startOfWeek(today), endOfWeek(today)];
    },
  },
  {
    id: "this-year",
    label: "Este ano",
    getValue: () => {
      const today = new Date();
      return [startOfYear(today), endOfYear(today)];
    },
  },
];

const ModalCreateReport = () => {
  const dispatch = useDispatch();

  const { open, payload } = useSelector((state) =>
    selectModalInfo(state, "modalCreateReport")
  );

  const onClose = useCallback(
    () => dispatch(closeElement("modalCreateReport")),
    []
  );

  const role = payload?.role || "personal";
  const expensesIds = payload?.expensesIds || undefined;

  const [loading, setLoading] = useState("");
  const [title, setTitle] = useState("");
  const [dateRange, setDateRange] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [finishExpenses, setFinishExpenses] = useState(false);
  const [all, setAll] = useState(false);
  const [selectedPeriodId, setSelectedPeriodId] = useState("this-month");
  const [currency, setCurrency] = useState("");

  const resetState = () => {
    setLoading(false);
    setTitle("");
    setDateRange([startOfMonth(new Date()), endOfMonth(new Date())]);
    setSelectedPeriodId("this-month");
    setAll(false);
    setFinishExpenses(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const data = await APIActions.reports[
        role === "financial" ? "createAccounting" : "createRegular"
      ]({
        fromDate: all ? undefined : dateRange[0] || undefined,
        toDate: all ? undefined : dateRange[1] || undefined,
        title,
        currency,
        role,
        finishExpenses,
        expensesIds: expensesIds || undefined,
      });
      dispatch(
        openSnackbar({
          message:
            role === "financial"
              ? `O relatório ficará pronto em breve - ${
                  data?.finishedExpenses?.expenses?.length || 0
                } despesa(s) encontradas`
              : "O relatório ficará pronto em breve",
        })
      );
      dispatch(
        addReport({
          role,
          data: {
            ...(role === "financial" ? data?.report : data),
            progress: 0,
          },
        })
      );
      onClose();
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao gerar relatório",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionProps={{
        unmountOnExit: true,
        onExited: resetState,
      }}
    >
      {loading && <LinearProgress />}
      <CloseableDialogTitle onClose={onClose}>
        Gerar relatório
        <Chip
          variant="filled"
          size="small"
          sx={{ fontWeight: "700", ml: 1, textTransform: "uppercase" }}
          label={formatRoleText(role)}
        />
      </CloseableDialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          {role !== "financial" ? (
            <>
              Você está gerando um relatório a partir do perfil{" "}
              <strong>{formatRoleText(role)}</strong>. Este relatório ficará
              visível apenas para você.
            </>
          ) : (
            <>
              Você está gerando um <strong>relatório de contabilidade</strong>.
              Apenas despesas <strong>aprovadas</strong> serão incluídas. Os
              usuários poderão visualizá-lo posteriormente.
            </>
          )}
        </DialogContentText>

        <TextInput
          autoFocus
          label={"Título"}
          required
          value={title}
          onChange={setTitle}
          margin={"normal"}
          size={"small"}
          placeholder={`Ex: Despesas de ${monthsName[new Date().getMonth()]}`}
        />
        {role === "financial" && (
          <CurrencyInput
            value={currency}
            onChange={setCurrency}
            margin={"normal"}
            size={"small"}
          />
        )}
        {role === "financial" && (
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Checkbox
                size="small"
                value={finishExpenses}
                onClick={() => setFinishExpenses(!finishExpenses)}
              />
            }
            label={
              <Typography component={"span"} fontWeight={"500"}>
                Marcar despesas como compensadas
              </Typography>
            }
          />
        )}
        <Typography mt={2} variant="h5" fontSize={"1.2rem"} fontWeight={"600"}>
          Filtrar despesas
        </Typography>
        <Typography variant="body2" color={"text.secondary"}>
          Defina quais despesas serão agrupadas no relatório
        </Typography>
        {role === "personal" && (
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Checkbox size="small" value={all} onClick={() => setAll(!all)} />
            }
            label="Todas as despesas"
          />
        )}
        <Collapse in={!all}>
          <DateRangePicker
            sx={{ mb: 1 }}
            slots={{ field: SingleInputDateRangeField }}
            name="allowedRange"
            label="Selecione o período"
            value={dateRange}
            onChange={(v) => {
              setSelectedPeriodId("");
              setDateRange(v);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
                size: "small",
                variant: "standard",
              },
              popper: {
                placement: "top",
              },
              actionBar: { actions: [] },
            }}
          />
          <Stack
            mt={0.5}
            direction={"row"}
            alignItems={"center"}
            gap={0.8}
            flexWrap={"wrap"}
          >
            {shortcutsItems?.map((item, idx) => (
              <Chip
                key={item?.id}
                clickable
                sx={{
                  fontWeight: "600",
                  outline: (t) =>
                    item?.id === selectedPeriodId &&
                    `1.2pt solid ${t.palette.primary.main}`,
                }}
                variant="outlined"
                size="small"
                label={item?.label}
                onClick={() => {
                  setDateRange(item.getValue());
                  setSelectedPeriodId(item?.id);
                }}
              />
            ))}
          </Stack>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading || !title?.trim()?.length}
          onClick={handleConfirm}
          variant="contained"
          disableElevation
        >
          {loading ? "Gerando" : "Gerar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ModalCreateReport);
