import {
  CheckCircle,
  CheckCircleOutlineOutlined,
  CreditScore,
  CreditScoreOutlined,
  HistoryOutlined,
  ThumbDownAlt,
  ThumbDownAltOutlined,
  Wallet,
  WalletOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { memo, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useRouteContext from "../../../../../hooks/useRouteContext";
import SidebarRoundedItem from "../../../components/SidebarRoundedItem";
import CreationFab from "./components/CreationFab";

const AdvancesSidebar = (props) => {
  const { role } = useRouteContext();

  const [params, setParams] = useSearchParams();

  const advancesFilter = params.get("advancesFilter") || "all";

  const handleFilterChange = useCallback(
    (filter) => () => {
      params.set("advancesFilter", filter);
      setParams(params);
    },
    [params, role]
  );

  useEffect(() => {
    if (role !== "personal") {
      params.set("advancesFilter", "all");
      setParams(params);
    }
  }, [role]);

  if (!role) return;

  return (
    <>
      <Box mb={2} pl={0.1}>
        <CreationFab role={role} />
      </Box>
      <div>
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "all"}
          Icon={WalletOutlined}
          ActiveIcon={Wallet}
          title={"Todos"}
          onClick={handleFilterChange("all")}
        />
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "available"}
          Icon={CreditScoreOutlined}
          ActiveIcon={CreditScore}
          title={"Disponíveis"}
          onClick={handleFilterChange("available")}
        />
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "pending"}
          Icon={HistoryOutlined}
          title={"Em aprovação"}
          onClick={handleFilterChange("pending")}
        />
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "approved"}
          Icon={CheckCircleOutlineOutlined}
          ActiveIcon={CheckCircle}
          title={"Aprovados"}
          onClick={handleFilterChange("approved")}
        />
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "rejected"}
          Icon={ThumbDownAltOutlined}
          ActiveIcon={ThumbDownAlt}
          title={"Rejeitados"}
          onClick={handleFilterChange("rejected")}
        />
        {/* <Divider sx={{ my: 1 }} />
        <Box px={2} pr={0}>
          <FormControlLabel
            label="Com valor disponível"
            control={<Checkbox sx={{ mr: 1 }} />}
          />
          <FormControlLabel
            label="Com limite atingido"
            control={<Checkbox sx={{ mr: 1 }} />}
          />
        </Box> */}
      </div>
    </>
  );
};

export default memo(AdvancesSidebar);
