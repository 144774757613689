import {
  CheckCircle,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";

const Header = ({
  completed,
  minimized,
  setMinimized = () => {},
  setOpened = () => {},
  completedReceiptsCount = 0,
  processingReceiptsCount = 0,
}) => {
  return (
    <Box
      bgcolor={(t) => `${t.palette.primary.main}10`}
      component={"div"}
      sx={{
        cursor: minimized ? "pointer" : "default",
      }}
      px={2}
      pr={1}
      display={"flex"}
      alignItems={"center"}
      height={50}
      onClick={() => setMinimized(false)}
    >
      {completed && (
        <CheckCircle fontSize="small" color="success" sx={{ mr: 1 }} />
      )}
      <Typography fontWeight={"600"} fontSize={"1.1rem"}>
        {completed
          ? `${completedReceiptsCount} ${
              completedReceiptsCount > 1
                ? "leituras concluídas"
                : "leitura concluída"
            }`
          : `Analisando ${processingReceiptsCount} notinha${
              processingReceiptsCount > 1 ? "s" : ""
            }...
       `}
      </Typography>
      <Box flex={1} />
      <Tooltip title={minimized ? "Expandir" : "Minimizar"}>
        <IconButton
          color="inherit"
          disableTouchRipple
          onClick={(e) => {
            e.stopPropagation();
            setMinimized(!minimized);
          }}
        >
          {minimized ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Tooltip>
      <Tooltip title={"Fechar"}>
        <IconButton
          color="inherit"
          disableTouchRipple
          onClick={(e) => {
            e.stopPropagation();
            setOpened(false);
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(Header);
