import { Box, LinearProgress } from "@mui/material";
import { DirectionsRenderer } from "@react-google-maps/api";
import React, { memo } from "react";
import Map from "../../../components/google-maps/Map";

function MapContent({ directionsResponse, loadingMap }) {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      width={"100%"}
      flex={1}
      height={"100%"}
      position={"relative"}
    >
      <LinearProgress
        sx={{
          visibility: loadingMap ? "visible" : "hidden",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
        }}
      />
      <Box flex={1} display="flex" flexDirection={"column"}>
        <Map mapContainerStyle={{ flex: 1, width: "100%" }}>
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </Map>
      </Box>
    </Box>
  );
}

export default memo(MapContent);
