import { setHours } from "date-fns";
import { APIActions } from "../../api/actions";
import { clearCache } from "../../api/cache";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { store } from "../../store/store";

export const calculateRoute = async (array) => {
  if (array.every(Boolean)) {
    try {
      const origin = array[0];
      const destination = array[array.length - 1];
      const waypoints = array.slice(1, -1);
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints:
          waypoints &&
          waypoints.map((loc) => ({
            location: loc,
          })),
        optimizeWaypoints: true,
      });
      let total = 0;
      const legs = results.routes[0].legs;
      for (let index = 0; index < legs.length; index++) {
        const leg = legs[index];
        total = total + leg.distance.value;
      }
      total = total / 1000;
      const dist = total.toFixed(2);
      return {
        results,
        distance: dist,
      };
    } catch (error) {
      store.dispatch(
        openSnackbar({ error: true, message: "Não foi possível buscar a rota" })
      );
      return null;
    }
  } else {
    return;
  }
};

export const formatRouteToApi = ({
  expenseData = {},
  routes = [],
  roundTrip,
  receipts,
}) => {
  const {
    routePolicy,
    notes,
    date,
    distance,
    project,
    currency,
    sendToApproval,
  } = expenseData;
  let formattedData = {
    type: "route",
    notes,
    currency,
    project: project?._id || null,
    date: setHours(new Date(date), 3),
    distance: parseFloat(distance) || 0,
    receipts,
    roundTrip,
    from: routes[0] || null,
    to: routes[routes.length - 1] || null,
    waypoints: routes.slice(1, -1) || null,
    routePolicyId: routePolicy?._id || null,
    sendToApproval: Boolean(sendToApproval),
  };
  if (!Array.isArray(receipts)) {
    delete formattedData.receipts;
  }
  return formattedData;
};

export const formatRouteFromApi = (expenseData = {}) => {
  return {
    ...expenseData,
  };
};

export const saveExpense = async ({
  sendToApproval = false,
  values = {},
  receipts = [],
  expenseId,
  signal,
  role,
}) => {
  try {
    const responseData = await APIActions.expenses.update({
      expenseId,
      role,
      signal,
      changes: formatRouteToApi({
        expenseData: {
          ...values,
          sendToApproval,
        },
        receipts,
      }),
    });
    clearCache("/advances");
    return {
      ok: true,
      data: responseData,
      error: null,
    };
  } catch (error) {
    return {
      ok: false,
      data: null,
      error,
    };
  }
};
