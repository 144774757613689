import { Box } from "@mui/material";
import React from "react";
import ReceiptsScannerBox from "../scanner/ReceiptsScannerBox";

export default () => {
  return (
    <Box
      position={"fixed"}
      bottom={0}
      left={0}
      right={0}
      width={"100%"}
      display={"flex"}
      alignItems={"flex-end"}
      pr={"20px"}
      pl={"5.7em"}
      zIndex={(t) => t.zIndex.modal - 1}
      gap={2}
      sx={{ pointerEvents: "none" }}
    >
      <ReceiptsScannerBox />
      <Box flex={1} />
    </Box>
  );
};
