import { useCallback, useState } from "react";
import { calculateRoute } from "../modals/route/utils";

const useExpenseRouteType = ({
  onChangeValue = () => {},
  distance = 0,
  isLoading,
}) => {
  const [calculating, setCalculating] = useState(false);
  const [routes, setRoutes] = useState(["", ""]);
  const [roundTrip, setRoundTrip] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const getDirectionsResponse = async (
    array = [],
    disableSetDistance = false
  ) => {
    if (array?.length && array.every(Boolean)) {
      setCalculating(true);
      const { results, distance } = await calculateRoute(array);
      if (!results) return;
      if (!disableSetDistance) {
        if (roundTrip) {
          onChangeValue("distance", parseFloat(distance) * 2);
        } else {
          onChangeValue("distance", parseFloat(distance));
        }
      }
      setDirectionsResponse(results);
      setCalculating(false);
    }
  };

  const onToggleRoundTrip = useCallback(() => {
    if (!roundTrip) {
      onChangeValue("distance", parseFloat(distance || 0) * 2);
    } else {
      onChangeValue("distance", parseFloat(distance || 0) / 2);
    }
    setRoundTrip(!roundTrip);
  }, [roundTrip, distance]);

  const onReoderRoutes = useCallback(
    (response = {}) => {
      const startIndex = response.source.index;
      const endIndex = response.destination.index;
      let result = Array.from(routes);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setRoutes([...result]);
      getDirectionsResponse([...result]);
    },
    [routes]
  );

  const onUpdateRoute = useCallback((idx, value) => {
    setRoutes((prev) => {
      prev.splice(idx, 1, value);
      return [...prev];
    });
  }, []);

  const onRevertRoutes = useCallback(() => {
    let reversed = Object.assign([], routes);
    reversed.reverse();
    setRoutes([...reversed]);
    getDirectionsResponse([...reversed]);
  }, [routes]);

  const onCalcRoute = useCallback(
    (disableSetDistance = false) =>
      getDirectionsResponse(routes, disableSetDistance),
    [routes]
  );

  const onAddRoute = useCallback(() => {
    setRoutes((prev) => {
      prev.push("");
      return [...prev];
    });
  }, []);

  const onRemoveRoute = useCallback((idx) => {
    setRoutes((prev) => {
      prev.splice(idx, 1);
      getDirectionsResponse([...prev]);
      return [...prev];
    });
  }, []);

  const resetState = useCallback(() => {
    setCalculating(false);
    setRoutes(["", ""]);
    setDirectionsResponse(null);
    setRoundTrip(false);
  }, []);

  return {
    directionsResponse,
    setDirectionsResponse,
    roundTrip,
    setRoundTrip,
    routes,
    setRoutes,
    calculating,
    //
    onToggleRoundTrip,
    onAddRoute,
    onUpdateRoute,
    onRemoveRoute,
    onReoderRoutes,
    onRevertRoutes,
    onCalcRoute,
    resetState,
    getDirectionsResponse,
  };
};

export default useExpenseRouteType;
