import { CopyAllOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../store/features/base/modalsSlice";
import { formatExpenseFromApi } from "../../../utils";

const CopyModel = ({ originalValuesRef, disabled }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    if (!originalValuesRef?.current) return;
    dispatch(
      openElement({
        name: "modalNewExpense",
        payload: {
          initialState: formatExpenseFromApi(originalValuesRef?.current || {}),
        },
      })
    );
  };

  return (
    <>
      <Tooltip title="Criar como cópia">
        <IconButton disabled={disabled} onClick={handleClick}>
          <CopyAllOutlined />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default memo(CopyModel);
